import {
  Button,
  chakra,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link as ReactLink } from 'react-router-dom'

import {
  AuthSection,
  AuthSectionActions,
  AuthSectionContent,
} from 'src/auth/components/AuthSection'
import { useUser } from 'src/auth/hooks/useUser'
import { emailPattern } from 'src/auth/utils/validation'
import { NonFieldErrors, PasswordInput } from 'src/common/components/Form'
import { routes } from 'src/common/routes'
import { fullHeightCss } from 'src/common/utils/style.utils'

interface LoginFormInput {
  password: string
  email: string
  rememberMe: boolean
}

export type LoginSection = Record<string, never>

export const LoginSection = (): React.ReactElement => {
  const { t } = useTranslation()

  const { login } = useUser()
  const [loading, setLoading] = useState(false)
  const [nonFieldErrors, setNonFieldErrors] = useState<string[]>([])

  const {
    register,
    handleSubmit,
    formState,
    setFocus,
  } = useForm<LoginFormInput>({
    mode: 'onTouched',
  })

  const onSubmit = ({ email, password, rememberMe }: LoginFormInput) => {
    setLoading(true)
    login(email, password, rememberMe).catch((error: AxiosError) => {
      const nonFieldErrors = error.response?.data.nonFieldErrors || ['unknown']
      setNonFieldErrors(nonFieldErrors)
      // only reset loading state in case of errors to prevent
      // "Can't perform a React state update on an unmounted component"
      setLoading(false)
    })
  }

  return (
    <chakra.form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      css={fullHeightCss}
    >
      <AuthSection>
        <AuthSectionContent>
          <chakra.h1 textStyle={'h2'}>{t('common:login')}</chakra.h1>
          <chakra.p marginTop={'2.4rem'}>
            {t('common:loginPage.subHeading')}
          </chakra.p>
          <Stack marginTop={'2.4rem'} spacing={'2.4rem'}>
            <FormControl
              id={'login_email'}
              isInvalid={!!formState.errors.email}
            >
              <FormLabel>{t('common:email')}</FormLabel>
              <Input
                type={'email'}
                {...register('email', {
                  required: t('common:errors.missingRequired') as string,
                  pattern: {
                    value: emailPattern,
                    message: t('common:errors.invalidEmail'),
                  },
                })}
              />
              <FormErrorMessage>
                {formState.errors.email?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id={'login_password'}
              isInvalid={!!formState.errors.password}
            >
              <FormLabel>{t('common:password')}</FormLabel>
              <PasswordInput
                {...register('password', {
                  required: t('common:errors.missingRequired') as string,
                })}
                autoComplete={'on'}
              />
              <FormErrorMessage>
                {formState.errors.password?.message}
              </FormErrorMessage>
            </FormControl>
            <Checkbox {...register('rememberMe')}>
              {t('common:loginPage.rememberMe')}
            </Checkbox>
            <NonFieldErrors
              errors={nonFieldErrors}
              setErrors={setNonFieldErrors}
              setFocus={setFocus}
              focusOnClose={'email'}
            />
          </Stack>
        </AuthSectionContent>
        <AuthSectionActions>
          <Button type={'submit'} width={'100%'} isLoading={loading}>
            {t('common:loginPage.doLogin')}
          </Button>
          <Link
            as={ReactLink}
            marginTop={'2rem'}
            textStyle={'small'}
            color={'custom.blueLight'}
            to={routes.forgotPassword}
          >
            {t('common:loginPage.forgotPassword')}
          </Link>
        </AuthSectionActions>
      </AuthSection>
    </chakra.form>
  )
}
