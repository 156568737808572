import {
  Alert,
  AlertDescription,
  AlertIcon,
  chakra,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiMailSend } from 'react-icons/bi'

import { fullHeightCss } from 'src/common/utils/style.utils'
import { paddedSectionCss } from 'src/theme/layout'

export interface EmailSubmissionConfirmation {
  loading?: boolean
  error?: string
  title: string
  subTitle?: string
  children?: React.ReactNode
}

export const EmailSubmissionConfirmation = ({
  loading = false,
  error,
  title,
  subTitle,
  children,
}: EmailSubmissionConfirmation): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <Flex
      align={'center'}
      justify={'center'}
      bg={'custom.blueDark'}
      css={[fullHeightCss, paddedSectionCss]}
    >
      <chakra.div maxWidth={'56.9rem'}>
        {loading ? (
          <Spinner color={'white'} size="xl" />
        ) : (
          <Flex
            align={'center'}
            justify={'center'}
            direction={'column'}
            color={'white'}
            textAlign={'center'}
            mb={'3.2rem'}
          >
            <chakra.span aria-hidden fontSize={'11.8rem'}>
              <BiMailSend />
            </chakra.span>
            <chakra.h1 textStyle={'h2'} mt={'4rem'}>
              {title}
            </chakra.h1>
            {subTitle && (
              <chakra.p textStyle={'text'} mt={'2.4rem'}>
                {subTitle}
              </chakra.p>
            )}
            {children && <chakra.div mt={'2.4rem'}>{children}</chakra.div>}
          </Flex>
        )}
        {error && (
          <Alert status={'error'}>
            <AlertIcon />
            <AlertDescription>
              {t([`common:apiErrors.${error}`, 'common:errors.unknown'])}
            </AlertDescription>
          </Alert>
        )}
      </chakra.div>
    </Flex>
  )
}
