import { useTranslation } from 'react-i18next'

import { fullHeightCss } from 'src/common/utils/style.utils'

import { ErrorDisplay } from './ErrorDisplay'

export interface ErrorPage404 {
  title?: string
}

export const ErrorPage404 = ({ title }: ErrorPage404): React.ReactElement => {
  const { t } = useTranslation()
  const _title = title || t('common:notFound')
  return <ErrorDisplay title={_title as string} css={fullHeightCss} />
}
