/*
 * We need to override the default sizes from Chakra-UI because we set the
 * root font-size to 62.5% (1 / 1.6), which allows use to use 1rem as an equivalent to 10px
 * instead of 1rem == 16px. Therefore we need to multiply all default rem sizes by 1.6
 * */

export const space = {
  px: '1px',
  0: '0',
  0.5: '0.2rem',
  1: '0.4rem',
  1.5: '0.6rem',
  2: '0.8rem',
  2.5: '1',
  3: '1.2rem',
  3.5: '1.4rem',
  4: '1.6rem',
  5: '2rem',
  6: '2.4rem',
  7: '2.8rem',
  8: '3.2rem',
  9: '3.6rem',
  10: '4rem',
  12: '4.8rem',
  14: '5.6rem',
  16: '6.4rem',
  20: '8rem',
  24: '9.6rem',
  28: '11.6rem',
  32: '12.8rem',
  36: '14.8rem',
  40: '16rem',
  44: '17.6rem',
  48: '19.2rem',
  52: '20.8rem',
  56: '22.4rem',
  60: '24rem',
  64: '25.6rem',
  72: '27.2rem',
  80: '28.8rem',
  96: '30.4rem',
}
