import { createBreakpoints } from '@chakra-ui/theme-tools'

export const breakpointsEm = {
  sm: '23.4em', // 375px
  md: '48em', // 768px
  lg: '64em', // 1024px
  xl: '90em', // 1216px
}

export const breakpointsRem = {
  sm: '37.5rem',
  md: '76.8rem',
  lg: '102.4rem',
  xl: '144',
}

export type BreakpointKey = keyof typeof breakpointsRem

export const media = {
  sm: `@media screen and (min-width: ${breakpointsEm['sm']})`,
  md: `@media screen and (min-width: ${breakpointsEm['md']})`,
  lg: `@media screen and (min-width: ${breakpointsEm['lg']})`,
  xl: `@media screen and (min-width: ${breakpointsEm['xl']})`,
  // alias for
  mobile: `@media screen and (min-width: ${breakpointsEm['sm']})`,
  tablet: `@media screen and (min-width: ${breakpointsEm['md']})`,
  tabletWide: `@media screen and (min-width: ${breakpointsEm['lg']})`,
  desktop: `@media screen and (min-width: ${breakpointsEm['xl']})`,
}

export const breakpoints = createBreakpoints(breakpointsEm)
