import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Skeleton,
  SkeletonText,
  useDisclosure,
} from '@chakra-ui/react'
import React, { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { useUser } from 'src/auth/hooks/useUser'
import { ErrorPage404 } from 'src/common/components/Error'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'src/common/components/Modal'
import { routes } from 'src/common/routes'
import {
  MatchingEventQueryResult,
  useMatchingEventQuery,
} from 'src/graphql/__generated__/types'
import {
  contentColumnNormalCss,
  defaultSectionCss,
  layout,
} from 'src/theme/layout'

import { MatchingEventDetailsSection } from './MatchingEventDetailsSection'

const LoadingSkeleton = () => (
  <chakra.div css={contentColumnNormalCss}>
    <Skeleton width={{ base: '50%', md: '30%' }} height={'3.2rem'} />
    <SkeletonText mt={'2.4rem'} noOfLines={10} spacing={'4'} />
  </chakra.div>
)

export type MatchingEventDetailsPage = Record<string, never>

interface MatchingEventDetailsPageParams {
  slug: string
}

const BackgroundImage = (): React.ReactElement => {
  const [isLoaded, setIsLoaded] = useState(false)
  return (
    <chakra.img
      sizes="(max-width: 1600px) 100vw, 1600px"
      srcSet="
            /assets/event-background/event-background_uqomih_c_scale,w_200.png 200w,
            /assets/event-background/event-background_uqomih_c_scale,w_574.png 574w,
            /assets/event-background/event-background_uqomih_c_scale,w_834.png 834w,
            /assets/event-background/event-background_uqomih_c_scale,w_1037.png 1037w,
            /assets/event-background/event-background_uqomih_c_scale,w_1198.png 1198w,
            /assets/event-background/event-background_uqomih_c_scale,w_1359.png 1359w,
            /assets/event-background/event-background_uqomih_c_scale,w_1505.png 1505w,
            /assets/event-background/event-background_uqomih_c_scale,w_1600.png 1600w"
      src="/assets/event-background/event-background_uqomih_c_scale,w_1600.png"
      width={'100%'}
      height={'100%'}
      objectFit={'cover'}
      objectPosition={'center center'}
      opacity={isLoaded ? 1 : 0}
      transition={'all 0.7s ease-in'}
      onLoad={() => setIsLoaded(true)}
      aria-hidden
    />
  )
}

export const MatchingEventDetailsPage = (): React.ReactElement => {
  const { loading: userLoading } = useUser()
  const { slug } = useParams<MatchingEventDetailsPageParams>()
  const { t } = useTranslation()
  const [passphrase, setPassphrase] = useState<string>('')
  const {
    data,
    loading: dataLoading,
    error,
  }: MatchingEventQueryResult = useMatchingEventQuery({
    variables: { slug, passphrase },
  })
  const { isOpen, onClose, onOpen } = useDisclosure()

  const matchingEvent = data?.matchingEvent

  const loading = dataLoading || userLoading
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      passphrase: { value: string }
    }
    setPassphrase(target.passphrase.value)
  }

  useEffect(() => error && onOpen(), [error, onOpen])

  if (!loading && !matchingEvent && !error) {
    return <ErrorPage404 />
  }
  if (!loading && error && error.message === 'passphrase_required') {
    return (
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <chakra.form onSubmit={(event) => handleSubmit(event)}>
          <ModalHeader>
            {t('matchingEvent.passphrase.passphraseRequired')}
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>{t('matchingEvent.passphrase.default')}</FormLabel>
              <Input id={'passphrase'} name={'passphrase'} />
            </FormControl>
          </ModalBody>
          <ModalFooter direction={'vertical'}>
            <Button
              as={Link}
              mr={'1.8rem'}
              to={routes.matchingEventList}
              variant={'secondary'}
              colorScheme={'red'}
            >
              {t('common:cancel')}
            </Button>
            <Button type={'submit'} variant={'secondary'}>
              {t('matchingEvent.passphrase.submitPassphrase')}
            </Button>
          </ModalFooter>
        </chakra.form>
      </Modal>
    )
  }

  return (
    <chakra.div>
      <chakra.div
        maxWidth={layout.maxWidth.hero}
        margin={'0 auto'}
        bg={'custom.blueDark'}
        height={{ base: '17rem', md: '28rem' }}
        aria-hidden
      >
        <BackgroundImage />
      </chakra.div>
      <chakra.div
        css={defaultSectionCss}
        pt={{ base: '4rem', md: '5.8rem' }}
        pb={{ base: '4.9rem', md: '6rem' }}
      >
        {loading && <LoadingSkeleton />}
        {!loading && matchingEvent && (
          <MatchingEventDetailsSection matchingEvent={matchingEvent} />
        )}
      </chakra.div>
    </chakra.div>
  )
}
