import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'

import { LoginPage } from 'src/auth/components/LoginPage'
import {
  PasswordResetPage,
  RequestPasswordResetPage,
} from 'src/auth/components/PasswordReset'
import {
  NotLoggedInOnlyRoute,
  PrivateRoute,
} from 'src/auth/components/ProtectedRoute'
import {
  RegisterPage,
  AccountActivationPage,
} from 'src/auth/components/RegisterPage'
import { UserProvider } from 'src/auth/hooks/useUser'
import { BlogPage } from 'src/blog/components/BlogPage'
import { MainFallback } from 'src/common/components/Error'
import { Layout } from 'src/common/components/Layout'
import { routes } from 'src/common/routes'
import { client } from 'src/config/apolloClient'
import { Homepage } from 'src/homepage/components/Hompage'
import { TranslationsProvider } from 'src/i18n/config'
import { MatchingEventDetailsPage } from 'src/matchingevent/components/MatchingEventDetailsPage'
import { MatchingEventListPage } from 'src/matchingevent/components/MatchingEventListPage'
import { ParticipantListPage } from 'src/matchingevent/components/ParticipantListPage'
import { AboutPage } from 'src/staticpages/components/AboutPage'
import { DataPolicy } from 'src/staticpages/components/DataPolicy'
import { Imprint } from 'src/staticpages/components/Imprint'
import { ThemeProvider } from 'src/theme'
import { MyProfilePage } from 'src/userprofile/components/MyProfilePage'
import { ParticipantProfilePage } from 'src/userprofile/components/ParticipantProfilePage'

export const ScrollToTop = (): null => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export const App = (): React.ReactElement => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider>
        <TranslationsProvider>
          <Sentry.ErrorBoundary fallback={<MainFallback />}>
            <ApolloProvider client={client}>
              <UserProvider>
                <Layout>
                  <Switch>
                    <Route path={routes.home} component={Homepage} exact />
                    <NotLoggedInOnlyRoute
                      path={routes.register}
                      component={RegisterPage}
                      exact
                    />
                    <NotLoggedInOnlyRoute
                      path={routes.activateAccount}
                      component={AccountActivationPage}
                      exact
                    />
                    <NotLoggedInOnlyRoute
                      path={routes.resetPassword}
                      component={PasswordResetPage}
                      exact
                    />
                    <NotLoggedInOnlyRoute
                      path={routes.forgotPassword}
                      component={RequestPasswordResetPage}
                      exact
                    />
                    <NotLoggedInOnlyRoute
                      path={routes.login}
                      component={LoginPage}
                      exact
                    />
                    <PrivateRoute
                      path={routes.profile}
                      component={MyProfilePage}
                      exact
                    />
                    <PrivateRoute
                      path={routes.matchingEventDetailsPath}
                      component={MatchingEventDetailsPage}
                      exact
                    />
                    <PrivateRoute
                      path={routes.matchingEventParticipantsPath}
                      component={ParticipantListPage}
                      exact
                    />
                    <PrivateRoute
                      path={routes.participantProfilePath}
                      component={ParticipantProfilePage}
                      exact
                    />
                    <Route path={routes.blog} component={BlogPage} exact />
                    <Route path={routes.impressum} component={Imprint} exact />
                    <Route path={routes.about} component={AboutPage} exact />
                    <Route
                      path={routes.datenschutz}
                      component={DataPolicy}
                      exact
                    />
                    <PrivateRoute
                      path={routes.matchingEventList}
                      component={MatchingEventListPage}
                      exact
                    />
                    <Redirect to={routes.home} />
                  </Switch>
                </Layout>
              </UserProvider>
            </ApolloProvider>
          </Sentry.ErrorBoundary>
        </TranslationsProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}
