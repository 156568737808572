import { chakra, StyleProps } from '@chakra-ui/react'

import { Chip } from 'src/common/components/Chip'

import { chipListCss } from './ChipList.styles'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export interface ChipList<T extends unknown> extends Omit<StyleProps, 'apply'> {
  items: T[]
  isReadonly?: boolean
  onDelete?: (item: T) => void
  itemToString: (item: T) => string
  variant?: Chip['variant']
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const ChipList = <T extends unknown>({
  items,
  isReadonly = true,
  itemToString,
  onDelete,
  variant,
  ...stylProps
}: ChipList<T>): React.ReactElement => {
  return (
    <chakra.ul css={chipListCss} {...stylProps}>
      {items.map((item) => {
        const label = itemToString(item)
        const onDeleteItem = onDelete ? () => onDelete(item) : undefined
        return (
          <li key={label}>
            <Chip
              label={label}
              isReadonly={isReadonly}
              onDelete={onDeleteItem}
              variant={variant}
            />
          </li>
        )
      })}
    </chakra.ul>
  )
}
