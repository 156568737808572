import {
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Progress,
} from '@chakra-ui/react'
import { useCombobox } from 'downshift'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'

import { Chip } from 'src/common/components/Chip'
import { ChipList } from 'src/common/components/ChipList'
import { colors } from 'src/theme/colors'

import {
  clearButtonCss,
  comboboxStyles,
  comboboxWrapperStyles,
  containerCss,
  menuMultipleStyles,
  placeholderCss,
  progressCss,
} from './MultiChipSelect.styles'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export interface MultiChipSelect<T extends unknown> {
  setInputValue: (inputValue: string) => void
  inputValue: string
  inputLabel: string
  items: T[]
  addSelectedItem: (item: T) => void
  removeSelectedItem: (item: T) => void
  selectedItems: T[]
  filterItems: (items: T[], inputValue: string, selectedItems: T[]) => T[]
  itemToString: (item: T | null) => string
  isReadonly?: boolean
  isLoading?: boolean
  error?: string | undefined
  noSelectedItemsLabel?: string
  chipVariant?: Chip['variant']
  isRequired: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const MultiChipSelect = <T extends unknown>({
  inputValue,
  setInputValue,
  inputLabel,
  items,
  addSelectedItem,
  removeSelectedItem,
  selectedItems,
  filterItems,
  itemToString,
  isReadonly = false,
  isLoading = false,
  error,
  noSelectedItemsLabel,
  chipVariant,
  isRequired,
}: MultiChipSelect<T>): React.ReactElement => {
  const { t } = useTranslation()

  const clearSelection = () => {
    setInputValue('')
  }

  const filteredItems = useMemo(() => {
    return filterItems(items, inputValue, selectedItems)
  }, [items, inputValue, filterItems, selectedItems])

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox<T | null>({
    inputValue,
    items: filteredItems,
    itemToString,
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || '')
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('')
            addSelectedItem(selectedItem)
            selectItem(null)
          }
          break
        default:
          break
      }
    },
  })

  return (
    <FormControl css={containerCss} isInvalid={!!error}>
      <FormLabel {...getLabelProps()}>
        {inputLabel} {isRequired && <span style={{ color: 'red' }}>*</span>}
      </FormLabel>
      <chakra.div
        css={comboboxWrapperStyles}
        style={isReadonly ? { display: 'none' } : { marginBottom: '1.2rem' }}
      >
        <chakra.div css={comboboxStyles} {...getComboboxProps()}>
          <Input
            {...getInputProps()}
            pr={'4rem'}
            // disable last-pass auto complete
            data-lpignore={true}
          />
          {inputValue && (
            <chakra.button
              css={clearButtonCss}
              onClick={clearSelection}
              aria-label={t('common:clearInput')}
            >
              <MdClose aria-hidden />
            </chakra.button>
          )}
          <FormErrorMessage>{error}</FormErrorMessage>
        </chakra.div>

        <chakra.ul
          {...getMenuProps()}
          css={[menuMultipleStyles]}
          layerStyle={'elevated'}
          style={!isOpen ? { display: 'none' } : undefined}
        >
          {isOpen && isLoading && (
            <Progress size={'xs'} isIndeterminate css={progressCss} />
          )}
          {isOpen && filteredItems.length === 0 && <li>Keine Treffer</li>}
          {isOpen &&
            filteredItems.map((item, index) => {
              const label = itemToString(item)
              const isHighlighted = highlightedIndex === index
              return (
                <li
                  style={
                    isHighlighted
                      ? { backgroundColor: colors.custom.blueFill }
                      : {}
                  }
                  key={label}
                  {...getItemProps({ item, index })}
                >
                  {label}
                </li>
              )
            })}
        </chakra.ul>
      </chakra.div>
      {selectedItems.length > 0 ? (
        <ChipList
          items={selectedItems}
          onDelete={removeSelectedItem}
          itemToString={itemToString}
          isReadonly={isReadonly}
          variant={chipVariant}
        />
      ) : isReadonly ? (
        <chakra.p css={placeholderCss}>{noSelectedItemsLabel}</chakra.p>
      ) : null}
    </FormControl>
  )
}
