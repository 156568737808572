import { chakra, Flex } from '@chakra-ui/react'

import { HeroImage } from 'src/common/components/HeroImage'
import { defaultSectionCss } from 'src/theme/layout'

import {
  containerCss,
  contentColumnCss,
  imageWrapperCss,
} from './AuthSection.styles'

export interface LoginSection {
  children: React.ReactNode
}

export const AuthSectionContent: React.FC = ({
  children,
}): React.ReactElement => {
  return <chakra.div flexGrow={1}>{children}</chakra.div>
}

export const AuthSectionActions: React.FC = ({ children }) => {
  return (
    <Flex marginTop={'4rem'} direction={'column'} align={'center'}>
      {children}
    </Flex>
  )
}

export type AuthSection = {
  children: React.ReactNode
}

export const AuthSection = ({ children }: LoginSection): React.ReactElement => {
  return (
    <chakra.div css={containerCss}>
      <chakra.div css={imageWrapperCss}>
        <HeroImage />
      </chakra.div>
      <chakra.div css={defaultSectionCss}>
        <chakra.div css={contentColumnCss}>{children}</chakra.div>
      </chakra.div>
    </chakra.div>
  )
}
