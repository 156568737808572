import { css } from '@emotion/react'

import { fullHeightCss } from 'src/common/utils/style.utils'
import { theme } from 'src/theme'

export const containerCss = css`
  ${fullHeightCss};
  position: relative;
  width: 100%;
`

export const imageGap = {
  lg: `${theme.grid.lg.columnWidth} + ${theme.grid.lg.gutter} * 1.5`,
  xl: `${theme.grid.xl.columnWidth} + ${theme.grid.xl.gutter} * 1.5`,
}

export const imageWrapperCss = css`
  position: absolute;
  top: 0;
  height: 100%;
  background: ${theme.colors.custom.blueFill};
  max-width: calc(${theme.layout.maxWidth.hero} / 2);

  display: none;

  ${theme.media.lg} {
    display: block;

    width: calc(50vw - (${imageGap.lg}));
    right: calc(50% + (${imageGap.lg}));
  }

  ${theme.media.xl} {
    width: calc(50vw - (${imageGap.xl}));
    right: calc(50% + (${imageGap.xl}));
  }
`

export const contentColumnCss = css`
  grid-column: 1 / -1;
  padding-top: 9.6rem;
  padding-bottom: 4.3rem;

  display: flex;
  flex-direction: column;

  ${theme.media.md} {
    grid-column: 2 / -2;
  }

  ${theme.media.lg} {
    grid-column: 7 / -2;
    padding-top: 12.7rem;
    padding-bottom: 7.2rem;
  }

  ${theme.media.xl} {
    grid-column: 7 / -2;
  }
`
