import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import React from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'

import commonDe from './de/common'
import commonEn from './en/common'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      de: { common: commonDe },
      en: { common: commonEn },
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
    },
  })

// update html lang attribute on language change
i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng)
})

export const TranslationsProvider: React.FC = ({ children }) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export { i18n }
