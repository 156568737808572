export const routes = {
  home: '/',
  register: '/auth/register',
  login: '/auth/login',
  activateAccount: '/auth/activation',
  forgotPassword: '/auth/passwort-vergessen',
  resetPassword: '/auth/reset-password',
  profile: '/profil',
  participantProfilePath: '/profil/participant/:id',
  participantProfile: (id: string): string => `/profil/participant/${id}`,
  impressum: '/impressum',
  about: '/about',
  datenschutz: '/datenschutz',
  emailConfirmation: '/register/confirmation',
  matchingEventDetailsPath: '/events/:slug',
  matchingEventDetails: (slug: string): string => `/events/${slug}`,
  matchingEventList: '/events',
  matchingEventParticipantsPath: '/events/:slug/participants',
  matchingEventParticipants: (slug: string): string =>
    `/events/${slug}/participants`,
  blog: '/blog',
}
