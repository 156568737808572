// https://coolors.co/00131f-003e59-006893-1279a2-2489b1-56a4c3-87bed4-b8d9e6-d1e6ef-e9f3f7
const blue = {
  50: '#E9F3F7', // blue fill, original #dbfaff
  100: '#D1E6EF',
  200: '#B8D9E6',
  300: '#87BED4',
  400: '#56A4C3',
  500: '#2489B1', // light blue
  600: '#1279A2',
  700: '#006893', // dark blue
  800: '#003E59',
  900: '#00131f',
}

// https://coolors.co/8d6300-a97908-c48f10-e0a518-fbba20-fdc432-fece43-fed254-feda72
const yellow = {
  50: '#fdfbe0',
  100: '#FEE592',
  200: '#FEDA6B',
  300: '#FECE43', // light yellow
  400: '#FDC432',
  500: '#FBBA20', // dark yellow
  600: '#E0A518',
  700: '#C48F10',
  800: '#A97908',
  900: '#8D6300', // button yellow
}

// Chakra default
const teal = {
  50: '#E6FFFA',
  100: '#B2F5EA',
  200: '#81E6D9',
  300: '#4FD1C5',
  400: '#38B2AC',
  500: '#319795',
  600: '#2C7A7B',
  700: '#285E61',
  800: '#234E52',
  900: '#1D4044',
}

// Chakra default
const red = {
  50: '#fef0f0',
  100: '#FED7D7',
  200: '#FEB2B2',
  300: '#FC8181',
  400: '#F56565',
  500: '#E53E3E',
  600: '#C53030',
  700: '#9B2C2C',
  800: '#822727',
  900: '#63171B',
}

// Chakra default
const gray = {
  50: '#F7FAFC',
  100: '#EDF2F7',
  200: '#E2E8F0',
  300: '#CBD5E0',
  400: '#A0AEC0',
  500: '#718096',
  600: '#4A5568',
  700: '#2D3748',
  800: '#1A202C',
  900: '#171923',
}

export const custom = {
  //primary
  blueLight: blue['500'],
  blueDark: blue['700'],
  blueFill: blue['50'],
  // highlight
  yellowDark: yellow['500'],
  yellowLight: yellow['300'],
  yellowButton: yellow['900'],
  // signal
  disabled: gray['200'],
  error: red['400'],
  errorFill: red['50'],
  check: teal['300'],
}

export const text = {
  default: gray['600'],
}

export const colors = {
  white: '#ffffff',
  black: '#000000',
  custom,
  teal,
  yellow,
  gray,
  red,
  blue,
  text,
  focus: 'rgba(66, 153, 225, 0.6)',
}

export type CustomColor = keyof typeof custom

export type ColorSchemeKey = 'blue' | 'red' | 'teal' | 'yellow' | 'gray'
