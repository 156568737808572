import { FetchResult } from '@apollo/client/link/core'
import {
  chakra,
  Divider,
  Flex,
  VStack,
  Button,
  useDisclosure,
  Link,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaTwitter, FaXing, FaLinkedin } from 'react-icons/fa'

import { useUser } from 'src/auth/hooks/useUser'
import { User } from 'src/auth/types'
import { ChipList } from 'src/common/components/ChipList'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'src/common/components/Modal'
import { ReadMore } from 'src/common/components/ReadMore'
import { TopicType } from 'src/graphql/__generated__/types'
import { UserAvatar } from 'src/userprofile/components/UserAvatar'
import { getUserName } from 'src/userprofile/utils'

export interface ParticipantListItem {
  hasMatchingRequest: boolean
  isMatchingRequestActionActive: boolean
  isRejected: boolean
  isRequestRejectionAllowed: boolean
  isRequestedForMatch: boolean
  loading: boolean
  onCancel: (participantId: string) => Promise<void | FetchResult>
  onReject: (participantId: string) => Promise<void | FetchResult>
  onRequest: (participantId: string) => Promise<void | FetchResult>
  user: User
}

export const ParticipantListItem = ({
  user: participant,
  onRequest,
  onCancel,
  onReject,
  hasMatchingRequest,
  isRequestedForMatch,
  isMatchingRequestActionActive,
  isRejected,
  isRequestRejectionAllowed,
  loading,
}: ParticipantListItem): React.ReactElement => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const titleId = `event_participant_list_item_${participant.id}_title`
  const itemToString = (item: TopicType | null) => item?.name || ''
  const participantName = getUserName(participant)
  const participantId = participant.id

  const handleSendMatchingRequest = () => {
    isMatchingRequestActionActive &&
      onRequest(participantId).finally(() => {
        onClose()
      })
  }

  const handleCancelMatchingRequest = () => {
    isMatchingRequestActionActive && onCancel(participantId)
  }

  const handleRejectMatchingRequest = () => {
    isRequestRejectionAllowed && onReject(participantId)
  }

  return (
    <Flex
      layerStyle={'elevated'}
      as={'article'}
      px={'2.4rem'}
      py={'4.8rem'}
      borderRadius={'md'}
      justify={'center'}
      align={'center'}
      direction={'column'}
      aria-labelledby={titleId}
    >
      <UserAvatar
        boxSize={'16rem'}
        userProfile={participant?.profile}
        bg={'gray.300'}
      />
      <chakra.header as={VStack} mt={'2.4rem'}>
        <chakra.h2 textStyle={'h4'} color={'custom.blueDark'} id={titleId}>
          {user?.id === participant.id ? t('you') : participantName}
        </chakra.h2>
        <chakra.p textStyle={'text'}>
          {participant?.profile?.organisation + ', ' + participant.profile?.job}
        </chakra.p>
      </chakra.header>
      {participant.profile?.phone && (
        <chakra.div
          maxWidth={'100%'}
          mt={'2.4rem'}
          display={'flex'}
          alignItems={'center'}
        >
          <Link href={'tel:' + participant.profile.phone} isExternal>
            {participant.profile.phone}
          </Link>
        </chakra.div>
      )}
      {participant.profile?.showEmail && (
        <Link href={'mailto:' + participant.email} isExternal>
          {participant.email}
        </Link>
      )}
      <chakra.div css={{ display: 'flex', alignContent: 'space-evenly' }}>
        {participant.profile?.twitter && (
          <Link href={participant.profile.twitter} isExternal>
            <FaTwitter size={22} />
          </Link>
        )}
        {participant.profile?.linkedIn && (
          <Link href={participant.profile.linkedIn} isExternal>
            <FaLinkedin size={22} />
          </Link>
        )}
        {participant.profile?.xing && (
          <Link href={participant.profile.xing} isExternal>
            <FaXing size={22} />
          </Link>
        )}
      </chakra.div>

      {participant.profile?.aboutMe && (
        <chakra.div maxWidth={'100%'} mt={'2.4rem'}>
          <ReadMore text={participant.profile?.aboutMe} />
        </chakra.div>
      )}
      <chakra.div width={'100%'} mt={{ base: '1.6rem', md: '2.4rem' }}>
        {participant.matchingEventGroupTag && (
          <>
            <chakra.div mt={'1.6rem'}>
              <chakra.p textStyle={'text'} mb={'0.8rem'}>
                {t('common:participantList.group')}
              </chakra.p>
              <ChipList
                key={participant.matchingEventGroupTag}
                items={[participant.matchingEventGroupTag]}
                itemToString={(value) => value}
                variant={'blue'}
              />
            </chakra.div>
            <Divider mt={'1.6rem'} mb={'1.6rem'} />
          </>
        )}
        <chakra.div mt={'1.6rem'}>
          <chakra.p textStyle={'text'} mb={'0.8rem'}>
            {t('common:myProfile.offeredTopics')}
          </chakra.p>
          {participant.offeredTopics.length > 0 && (
            <ChipList
              items={participant.offeredTopics}
              itemToString={itemToString}
              isReadonly
            />
          )}
        </chakra.div>
        <Divider mt={'1.6rem'} mb={'1.6rem'} />
        <chakra.div>
          <chakra.p textStyle={'text'} mb={'0.8rem'}>
            {t('common:myProfile.demandedTopics')}
          </chakra.p>
          {participant.demandedTopics.length > 0 && (
            <ChipList
              items={participant.demandedTopics}
              itemToString={itemToString}
              isReadonly
            />
          )}
        </chakra.div>
        {isMatchingRequestActionActive &&
          user?.id !== participant.id &&
          !isRejected && (
            <Button
              mt={'4rem'}
              width={'100%'}
              isLoading={loading}
              variant={hasMatchingRequest ? 'tertiary' : 'secondary'}
              onClick={() =>
                hasMatchingRequest ? handleCancelMatchingRequest() : onOpen()
              }
            >
              {hasMatchingRequest
                ? t('common:participantList.cancelRequest')
                : t('common:participantList.requestMeeting')}
            </Button>
          )}
        {isMatchingRequestActionActive &&
          isRequestRejectionAllowed &&
          user?.id !== participant.id &&
          hasMatchingRequest &&
          isRequestedForMatch && (
            <chakra.p textStyle={'text'} mb={'0.8rem'}>
              {t('common:participantList.bidirectionalRequest')}
            </chakra.p>
          )}
        {isMatchingRequestActionActive &&
          isRequestRejectionAllowed &&
          user?.id !== participant.id &&
          isRequestedForMatch &&
          !hasMatchingRequest && (
            <Button
              mt={'2.4rem'}
              width={'100%'}
              isLoading={loading}
              variant={'tertiary'}
              onClick={() => handleRejectMatchingRequest()}
            >
              {t('common:participantList.rejectRequest')}
            </Button>
          )}
      </chakra.div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalHeader>
          {t('common:participantList.sendMatchingRequest', {
            participantName: participantName,
          })}
        </ModalHeader>
        <ModalBody>
          {t('common:participantList.sendMatchingRequestConfirm', {
            participantName: participantName,
          })}
        </ModalBody>
        <ModalFooter direction={'vertical'}>
          <Button onClick={onClose} variant={'secondary'} colorScheme={'blue'}>
            {t('common:cancel')}
          </Button>
          <Button isLoading={loading} onClick={handleSendMatchingRequest}>
            {t('common:participantList.sendRequest')}
          </Button>
        </ModalFooter>
      </Modal>
    </Flex>
  )
}
