export default {
  greetings: 'Greetings from EvilBeard Team',
  skipLinkLabel: 'skip to main content',
  mainAnchorLabel: 'main content',
  notFound: 'Error | 404',
  noResultsFound: 'No results found.',
  resetFilter: 'Reset filter',
  you: 'you',
  hint: 'Hint',
  showMore: 'Show more',
  showLess: 'Show less',
  back: 'back',
  mainNavigation: {
    label: 'main navigation',
    home: 'Home',
    events: 'Events',
    blog: 'Blog',
    close: 'close main navigation',
    open: 'open main navigation',
    menu: 'menu',
  },
  userMenu: {
    label: 'user menu',
    profile: 'profile',
    logout: 'Log out',
  },
  footerNavigation: {
    label: 'Footer Navigation',
    imprint: 'Imprint',
    dataPolicy: 'Data policy',
    about: 'About VDI/VDE-IT',
  },
  nextMatchLogo: 'nextmatch Logo',
  login: 'Login',
  register: 'Register',
  email: 'E-mail',
  contacts: 'Contacts',
  socialMedia: 'Social Media',
  password: 'Password',
  error: 'Error',
  cancel: 'Cancel',
  close: 'Close',
  clearInput: 'Delete entry',
  oClock: '',
  save: 'Save',
  edit: 'Edit',
  backToLogin: 'Back to login',
  auth: {
    showPassword: 'Show password',
    hidePassword: 'Hide password',
  },
  registerPage: {
    heading: 'Register',
    subHeading:
      'Welcome to nextmatch! ' +
      'Please register with your e-mail address and a secure password.',
    doRegister: 'Agree and register',
  },
  accountActivation: {
    heading: 'Please confirm your e-mail address.',
    subHeading:
      'We will send you an e-mail with an activation link shortly. Please also check your spam folder if you do not receive an e-mail.',
  },
  loginPage: {
    title: 'Login',
    subHeading: 'Log in to join events and find new contacts.',
    doLogin: 'Log in',
    doLogout: 'Log out',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot your password?',
  },
  errors: {
    unknown: 'An unknown error has occured.',
    missingRequired: 'Please fill out this field.',
    invalidEmail: 'Please enter a valid e-mail address.',
    passwordMinLength: 'Password must be at least eight characters long.',
    passwordMissingSpecialCharacters:
      'Password must contain at least one special character.',
    passwordMissingNumber: 'Password must contain at least one number.',
    passwordMissingLetter: 'Password must contain at least one letter.',
    inputTooLong: 'The input is too long.',
    moreThanEightTopics: 'A maximum of eight topics can be selected.',
    networkError:
      'A network error occurred. Please check you internet connection.',
    fileCompressingError: 'Something went wrong. Please select another image.',
    exceedingInputLimit: 'You can only enter 450 characters',
    atLeastOneTopic: 'Please add at least one element',
    missingRequiredToast:
      'ou have not provided all the information mandatory to participate in Matching Events.',
    invalidPhoneInput:
      "Phone number formatted incorrectly, please use the following format: '+999999999'",
  },
  apiErrors: {
    not_authenticated:
      'You seem to be no longer logged in. Please reload the page.',
    validation_error:
      'Unfortunately, an error occurred while validating your input.',
    user_not_active:
      'This account has not been activated yet.\n' +
      'You have received an email with an activation link after your registration (please also check your spam folder). After clicking the activation link in the email, you can use your account.',
    invalid_credentials: 'This combination of e-mail and password is invalid.',
    password_too_short: 'Password must be at least eight characters long.',
    password_too_common: 'This password is too common.',
    email_unique: 'Someone is already registered with this e-mail address.',
    password_has_no_small_letters:
      'The password must contain at least one lowercase letter.',
    password_has_no_capital_letters:
      'The password must contain at least one uppercase letter.',
    password_has_no_special_characters:
      'Password must contain at least one special character.',
    activation_link_invalid: 'Invalid activation link',
    reset_password_link_invalid:
      'This password reset link is invalid or expired',
    matching_event_does_not_exist:
      'This event no longer exists. Please reload the page.',
    user_profile_not_complete:
      'It seems that your profile is incomplete. Update it now to continue.',
    invalid_topics_input:
      'This selection of topics is invalid. Either it contains duplicates or obsolete entries.' +
      'Please check your selection.',
    subscription_deadline_is_passed:
      'The registration for this event has already expired.',
    matching_request_phase_not_started:
      'You cannot submit your call requests yet. This will be possible as soon as the registration has expired.',
    matching_request_phase_is_passed:
      'Unfortunately, the deadline for submitting meeting requests has already passed.',
    requested_user_not_participant:
      'This user is no longer participating in this event. Please reload the page.',
    current_user_not_participant:
      'You are no longer participating in this event. Please reload the page.',
  },
  registerTermsAndConditions:
    'By clicking on "Agree and register" you agree to the <1>user agreement</1>, <3>data policy</3> and <5>cookie policy</5> of nextmatch.',
  myProfile: {
    profile: 'Profile',
    generalInfo: 'General information',
    deleteAccount: 'Delete account',
    matchingCriteria: 'Matching criteria',
    noInput: 'No input',
    visibleToPublic: 'Visible to everyone',
    visibleToMatches: 'Visible to matches',
    demandedTopics: 'I am looking for',
    offeredTopics: 'I offer',
    deleteAccountDialog:
      'If you have problems with your account, please contact the support team of nextmatch via nextmatch@vdivde-it.de. \n\n If you really want to delete your nextmatch account and all related information irretrievably, please click "Delete account". We will miss you and hope to see you soon!',
    showEmailText: 'Show my email to other users',
  },
  userProfile: {
    firstName: 'First name',
    lastName: 'Last name',
    title: 'Title',
    gender: 'Gender',
    organisation: 'Organisation',
    aboutMe: 'About me',
    job: 'Position',
    phone: 'Phone number',
    showEmail: 'Email',
    twitter: 'Twitter',
    linkedIn: 'LinkedIn',
    xing: 'Xing',
  },
  gender: {
    M: 'Male',
    F: 'Female',
    D: 'Diverse',
  },
  salutation: {
    M: 'Mr',
    F: 'Mrs',
  },
  matchingEvent: {
    events: 'Events',
    startAndEndTime: '{{startTime}} until {{endTime}}',
    showDetails: 'Show details',
    exclusive: 'Exclusive event',
    datetimePlaceFormat: '{{date}} • {{time}} • {{city}}',
    doSubscribe: 'Register for the event',
    doUnsubscribe: 'Unsubscribe from this event',
    notCompletedProfileHint:
      'Please complete the information in your profile first so that you can register for this event.',
    unsubscribeConfirm: 'Confirm unsubscription',
    unsubscribeFromEvent: 'Unsubscribe from event',
    unsubscribeFromEventInfo:
      'By clicking on "Confirm unsubscription” you unsubscribe from this event. ' +
      'If you have already made matching requests, these will also be deleted',
    participants: 'participants',
    requestMeetings: 'Show participants',
    requestMeetingsInfoText: 'You can send matching requests here.',
    loginToSeeOtherParticipants:
      'Please log in first to see other participants',
    backToMatchingEventDetails: 'Back to event',
    matchingResults: 'Matching the search',
    searchParticipants: 'Find suitable participants',
    subscriptionDeadlineExpiresAt:
      'The registration period ends on {{date}} at {{time}}.',
    matchingRequestDeadlineFromUntil:
      'In the period from {{fromDate}} to {{untilDate}} at {{untilTime}} you can submit your meeting requests. After the deadline you will receive your personalized meeting schedule.',
    matchingRequestDeadlineWithOverlap:
      'You can submit your meeting requests until {{untilDate}} at {{untilTime}}. After the deadline you will receive your personalized meeting schedule.',
    matchingRequestDeadline:
      'You can send requests until the start of the event. You will receive your personalized preliminary schedule here shortly and in final form at the beginning of the event.',
    passphrase: {
      default: 'Passphrase',
      passphraseRequired:
        'This exclusive event is reserved for invited guests. Please enter the passphrase you received with the invitation to access the events details and registration.',
      submitPassphrase: 'Submit passphrase',
    },
  },
  participantList: {
    requestMeeting: 'Request meeting',
    sendMatchingRequest: 'Send meeting request to {{participantName}}',
    sendMatchingRequestConfirm:
      'If {{participantName}} is also interested in a conversation, a shared time slot will be generated as part of this event, with the possibility of getting to know each other.',
    sendRequest: 'Send request',
    cancelRequest: 'Cancel request',
    rejectRequest: 'Decline meeting request',
    bidirectionalRequest: 'This person has also requested a meeting with you',
    group: 'Group',
    filter: {
      label: 'Filter by',
      groupLabel: 'Filter by groups',
      all: 'Show all',
      requestFromUser: 'Requested participants',
      requestToUser: 'Requesting participants',
    },
  },
  schedule: {
    schedule: 'Event-Schedule',
    temporarySchedule: 'Temporary Event-Schedule',
    temporaryScheduleInfo:
      'Here you can see your preliminary schedule. This is based on the call requests sent and received so far. Please note that the schedule may still change until the start of the event.',
    noSessions:
      'Your schedule is empty. If you think this is a mistake, please contact the nextmatch Support Team: nextmatch@vdivde-it.de',
    sessionSlot: '{{startTime}} to {{endTime}} • table {{tableNumber}}',
    match: 'Match',
    requestFromCurrentUser: 'Initiated by you',
    currentUserRequested: 'Initiated by {{fullName}}',
    cancelSession: 'Cancel Meeting',
  },
  requestNewPassword: {
    passwordResetEmailSend: 'E-mail sent',
    passwordResetEmailSendDescription:
      'An e-mail to reset the password has been sent. Please check your inbox.',
    heading: 'Forgot password',
    subHeading:
      'Enter your e-mail address here so we can send you a link to reset your password.',
    sendRequest: 'Send link to reset',
  },
  resetPassword: {
    heading: 'Reset password',
    subHeading:
      'Please enter a new password, which will be used for your future login.',
    newPassword: 'New password',
    saveNewPassword: 'Save new password',
    passwordReset: 'Password has been reset',
    passwordResetConfirmation: 'You can now log in with your new password.',
  },
  languageMenu: {
    label: 'Language menu',
    german: 'German',
    english: 'English (UK/US)',
  },
}
