import { ApolloErrorMessage, ErrorPage404 } from 'src/common/components/Error'
import {
  EntryQueryResult,
  useEntryQuery,
} from 'src/graphql/__generated__/types'
import {
  StaticPage,
  StaticPageSkeleton,
} from 'src/staticpages/components/StaticPage'

export const AboutPage = (): React.ReactElement | null => {
  const { data, loading, error }: EntryQueryResult = useEntryQuery({
    variables: { slug: 'ABOUT_PAGE' },
  })

  if (loading) return <StaticPageSkeleton />

  if (!loading && error) return <ApolloErrorMessage error={error} />

  return data?.entry ? (
    <StaticPage title={data.entry.title} body={data.entry.body} />
  ) : (
    <ErrorPage404 />
  )
}
