import { chakra, Stack, Flex } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReactLink } from 'react-router-dom'

import { maxWidthCss, paddedSectionCss } from 'src/theme/layout'

import { navLinkCss } from './Header.styles'
import { FOOTER_NAV_ITEMS } from './constants'

export const Footer = (): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <chakra.footer bg={'gray.50'}>
      <Flex
        as={'nav'}
        aria-label={t('common:footerNavigation.label')}
        css={[paddedSectionCss, maxWidthCss]}
        justify={'center'}
      >
        <Stack
          as={'ul'}
          direction={{ base: 'column', md: 'row' }}
          justify={'center'}
          spacing={'3.2rem'}
          align={'center'}
          padding={{ base: '2.1rem 0 2.9rem 0', xl: '3.5rem 0' }}
        >
          {FOOTER_NAV_ITEMS(t).map((item, index) => (
            <chakra.li key={index}>
              <chakra.a
                as={ReactLink}
                to={item.link}
                textStyle={'h5'}
                css={navLinkCss}
              >
                {item.title}
              </chakra.a>
            </chakra.li>
          ))}
        </Stack>
      </Flex>
    </chakra.footer>
  )
}
