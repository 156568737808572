import { colors } from '../colors'

export const Link = {
  baseStyle: {
    textDecoration: 'none',
    color: colors.text.default,
    '&:hover': {
      textDecoration: 'none',
      color: colors.custom.blueDark,
    },
  },
  variants: {
    primary: {
      color: colors.custom.blueDark,
      '&:hover': {
        color: colors.custom.blueLight,
      },
    },
  },
}
