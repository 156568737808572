import { css } from '@emotion/react'

import { visuallyHiddenCss } from 'src/common/utils/style.utils'
import { theme } from 'src/theme'

export const btnCss = css`
  ${visuallyHiddenCss};

  &:focus {
    z-index: ${theme.zIndices.skipLink};
    position: fixed;
    top: 0;
    left: 0;
    padding: 1rem 3rem;
    background: ${theme.colors.custom.blueFill};
    border: 0.2rem solid ${theme.colors.custom.blueDark};
    outline: none;
  }
`
