import { chakra, Flex, Link } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link as ReactLink } from 'react-router-dom'

import { RichText } from 'src/common/components/RichText'
import { routes } from 'src/common/routes'
import { DateFormat, dateTimeFormat } from 'src/common/utils/date.utils'
import { MatchingEventNode } from 'src/graphql/__generated__/types'
import { colors } from 'src/theme/colors'

import { containerCss } from './MatchingEventListItem.styles'

export interface MatchingEventListItem {
  event: Pick<
    MatchingEventNode,
    'id' | 'slug' | 'name' | 'teaserText' | 'city' | 'startDate' | 'isExclusive'
  >
}

export const ExclusiveTag = (): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <Flex
      marginLeft={{ base: '-3.4rem', md: '-5.8rem' }}
      marginRight={'2rem'}
      direction={'column'}
      alignSelf={'flex-start'}
    >
      <chakra.div
        padding={'1.2rem 1.8rem'}
        color={colors.white}
        bg={colors.gray['600']}
        borderRightRadius={'5rem'}
        textStyle={'h6'}
      >
        {t('common:matchingEvent.exclusive')}
      </chakra.div>
      <chakra.div
        borderTop={`1.2rem solid ${colors.blue['900']}`}
        borderLeft={'1rem solid transparent'}
        width={0}
        height={0}
      />
    </Flex>
  )
}

export const MatchingEventListItem = ({
  event,
}: MatchingEventListItem): React.ReactElement => {
  const { t, i18n } = useTranslation()
  const titleId = `event_list_item_${event.id}_title`
  const startDate = new Date(event.startDate)
  const date = dateTimeFormat(startDate, DateFormat.Date, i18n.language)
  const time = dateTimeFormat(startDate, DateFormat.Time, i18n.language)
  const dateTimeLocationInfo: {
    date: string
    time: string
    location?: string
  } = {
    date,
    time: `${time} ${t('common:oClock')}`,
    ...(event?.city && { location: event.city }),
  }
  return (
    <chakra.li css={containerCss} layerStyle={'elevated'}>
      <chakra.article aria-labelledby={titleId}>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'left', md: 'center' }}
          position={'relative'}
        >
          {event.isExclusive && <ExclusiveTag />}
          <chakra.div
            textStyle={'h6'}
            color={colors.gray['500']}
            margin={{ base: '1.4rem 0 0 0', md: '0 0 1rem 0' }}
          >
            {Object.values(dateTimeLocationInfo).join(' • ')}
          </chakra.div>
        </Flex>
        <chakra.h2
          textStyle={'h4'}
          color={colors.custom.blueDark}
          marginTop={'1.2rem'}
          id={titleId}
        >
          {event.name}
        </chakra.h2>
        <chakra.div textStyle={'text'} marginTop={'3.2rem'}>
          <RichText html={event.teaserText} />
        </chakra.div>
        <chakra.div marginTop={'2.4rem'}>
          <Link
            textStyle={'h6'}
            as={ReactLink}
            variant={'primary'}
            to={routes.matchingEventDetails(event.slug)}
          >
            {t('common:matchingEvent.showDetails')}
          </Link>
        </chakra.div>
      </chakra.article>
    </chakra.li>
  )
}
