import { Skeleton, chakra, SkeletonText } from '@chakra-ui/react'

export type SkeletonBlogArticleListItem = Record<string, never>

export const SkeletonBlogArticleListItem = (): React.ReactElement => {
  return (
    <chakra.li>
      <Skeleton w={'50%'} h={'2rem'} maxW={'22rem'} />
      <Skeleton w={'80%'} h={'2.8rem'} maxW={'60rem'} mt={'2.4rem'} />
      <SkeletonText
        noOfLines={10}
        mt={'3.2rem'}
        skeletonHeight={'1rem'}
        spacing={'1rem'}
      />
    </chakra.li>
  )
}
