import { colors } from 'src/theme/colors'

export const FormLabel = {
  baseStyle: {
    color: colors.gray[500],
    mb: '0',
    pb: '0.8rem',
    _disabled: {
      color: colors.custom.disabled,
    },
    _invalid: {
      color: colors.custom.error,
    },
  },
}

export const FormError = {
  baseStyle: {
    text: {
      color: colors.custom.error,
    },
  },
}
