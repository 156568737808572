import { User } from 'src/auth/types'
import { Maybe, UserProfileType } from 'src/graphql/__generated__/types'

export const getUserName = (
  user: Pick<User, 'email'> & {
    profile?: Maybe<Pick<UserProfileType, 'title' | 'firstName' | 'lastName'>>
  },
): string => {
  const name = user.profile
    ? `${user.profile?.title} ${user.profile?.firstName} ${user?.profile?.lastName}`.trim()
    : ''
  return name || user.email.split(/[@.]/)[0]
}
