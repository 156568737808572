import {
  chakra,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Textarea,
} from '@chakra-ui/react'
import React, { useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaLinkedin, FaTwitter, FaXing } from 'react-icons/fa'
import { MdKeyboardArrowDown } from 'react-icons/md'

import { User } from 'src/auth/types'
import { ApolloErrorMessage } from 'src/common/components/Error'
import {
  Gender,
  UserProfileGender,
  UserProfileType,
  useUserProfileMutation,
} from 'src/graphql/__generated__/types'
import { FormSection } from 'src/userprofile/components/FormSection'

import { formFieldCss, inputCss, maxWidthCss } from './MyProfileForm.styles'

export interface UserProfileForm {
  user: User
}

export interface UserProfileFormInput {
  firstName: string
  lastName: string
  title: string
  gender: UserProfileGender | ''
  organisation: string
  aboutMe: string
  job: string
  twitter: string
  linkedIn: string
  xing: string
  showEmail: boolean
  phone: string
}

const getInitialData = (
  profile: UserProfileType | null | undefined,
): UserProfileFormInput => {
  return {
    firstName: profile?.firstName || '',
    lastName: profile?.lastName || '',
    title: profile?.title || '',
    gender: profile?.gender || '',
    organisation: profile?.organisation || '',
    aboutMe: profile?.aboutMe || '',
    job: profile?.job || '',
    twitter: profile?.twitter || '',
    xing: profile?.xing || '',
    linkedIn: profile?.linkedIn || '',
    showEmail: profile?.showEmail || false,
    phone: profile?.phone || '',
  }
}

export const MyProfileForm = ({
  user,
}: UserProfileForm): React.ReactElement => {
  const { t } = useTranslation()
  const currentProfile = user?.profile
  const defaultValues = useMemo(() => {
    return getInitialData(currentProfile)
  }, [currentProfile])
  const [isEdit, setIsEdit] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const [updateUserProfile, { loading, error }] = useUserProfileMutation({
    // prevent "unhandled promise rejection"
    // display errors via `error` object
    onError: () => null,
  })

  const {
    register,
    handleSubmit,
    formState,
    control,
    setFocus,
  } = useForm<UserProfileFormInput>({
    mode: 'onTouched',
    defaultValues: defaultValues,
  })

  const handleEditButtonClick = (e: React.MouseEvent) => {
    if (!isEdit) {
      // prevent form from submit
      e.preventDefault()
      setIsEdit(true)
      setTimeout(() => {
        // focus first element in form
        setFocus('gender')
      }, 0)
    }
  }

  const onSubmit = (data: UserProfileFormInput) => {
    setIsEdit(false)
    updateUserProfile({
      variables: {
        userData: {
          ...data,
          gender: (data.gender as Gender) || null,
        },
      },
    })
      // we have to catch apollo errors even if we access them trough the returned error object
      .catch(() => {
        setIsEdit(true)
      })
      .finally(() => {
        buttonRef.current?.focus()
      })
  }

  const isReadOnly = !isEdit
  const placeholder = t('common:myProfile.noInput')

  return (
    <FormSection
      prefix={'my_profile_form'}
      title={t('common:myProfile.generalInfo')}
      onSubmit={handleSubmit(onSubmit)}
      isEdit={isEdit}
      handleButtonClick={handleEditButtonClick}
      buttonRef={buttonRef}
      loading={loading}
    >
      <ApolloErrorMessage error={error} mt={'3rem'} />

      <chakra.div mt={'3rem'}>
        <FormControl id={'gender'} css={formFieldCss}>
          <FormLabel>{t('common:userProfile.gender')}</FormLabel>
          <chakra.div
            // setting the max with on the <Select> does not work
            css={maxWidthCss}
          >
            <Select
              {...register('gender')}
              css={inputCss}
              iconSize={'2.2rem'}
              icon={<MdKeyboardArrowDown />}
              // for some reason the <Select> is still editable when set to isReadonly=true
              isDisabled={isReadOnly}
            >
              {Object.entries(UserProfileGender).map(
                ([optionKey, optionValue]) => (
                  <option key={optionKey} value={optionValue}>
                    {t(`common:gender.${optionValue}`)}
                  </option>
                ),
              )}
              <option value={''}>{placeholder}</option>
            </Select>
          </chakra.div>
        </FormControl>
        <FormControl
          id={'title'}
          isInvalid={!!formState.errors.title}
          isReadOnly={isReadOnly}
          css={formFieldCss}
        >
          <FormLabel>{t('common:userProfile.title')}</FormLabel>
          <Input
            {...register('title', {
              maxLength: {
                value: 100,
                message: t('common:errors.inputTooLong'),
              },
            })}
            css={[inputCss, maxWidthCss]}
            placeholder={placeholder}
          />
          <FormErrorMessage>
            {formState.errors?.title?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id={'firstName'}
          isInvalid={!!formState.errors.firstName}
          css={formFieldCss}
          isRequired
        >
          <FormLabel>{t('common:userProfile.firstName')}</FormLabel>
          <Input
            {...register('firstName', {
              required: t('common:errors.missingRequired') as string,
              maxLength: {
                value: 100,
                message: t('common:errors.inputTooLong'),
              },
            })}
            isReadOnly={isReadOnly}
            css={[inputCss, maxWidthCss]}
            placeholder={placeholder}
          />
          <FormErrorMessage>
            {formState.errors?.firstName?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id={'lastName'}
          isInvalid={!!formState.errors.lastName}
          isReadOnly={isReadOnly}
          css={formFieldCss}
          isRequired
        >
          <FormLabel>{t('common:userProfile.lastName')}</FormLabel>
          <Input
            {...register('lastName', {
              required: t('common:errors.missingRequired') as string,
              maxLength: {
                value: 100,
                message: t('common:errors.inputTooLong'),
              },
            })}
            css={[inputCss, maxWidthCss]}
            placeholder={placeholder}
          />
          <FormErrorMessage>
            {formState.errors?.lastName?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id={'organisation'}
          isInvalid={!!formState.errors.organisation}
          isReadOnly={isReadOnly}
          css={formFieldCss}
          isRequired
        >
          <FormLabel>{t('common:userProfile.organisation')}</FormLabel>
          <Input
            {...register('organisation', {
              required: t('common:errors.missingRequired') as string,
              maxLength: {
                value: 255,
                message: t('common:errors.inputTooLong'),
              },
            })}
            css={[inputCss, maxWidthCss]}
            placeholder={placeholder}
          />
          <FormErrorMessage>
            {formState.errors?.organisation?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id={'job'}
          isInvalid={!!formState.errors.job}
          isReadOnly={isReadOnly}
          css={formFieldCss}
          isRequired
        >
          <FormLabel>{t('common:userProfile.job')}</FormLabel>
          <Input
            {...register('job', {
              required: t('common:errors.missingRequired') as string,
              maxLength: {
                value: 255,
                message: t('common:errors.inputTooLong'),
              },
            })}
            css={[inputCss, maxWidthCss]}
            placeholder={placeholder}
          />
          <FormErrorMessage>{formState.errors?.job?.message}</FormErrorMessage>
        </FormControl>
        <Controller
          control={control}
          name={'aboutMe'}
          rules={{
            maxLength: 450,
          }}
          render={({
            field: { value, onChange, onBlur, ref, name },
            fieldState: { invalid },
          }) => {
            return (
              <FormControl
                id={name}
                isInvalid={invalid}
                isReadOnly={isReadOnly}
                css={formFieldCss}
              >
                <FormLabel>{t('common:userProfile.aboutMe')}</FormLabel>
                <Textarea
                  onChange={onChange}
                  onBlur={onBlur}
                  css={[inputCss, maxWidthCss]}
                  placeholder={placeholder}
                  value={value}
                  ref={ref}
                  rows={isReadOnly && !value ? 2 : 5}
                  resize={isReadOnly ? 'none' : undefined}
                />
                {isEdit && !invalid && (
                  <FormHelperText>{value.length}/450</FormHelperText>
                )}
                <FormErrorMessage>{value.length}/450</FormErrorMessage>
                <FormErrorMessage>
                  {t('common:errors.exceedingInputLimit')}
                </FormErrorMessage>
              </FormControl>
            )
          }}
        />
        <FormControl
          id={'phone'}
          isInvalid={!!formState.errors.phone}
          css={formFieldCss}
        >
          <FormLabel>{t('common:userProfile.phone')}</FormLabel>
          <Input
            {...register('phone', {
              maxLength: {
                value: 16,
                message: t('common:errors.inputTooLong'),
              },
              pattern: /\x2B?1?\d{8,15}/,
            })}
            isReadOnly={isReadOnly}
            css={[inputCss, maxWidthCss]}
            placeholder={'+49303100780'}
          />
          <FormErrorMessage>
            {formState.errors?.phone?.type === 'pattern' &&
              t('common:errors.invalidPhoneInput')}
          </FormErrorMessage>
          <FormErrorMessage>
            {formState.errors?.phone?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id={'showEmail'}
          isInvalid={!!formState.errors.showEmail}
          css={formFieldCss}
        >
          <FormLabel>{t('common:userProfile.showEmail')}</FormLabel>
          <Checkbox
            {...register('showEmail')}
            css={[inputCss, maxWidthCss]}
            isReadOnly={isReadOnly}
          >
            <chakra.p>{t('common:myProfile.showEmailText')}</chakra.p>
          </Checkbox>
          <FormErrorMessage>
            {formState.errors?.showEmail?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id={'twitter'}
          isInvalid={!!formState.errors.twitter}
          css={formFieldCss}
        >
          <FormLabel>{t('common:userProfile.twitter')}</FormLabel>
          <InputGroup>
            <InputLeftAddon>
              <FaTwitter />
            </InputLeftAddon>
            <Input
              {...register('twitter', {
                maxLength: {
                  value: 150,
                  message: t('common:errors.inputTooLong'),
                },
              })}
              isReadOnly={isReadOnly}
              css={[inputCss, maxWidthCss, { marginLeft: '1rem' }]}
              placeholder={placeholder}
            />
          </InputGroup>

          <FormErrorMessage>
            {formState.errors?.twitter?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id={'linkedIn'}
          isInvalid={!!formState.errors.linkedIn}
          css={formFieldCss}
        >
          <FormLabel>{t('common:userProfile.linkedIn')}</FormLabel>
          <InputGroup>
            <InputLeftAddon>
              <FaLinkedin />
            </InputLeftAddon>
            <Input
              {...register('linkedIn', {
                maxLength: {
                  value: 150,
                  message: t('common:errors.inputTooLong'),
                },
              })}
              isReadOnly={isReadOnly}
              css={[inputCss, maxWidthCss, { marginLeft: '1rem' }]}
              placeholder={placeholder}
            />
          </InputGroup>

          <FormErrorMessage>
            {formState.errors?.linkedIn?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          id={'xing'}
          isInvalid={!!formState.errors.xing}
          css={formFieldCss}
        >
          <FormLabel>{t('common:userProfile.xing')}</FormLabel>
          <InputGroup>
            <InputLeftAddon>
              <FaXing />
            </InputLeftAddon>
            <Input
              {...register('xing', {
                maxLength: {
                  value: 150,
                  message: t('common:errors.inputTooLong'),
                },
              })}
              isReadOnly={isReadOnly}
              css={[inputCss, maxWidthCss, { marginLeft: '1rem' }]}
              placeholder={placeholder}
            />
          </InputGroup>

          <FormErrorMessage>{formState.errors?.xing?.message}</FormErrorMessage>
        </FormControl>
      </chakra.div>
    </FormSection>
  )
}
