import {
  ModalProps,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react'

export type Modal = ModalProps

/**
 * Custom Modal component with slightly adjusted defaults
 * */
export const Modal = ({
  children,
  motionPreset = 'slideInBottom',
  scrollBehavior = 'inside',
  size = 'lg',
  ...props
}: Modal): React.ReactElement => {
  return (
    <ChakraModal
      size={size}
      motionPreset={motionPreset}
      scrollBehavior={scrollBehavior}
      {...props}
    >
      <ModalOverlay />
      <ModalContent>{children}</ModalContent>
    </ChakraModal>
  )
}
