import { init } from '@sentry/react'

import config from './config'

const {
  env,
  sentryEnv: environment,
  sentryDSN: dsn,
  sentryRelease: release,
} = config

export const initSentry = (): void => {
  if (env === 'development' || !dsn) {
    console.info('Sentry reporting is disabled in development environment')
    return
  }
  if (!dsn) {
    console.info(
      'Sentry reporting disabled; set REACT_APP_SENTRY_DSN to enable',
    )
    return
  }

  init({ dsn, environment, release })
}
