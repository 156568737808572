import { chakra, StyleProps } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'

import {
  containerCss,
  deleteButtonCss,
} from 'src/common/components/Chip/Chip.styles'
import { visuallyHiddenCss } from 'src/common/utils/style.utils'

export interface Chip extends StyleProps {
  label: string
  isReadonly?: boolean
  onDelete?: () => void
  variant?: 'yellow' | 'blue' | 'gray' | 'outline'
}

export const Chip = ({
  label,
  isReadonly = true,
  onDelete,
  variant = 'yellow',
}: Chip): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <chakra.div
      css={[
        containerCss.base,
        !isReadonly && containerCss.deletable,
        containerCss[variant],
      ]}
      textStyle={'small'}
    >
      {label}{' '}
      {!isReadonly && (
        <chakra.button
          onClick={onDelete}
          css={[deleteButtonCss.base, deleteButtonCss[variant]]}
        >
          <chakra.span css={visuallyHiddenCss}>{t('common:close')}</chakra.span>
          <MdClose aria-hidden />
        </chakra.button>
      )}
    </chakra.div>
  )
}
