import { css } from '@emotion/react'
import { SerializedStyles } from '@emotion/utils'

import { BreakpointKey, media } from './breakpoints'

interface ColumnConfig {
  columns: number
  columnWidth: string
  gutter: string
}

export const layout = {
  maxWidth: {
    lg: '121.6rem', // 118 + 2 * paddingX.lg
    xl: '118rem',
    hero: '160rem',
  },
  paddingX: {
    base: '2.4rem',
    md: '3.2rem',
    lg: '1.8rem',
    xl: '0',
  },
}

export const grid: Record<BreakpointKey, ColumnConfig> = {
  sm: {
    columns: 6,
    columnWidth: '4.8rem',
    gutter: '0.8rem',
  },
  md: {
    columns: 9,
    columnWidth: '6.4rem',
    gutter: '1.6rem',
  },
  lg: {
    columns: 12,
    columnWidth: '6.4rem',
    gutter: '2rem',
  },
  xl: {
    columns: 12,
    columnWidth: '8rem',
    gutter: '2rem',
  },
}

export const columnsCss = (bp: BreakpointKey): SerializedStyles => {
  const { columns, gutter } = grid[bp]
  return css`
    grid-template-columns: repeat(${columns}, 1fr);
    grid-gap: ${gutter};
  `
}

export const defaultGridGapCss = css`
  grid-column-gap: ${grid.sm.gutter};

  ${media.md} {
    grid-column-gap: ${grid.md.gutter};
  }
  ${media.lg} {
    grid-column-gap: ${grid.lg.gutter};
  }
  ${media.xl} {
    grid-column-gap: ${grid.xl.gutter};
  }
`

export const maxWidthCss = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${media.lg} {
    max-width: ${layout.maxWidth.lg};
  }
  ${media.xl} {
    max-width: ${layout.maxWidth.xl};
  }
`

export const defaultGridCss = css`
  ${defaultGridGapCss};
  display: grid;
  grid-template-columns: repeat(${grid.sm.columns}, 1fr);

  ${media.md} {
    grid-template-columns: repeat(${grid.md.columns}, 1fr);
  }
  ${media.lg} {
    grid-template-columns: repeat(${grid.lg.columns}, 1fr);
  }
  ${media.xl} {
    grid-template-columns: repeat(${grid.xl.columns}, 1fr);
  }
`

export const paddedSectionCss = css`
  box-sizing: border-box;
  width: 100%;
  padding-left: ${layout.paddingX.base};
  padding-right: ${layout.paddingX.base};

  ${media.md} {
    padding-left: ${layout.paddingX.md};
    padding-right: ${layout.paddingX.md};
  }

  ${media.lg} {
    padding-left: ${layout.paddingX.lg};
    padding-right: ${layout.paddingX.lg};
  }

  ${media.xl} {
    padding-left: ${layout.paddingX.xl};
    padding-right: ${layout.paddingX.xl};
  }
`

export const defaultSectionCss = css`
  ${maxWidthCss};
  ${paddedSectionCss};
  ${defaultGridCss};
`
export const contentColumnFullCss = css`
  grid-column: 1 / -1;
`

export const contentColumnNormalCss = css`
  grid-column: 1 / -1;

  ${media.md} {
    grid-column: 2 / -2;
  }
`

export const defaultPageCss = css`
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
`
