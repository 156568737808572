import { css } from '@emotion/react'

import { theme } from 'src/theme'
import { textStylesCss } from 'src/theme/typographyCss'

export const richTextCss = css`
  h1 {
    ${textStylesCss.h1};
    margin-bottom: 2.4rem;

    ${theme.media.tablet} {
      margin-bottom: 1.8rem;
    }
  }
  h2 {
    ${textStylesCss.h2};
    margin-bottom: 1.8rem;

    ${theme.media.tablet} {
      margin-bottom: 1.8rem;
    }
  }
  h3 {
    ${textStylesCss.h3};
    margin-bottom: 1.6rem;

    ${theme.media.tablet} {
      margin-bottom: 1.8rem;
    }
  }

  h4 {
    ${textStylesCss.h4};
    margin-bottom: 1.6rem;

    ${theme.media.tablet} {
      margin-bottom: 1.8rem;
    }
  }

  p + p {
    margin-top: 1rem;
  }

  // we define margin-top only for successors of previous elements
  // --> basically the same as :not(:first-child)
  // --> avoid warnings about issues with :first-child selector and ssr
  * ~ {
    h1 {
      margin-top: 2.4rem;

      ${theme.media.tablet} {
        margin-top: 3.2rem;
      }
    }

    h2 {
      margin-top: 2.4rem;

      ${theme.media.tablet} {
        margin-top: 3.2rem;
      }
    }
    h3 {
      margin-top: 2.4rem;

      ${theme.media.tablet} {
        margin-top: 3.2rem;
      }
    }

    h4 {
      margin-top: 1.6rem;

      ${theme.media.tablet} {
        margin-top: 1.8rem;
      }
    }

    ul,
    ol {
      margin-top: 1.6rem;

      ${theme.media.tablet} {
        margin-top: 1.8rem;
      }
    }
  }

  a {
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.custom.blueLight};
    text-decoration: underline;

    &:hover {
      color: ${theme.colors.custom.blueDark};
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }
  li {
    display: list-item;
  }
`
