import { chakra } from '@chakra-ui/react'
import React, { AriaAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from 'src/auth/hooks/useUser'
import {
  ScheduleSessionListDocument,
  useCancelSessionMutation,
  useScheduleSessionListQuery,
} from 'src/graphql/__generated__/types'
import { sessionListCss } from 'src/matchingevent/components/SessionList/SessionList.styles'
import { SessionListItem } from 'src/matchingevent/components/SessionListItem'
import { SessionListItemSkeleton } from 'src/matchingevent/components/SessionListItem/SessionListItemSkeleton'
import { POLL_INTERVAL, SLOT_CHECK_INTERVAL } from 'src/matchingevent/constants'

export interface SessionList extends AriaAttributes {
  matchingEventId: string
}
export const SessionList = ({
  matchingEventId,
  ...ariaProps
}: SessionList): React.ReactElement => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [time, setTime] = useState(Date.now())
  const { data, loading } = useScheduleSessionListQuery({
    variables: { matchingEventId },
    pollInterval: POLL_INTERVAL,
  })
  const [cancelMutation, { error }] = useCancelSessionMutation({
    refetchQueries: [
      { query: ScheduleSessionListDocument, variables: { matchingEventId } },
    ],
  })
  const list = data?.scheduleSessionList ?? []
  const handleCancelSession = (sessionId: string) => {
    cancelMutation({
      variables: { sessionId: sessionId, matchingEventId: matchingEventId },
    })
  }

  const checkSlotIsActive = (start: string, end: string) => {
    const startTime = new Date(start).getTime()
    const endTime = new Date(end).getTime()

    return startTime <= time && time <= endTime
  }

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), SLOT_CHECK_INTERVAL)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => error && console.log(error), [error])

  if (loading)
    return (
      <chakra.div css={sessionListCss} as={'ol'} role={'list'} {...ariaProps}>
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <SessionListItemSkeleton key={index} />
          ))}
      </chakra.div>
    )
  if (!loading && list.length === 0)
    return (
      <chakra.div>
        <chakra.p textStyle={'text'}>
          {t('common:schedule.noSessions')}
        </chakra.p>
      </chakra.div>
    )

  return (
    <chakra.div css={sessionListCss} as={'ol'} role={'list'} {...ariaProps}>
      {list.map((item) => {
        return (
          item?.user1 &&
          item?.user2 &&
          item?.matchType && (
            <SessionListItem
              key={item.id}
              matchType={item.matchType}
              matchUser={item.user1.id === user?.id ? item.user2 : item.user1}
              onCancelSession={handleCancelSession}
              sessionId={item.id}
              slot={item.slot}
              tableNumber={item.tableNumber}
              slotIsActive={checkSlotIsActive(item.slot.start, item.slot.end)}
            />
          )
        )
      })}
    </chakra.div>
  )
}
