import {
  useToast,
  Button,
  chakra,
  Flex,
  ModalHeader,
  useDisclosure,
  Stack,
} from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useUser } from 'src/auth/hooks/useUser'
import { Chip } from 'src/common/components/Chip'
import { ApolloErrorMessage } from 'src/common/components/Error'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from 'src/common/components/Modal'
import { RichText } from 'src/common/components/RichText'
import { routes } from 'src/common/routes'
import {
  DateFormat,
  dateTimeFormat,
  isDatePassed,
} from 'src/common/utils/date.utils'
import {
  SubscribeForMatchingEventMutationHookResult,
  MatchingEventNode,
  Maybe,
  ParticipationNode,
  UnsubscribeForMatchingEventMutationHookResult,
  useSubscribeForMatchingEventMutation,
  useUnsubscribeForMatchingEventMutation,
  MatchingEventsDocument,
} from 'src/graphql/__generated__/types'
import { DeadlineInfo } from 'src/matchingevent/components/DeadlineInfo'
import { SessionList } from 'src/matchingevent/components/SessionList/SessionList'
import { contentColumnNormalCss } from 'src/theme/layout'

export interface MatchingEventDetailsSection {
  matchingEvent: Pick<
    MatchingEventNode,
    | 'id'
    | 'name'
    | 'slug'
    | 'description'
    | 'streetAndNumber'
    | 'zipCode'
    | 'city'
    | 'startDate'
    | 'endDate'
    | 'usersCount'
    | 'subscriptionDeadline'
    | 'matchingRequestDeadline'
    | 'isExclusive'
    | 'allowOverlappingDates'
    | 'showTemporarySchedule'
  > & {
    currentUserParticipation?: Maybe<Pick<ParticipationNode, 'id'>>
  }
}

export const MatchingEventDetailsSection = ({
  matchingEvent,
}: MatchingEventDetailsSection): React.ReactElement => {
  const { t, i18n } = useTranslation()
  const btnRef = useRef<HTMLButtonElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { user } = useUser()
  const id = matchingEvent.id
  const [
    subscribeForMatchingEvent,
    { loading: subscribeForMatchingEventLoading, error },
  ]: SubscribeForMatchingEventMutationHookResult = useSubscribeForMatchingEventMutation(
    {
      variables: {
        matchingEventId: id,
      },
      // prevent "unhandled promise rejection"
      // display errors via `error` object
      onError: () => null,
    },
  )

  const [
    unsubscribeForMatchingEvent,
    { loading: unsubscribeForMatchingEventLoading },
  ]: UnsubscribeForMatchingEventMutationHookResult = useUnsubscribeForMatchingEventMutation(
    {
      variables: {
        matchingEventId: id,
      },
      onCompleted: () => onClose(),
      // refetch list of matching events, because the event might be removed
      // from visible events after the user is removed from the event's participants
      refetchQueries: [{ query: MatchingEventsDocument }],
      // prevent "unhandled promise rejection"
      // display errors via `error` object
      onError: () => null,
    },
  )

  const startDate = new Date(matchingEvent?.startDate)
  const startDateFormatted = dateTimeFormat(
    startDate,
    DateFormat.Date,
    i18n.language,
  )
  const startTime = dateTimeFormat(startDate, DateFormat.Time, i18n.language)

  const endDate = new Date(matchingEvent?.endDate)
  const endTime = dateTimeFormat(endDate, DateFormat.Time, i18n.language)

  const subscriptionDeadlineIsPassed = isDatePassed(
    matchingEvent?.subscriptionDeadline,
  )
  const matchingRequestDeadlineIsPassed = isDatePassed(
    matchingEvent?.matchingRequestDeadline,
  )

  const titleId = `${id}__matching_event__title`
  const sessionListTitleId = `${id}__matching_event__sessions_title`
  const dateTimeLocationInfo: {
    date: string
    time: string
    location?: string
  } = {
    date: startDateFormatted,
    time: t('matchingEvent.startAndEndTime', { startTime, endTime }),
    ...(matchingEvent.city &&
      matchingEvent.streetAndNumber &&
      matchingEvent.zipCode && {
        location: `${matchingEvent.streetAndNumber}, ${matchingEvent.zipCode} ${matchingEvent.city}`,
      }),
  }

  const handleSubscribeForMatchingEvent = (): void => {
    user?.profile && !user?.profile.isProfileComplete
      ? toast({
          title: t('hint'),
          description: t('matchingEvent.notCompletedProfileHint'),
          status: 'warning',
          duration: null, // to make sure the user read the message
          isClosable: true,
        })
      : subscribeForMatchingEvent().finally(() => {
          btnRef.current?.focus()
        })
  }

  const handleUnsubscribeForMatchingEvent = (): void => {
    unsubscribeForMatchingEvent().finally(() => {
      btnRef.current?.focus()
    })
  }

  const subscriptionHandling: boolean =
    subscribeForMatchingEventLoading || unsubscribeForMatchingEventLoading

  const currentUserIsParticipant = !!matchingEvent?.currentUserParticipation
  const showSessionList =
    matchingEvent &&
    currentUserIsParticipant &&
    (matchingRequestDeadlineIsPassed || matchingEvent.showTemporarySchedule)

  return (
    <>
      <chakra.article
        css={contentColumnNormalCss}
        aria-labelledby={titleId}
        role={'main'}
        position={'relative'}
      >
        <Flex direction={'column-reverse'} justify={'center'} as={'header'}>
          <chakra.h1
            textStyle={'h2'}
            color={'custom.blueDark'}
            mt={'1.4rem'}
            id={titleId}
          >
            {matchingEvent?.name}
          </chakra.h1>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'left', md: 'center' }}
            position={'relative'}
          >
            {matchingEvent.isExclusive && (
              <chakra.div mr={'2rem'}>
                <Chip
                  label={t('common:matchingEvent.exclusive')}
                  variant={'gray'}
                />
              </chakra.div>
            )}
            <chakra.span textStyle={'h6'} color={'gray.500'}>
              {Object.values(dateTimeLocationInfo).join(' • ')}
            </chakra.span>
          </Flex>
        </Flex>

        {matchingEvent && (
          <RichText
            html={matchingEvent?.description}
            textStyle={'paragraph'}
            marginTop={'2.4rem'}
          />
        )}
        <ApolloErrorMessage error={error} mt={'3rem'} />
        {showSessionList && (
          <>
            <chakra.h2
              textStyle={'h3'}
              color={'custom.blueDark'}
              mt={'6.4rem'}
              mb={'2.4rem'}
              id={sessionListTitleId}
            >
              {matchingEvent.showTemporarySchedule
                ? t('common:schedule.temporarySchedule')
                : t('common:schedule.schedule')}
            </chakra.h2>
            {matchingEvent.showTemporarySchedule && (
              <chakra.p textStyle={'small'} color={'gray.500'} mb={'2.4rem'}>
                {t('schedule.temporaryScheduleInfo')}
              </chakra.p>
            )}
            <SessionList
              matchingEventId={id}
              aria-labelledby={sessionListTitleId}
            />
          </>
        )}
        <Stack spacing={'1.6rem'} mt={'4.8rem'}>
          {currentUserIsParticipant && (
            <div>
              <Button
                width={'100%'}
                maxWidth={{ md: '32.7rem' }}
                variant={'primary'}
                as={Link}
                to={routes.matchingEventParticipants(matchingEvent.slug)}
              >
                {t('matchingEvent.requestMeetings')}
              </Button>
              <chakra.p textStyle={'small'} color={'gray.500'} mt={'0.6'}>
                {t('matchingEvent.requestMeetingsInfoText')}
              </chakra.p>
            </div>
          )}
          <Button
            width={'100%'}
            maxWidth={{ md: '32.7rem' }}
            variant={
              matchingEvent?.currentUserParticipation ? 'secondary' : 'primary'
            }
            ref={btnRef}
            colorScheme={'red'}
            onClick={() =>
              currentUserIsParticipant
                ? onOpen()
                : handleSubscribeForMatchingEvent()
            }
            isDisabled={
              !currentUserIsParticipant && subscriptionDeadlineIsPassed
            }
            isLoading={subscriptionHandling}
          >
            {currentUserIsParticipant
              ? t('matchingEvent.doUnsubscribe')
              : t('matchingEvent.doSubscribe')}
          </Button>
        </Stack>
        {matchingEvent && !matchingRequestDeadlineIsPassed && (
          <DeadlineInfo
            matchingEvent={matchingEvent}
            marginTop={{ base: '2.4rem', md: '4.8rem' }}
          />
        )}
      </chakra.article>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalCloseButton />
        <ModalHeader>{t('matchingEvent.unsubscribeFromEvent')}</ModalHeader>
        <ModalBody>{t('matchingEvent.unsubscribeFromEventInfo')}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>{t('cancel')}</Button>
          <Button
            onClick={handleUnsubscribeForMatchingEvent}
            variant={'secondary'}
            colorScheme={'red'}
            isLoading={subscriptionHandling}
          >
            {t('matchingEvent.unsubscribeConfirm')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
