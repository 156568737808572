import { Button, Link, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useUser } from 'src/auth/hooks/useUser'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'src/common/components/Modal'

export type DeleteAccount = Record<string, never>

export const DeleteAccount = (): React.ReactElement => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { t } = useTranslation()
  const { deleteUser } = useUser()

  const handleDelete = () => {
    deleteUser()
  }

  return (
    <>
      <Link
        as={'button'}
        color={'red.500'}
        textDecoration={'underline'}
        onClick={onOpen}
      >
        {t('common:myProfile.deleteAccount')}
      </Link>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalHeader>{t('common:myProfile.deleteAccount')}</ModalHeader>
        <ModalBody>{t('common:myProfile.deleteAccountDialog')}</ModalBody>
        <ModalFooter direction={'vertical'}>
          <Button onClick={onClose} variant={'secondary'} colorScheme={'blue'}>
            {t('common:cancel')}
          </Button>
          <Button
            onClick={handleDelete}
            variant={'secondary'}
            colorScheme={'red'}
          >
            {t('common:myProfile.deleteAccount')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
