import { Box, BoxProps } from '@chakra-ui/react'
import DOMPurify from 'dompurify'
import React from 'react'

import { richTextCss } from './RichText.styles'

export interface RichText extends Omit<BoxProps, 'children'> {
  html: string
  textStyle?: string
}

export const RichText = React.forwardRef<HTMLDivElement, RichText>(
  ({ html, textStyle = 'paragraph', ...props }, ref): React.ReactElement => {
    // ADD_ATTR to allow target att in anchor tags
    const cleanHtml = DOMPurify.sanitize(html, { ADD_ATTR: ['target'] })

    DOMPurify.addHook('afterSanitizeElements', function (node) {
      if (!node.tagName) {
        return
      }

      if (node.tagName.toLowerCase() === 'font') {
        node.setAttribute('face', 'Segoe Semilight')
      }
    })

    return (
      <Box
        ref={ref}
        dangerouslySetInnerHTML={{ __html: cleanHtml }}
        css={richTextCss}
        textStyle={textStyle}
        {...props}
      />
    )
  },
)
