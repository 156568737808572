import { colors } from '../colors'

export const Textarea = {
  baseStyle: {
    display: 'block',
    // minHeight: 0,
    _hover: {
      background: colors.gray[50],
    },
    _invalid: {
      bg: colors.custom.errorFill,
      _focus: {
        bg: 'none',
      },
    },
    // disable auto fill
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
      transition: 'background-color 5000s ease-in-out 0s',
    },
  },
}
