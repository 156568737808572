import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Chip } from 'src/common/components/Chip'
import { MultiChipSelect } from 'src/common/components/MultiChipSelect'
import { TopicType, useTopicsQuery } from 'src/graphql/__generated__/types'

export type ItemType = Pick<TopicType, 'name' | 'id'>

const filterItems = (
  items: ItemType[],
  inputValue: string,
  selectedItems: ItemType[],
) => {
  return inputValue
    ? items
        // don't show selected items in auto-complete suggestions
        .filter(
          (item) =>
            !selectedItems.find(
              (selectedItem) => selectedItem.name === item.name,
            ),
        )
        // restrict auto-complete suggestions to 10 elements
        .slice(0, 10)
    : []
}

const itemToString = (item: ItemType | null) => item?.name || ''

export type TopicMultiSelect = {
  label: string
  isReadonly?: boolean
  selectedItems: ItemType[]
  setSelectedItems: Dispatch<SetStateAction<ItemType[]>>
  error?: string | undefined
  chipVariant?: Chip['variant']
  isRequired: boolean
}

export const TopicMultiSelect = ({
  label,
  isReadonly,
  selectedItems,
  setSelectedItems,
  error,
  chipVariant,
  isRequired,
}: TopicMultiSelect): React.ReactElement => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const {
    data: topicsData,
    previousData: previousTopicsData,
    loading: topicsLoading,
  } = useTopicsQuery({
    variables: { search: inputValue },
    skip: !inputValue,
  })

  const items =
    topicsData?.topics || (inputValue && previousTopicsData?.topics) || []

  const removeSelectedItem = (item: ItemType) => {
    setSelectedItems((prev) =>
      prev.filter((selectedItem) => selectedItem.name != item.name),
    )
  }

  const addSelectedItem = (item: ItemType) => {
    if (
      !selectedItems.find((selectedItem) => item.name === selectedItem.name)
    ) {
      setSelectedItems((prev) => [...prev, item])
    }
  }

  const noSelectedItemsLabel = t('common:myProfile.noInput')

  return (
    <MultiChipSelect
      inputLabel={label}
      inputValue={inputValue}
      setInputValue={setInputValue}
      items={items}
      addSelectedItem={addSelectedItem}
      removeSelectedItem={removeSelectedItem}
      selectedItems={selectedItems}
      filterItems={filterItems}
      itemToString={itemToString}
      isReadonly={isReadonly}
      isLoading={topicsLoading}
      error={error}
      noSelectedItemsLabel={noSelectedItemsLabel}
      chipVariant={chipVariant}
      isRequired={isRequired}
    />
  )
}
