import { ModalBodyProps, ModalBody as ChakraModalBody } from '@chakra-ui/react'
import { useState, useRef, useLayoutEffect, useEffect } from 'react'

import {
  topShadow,
  bottomShadow,
  topAndBottomShadow,
  bodyCss,
} from './ModalBody.styles'

export type ModalBody = ModalBodyProps

interface ScrollState {
  topReached: boolean
  bottomReached: boolean
}

const getScrollPosition = (el: HTMLElement): ScrollState => {
  return {
    // top is reached if scroll position is 0
    topReached: el.scrollTop === 0,
    // bottom is reached if the total height equals the scroll position + the height of the currently visible area
    bottomReached: el.scrollHeight === el.scrollTop + el.offsetHeight,
  }
}

/**
 * Modal body that displays a shadow at the top or bottom depending on the scroll position.
 * - top shadow: displayed if th scroll position is not at the top
 * - bottom shadow: displayed if the scroll position is not at the bottom
 *
 * This is implemented with performance in mind.
 * Please checkout the following post to learn more about the used technique
 * https://dev.to/n8tb1t/tracking-scroll-position-with-react-hooks-3bbj
 * */
export const ModalBody = (props: ModalBody): React.ReactElement => {
  const bodyRef = useRef<HTMLDivElement>(null)

  const [scrollState, setScrollState] = useState<ScrollState>({
    topReached: true,
    bottomReached: false,
  })

  let throttleTimeout: number | null = null

  const updateScrollState = () => {
    if (bodyRef.current) {
      const newState = getScrollPosition(bodyRef.current)
      if (
        scrollState.topReached !== newState.topReached ||
        scrollState.bottomReached !== newState.bottomReached
      ) {
        throttleTimeout = null
        setScrollState(newState)
      }
    }
  }

  useLayoutEffect(() => {
    // register throttled event handler
    const scrollContainer = bodyRef.current
    if (scrollContainer) {
      const handleScrollEvent = () => {
        if (throttleTimeout === null) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          throttleTimeout = window.setTimeout(updateScrollState, 300)
        }
      }
      scrollContainer.addEventListener('scroll', handleScrollEvent)
      return () =>
        scrollContainer?.removeEventListener('scroll', handleScrollEvent)
    }
  }, [scrollState])

  useEffect(() => {
    // set initial state
    if (bodyRef.current) {
      setScrollState(getScrollPosition(bodyRef.current))
    }
  }, [bodyRef])

  return (
    <ChakraModalBody
      css={bodyCss}
      style={{
        boxShadow:
          !scrollState.topReached && !scrollState.bottomReached
            ? topAndBottomShadow
            : !scrollState.bottomReached
            ? topShadow
            : !scrollState.topReached
            ? bottomShadow
            : undefined,
      }}
      ref={bodyRef}
      {...props}
    />
  )
}
