import { css } from '@emotion/react'

import { media } from 'src/theme/breakpoints'

export const chipListCss = css`
  display: flex;
  flex-wrap: wrap;

  margin: -1rem 0 0 -1rem;

  > * {
    margin: 1rem 0 0 1rem;
  }

  ${media.md} {
    margin: -1.5rem 0 0 -1.5rem;

    > * {
      margin: 1.5rem 0 0 1.5rem;
    }
  }
`
