import { chakra } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUser } from 'src/auth/hooks/useUser'
import {
  contentColumnNormalCss,
  defaultPageCss,
  defaultSectionCss,
} from 'src/theme/layout'
import { ImageUpload } from 'src/userprofile/components/UploadProfileImage'

import { DeleteAccount } from './DeleteAccount'
import { MyProfileForm } from './MyProfileForm'
import { MyProfilePageSkeleton } from './MyProfilePageSkeleton'
import { MyTopicsForm } from './MyTopicsForm'

export type MyProfilePage = Record<string, never>

export const MyProfilePage = (): React.ReactElement => {
  const { t } = useTranslation()

  const { user, loading } = useUser()
  if (loading || !user) {
    return <MyProfilePageSkeleton />
  }

  const titleId = 'my_profile_page__title'

  return (
    <chakra.article
      css={[defaultSectionCss, defaultPageCss]}
      role={'main'}
      aria-labelledby={titleId}
    >
      <chakra.header css={contentColumnNormalCss}>
        <chakra.h1 textStyle={'h1'} id={titleId}>
          {t('common:myProfile.profile')}
        </chakra.h1>
      </chakra.header>
      <chakra.div
        css={contentColumnNormalCss}
        marginTop={{ base: '4rem', lg: '4.8rem' }}
      >
        <ImageUpload user={user} />
      </chakra.div>
      <chakra.div
        css={contentColumnNormalCss}
        layerStyle={'elevated'}
        padding={{ base: '2.4rem', lg: '4.8rem' }}
        marginTop={{ base: '4rem', lg: '4.8rem' }}
      >
        <MyProfileForm user={user} />
      </chakra.div>
      <chakra.div
        css={contentColumnNormalCss}
        layerStyle={'elevated'}
        padding={{ base: '2.4rem', lg: '4.8rem' }}
        marginTop={{ base: '4rem', lg: '6.4rem' }}
      >
        <MyTopicsForm />
      </chakra.div>
      <chakra.div css={contentColumnNormalCss} marginTop={'2.4rem'}>
        <DeleteAccount />
      </chakra.div>
    </chakra.article>
  )
}
