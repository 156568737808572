import { colors } from '../colors'
import { fontSizes, fontWeights } from '../typography'

export const Modal = {
  baseStyle: {
    dialogContainer: {
      alignItems: { base: 'flex-end', md: 'center' },
    },
    dialog: {
      borderRadius: { base: '1rem 1rem 0 0', md: '1rem' },
      my: '0',
      maxHeight: { base: '100%', md: '60%' },
    },
    header: {
      fontSize: { base: fontSizes.md },
      fontWeight: fontWeights.semiBold,
      p: {
        base: '4.8rem 2.4rem 0 2.4rem',
        md: '4.8rem 4.8rem 0 4.8rem',
      },
      color: colors.custom.blueDark,
    },
    body: {
      px: { base: '2.4rem', md: '4.8rem' },
      py: { base: '1.6rem', md: '1.4rem' },
    },
    footer: {
      p: {
        base: '2.4rem 2.4rem 4.4rem',
        md: '2rem 4.8rem 4.8rem',
      },
    },
  },
}
