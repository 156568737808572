import { chakra } from '@chakra-ui/react'
import { useRef } from 'react'

import { fullHeightCss } from 'src/common/utils/style.utils'

import { Footer } from './Footer'
import { Header } from './Header'
import { MainAnchor, SkipMenuButton } from './SkipMenuButton'

export interface Layout {
  children?: React.ReactNode
}

export const Layout = ({ children }: Layout): React.ReactElement => {
  const mainAnchorRef = useRef<HTMLAnchorElement>(null)
  return (
    <chakra.div minHeight={'100vh'} display={'flex'} flexDirection={'column'}>
      <SkipMenuButton targetRef={mainAnchorRef} />
      <Header />
      <chakra.main
        css={fullHeightCss}
        flexDirection={'column'}
        alignItems={'strech'}
      >
        <MainAnchor ref={mainAnchorRef} />
        {children}
      </chakra.main>
      <Footer />
    </chakra.div>
  )
}
