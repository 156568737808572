import { chakra, BoxProps, Image } from '@chakra-ui/react'
import { BiUser } from 'react-icons/bi'

import { Maybe, UserProfileType } from 'src/graphql/__generated__/types'

type UserAvatarFallback = {
  boxSize: string
  displayname?: string
}
const UserAvatarFallback = ({
  boxSize,
  displayname,
}: UserAvatarFallback): React.ReactElement => {
  return displayname ? (
    <chakra.span fontSize={`calc(${boxSize} / 2)`} fontWeight={'semiBold'}>
      {displayname}
    </chakra.span>
  ) : (
    <BiUser fontSize={`calc(${boxSize} / 1.5)`} />
  )
}
const getInitials = (
  firstName: string,
  lastName: string,
): string | undefined => {
  if (!firstName && !lastName) return undefined

  const initials =
    firstName && lastName
      ? firstName?.substring(0, 1) + lastName?.substring(0, 1)
      : firstName?.substring(0, 1)

  return initials?.toUpperCase()
}

export interface UserAvatar extends BoxProps {
  boxSize?: string
  userProfile?: Maybe<
    Pick<UserProfileType, 'firstName' | 'lastName' | 'profileImage'>
  >
}
export const UserAvatar = ({
  boxSize = '3.6rem',
  color = 'white',
  bg = 'custom.blueLight',
  userProfile,
  ...props
}: UserAvatar): React.ReactElement => {
  const displayLetters = userProfile
    ? getInitials(userProfile?.firstName, userProfile?.lastName)
    : ''

  return (
    <chakra.div
      aria-hidden
      display={'flex'}
      alignItems={'center'}
      borderRadius={'full'}
      justifyContent={'center'}
      bg={bg}
      boxSize={boxSize}
      color={color}
      {...props}
    >
      {userProfile?.profileImage ? (
        <Image
          borderRadius={'full'}
          boxSize={boxSize}
          objectFit="cover"
          src={userProfile?.profileImage}
          alt="Profile image"
          fallback={
            <UserAvatarFallback
              displayname={displayLetters}
              boxSize={boxSize}
            />
          }
        />
      ) : (
        <UserAvatarFallback displayname={displayLetters} boxSize={boxSize} />
      )}
    </chakra.div>
  )
}
