/**
 * Alert styles are also used for toasts
 * */
export const Alert = {
  baseStyle: {
    container: {
      button: {
        fontSize: '1.4rem',
        width: '3.2rem',
        height: '3.2rem',
      },
    },
    icon: {},
    title: {
      lineHeight: '1.5em',
      marginRight: '1rem',
    },
    description: {
      flexGrow: '1',
      fontSize: '1.4rem',
      lineHeight: '172%',
      marginRight: '1rem',
    },
  },
}
