export enum DateFormat {
  DateNumericWithYear = 'DATE_NUMERIC_WITH_YEAR',
  DateWithYear = 'DATE_WITH_YEAR',
  Date = 'DATE',
  Time = 'TIME',
}

export const dateTimeFormat = (
  date: Date,
  format: DateFormat,
  locale: string,
): string => {
  switch (format) {
    case DateFormat.Date:
      return date.toLocaleDateString(locale, {
        day: '2-digit',
        month: 'long',
      })
    case DateFormat.DateNumericWithYear:
      return date.toLocaleDateString(locale, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    case DateFormat.DateWithYear:
      return date.toLocaleDateString(locale, {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      })
    case DateFormat.Time:
      // #54 - String replacement is not ideal but
      // https://newbedev.com/displaying-am-and-pm-in-lower-case-after-date-formatting
      // states: "Unfortunately out of the box the AM and PM do not seem to be customisable
      // in the standard SimpleDateFormat class"
      return date
        .toLocaleTimeString(locale, {
          hour: '2-digit',
          minute: '2-digit',
        })
        .replace('AM', 'a.m.')
        .replace('PM', 'p.m.')
  }
}

export const isDatePassed = (date: string): boolean =>
  new Date(date) < new Date()
