import { chakra } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Layout } from 'src/common/components/Layout'
import { colors } from 'src/theme/colors'
import { contentColumnFullCss, defaultSectionCss } from 'src/theme/layout'

import { ErrorBoundary } from './ErrorBoundary'

export interface MainFallback {
  title?: string
}

const styles = {
  wrapper: {
    display: 'flex',
    aligntems: 'center',
    justifyContent: 'center',
    paddingTop: '4.5rem',
  },
  errorText: {
    color: colors.custom.error,
    fontSize: '4vw',
    fontWeight: 700,
  },
}
export const MainFallback = ({ title }: MainFallback): React.ReactElement => {
  const { t } = useTranslation()

  const criticalFallback = (
    <div style={styles.wrapper}>
      <p style={styles.errorText}>
        {title ?? 'An unknown error has occurred!'}
      </p>
    </div>
  )

  return (
    <ErrorBoundary fallback={criticalFallback}>
      <Layout>
        <chakra.div css={defaultSectionCss}>
          <chakra.div css={contentColumnFullCss}>
            <div style={styles.wrapper}>
              <chakra.span textStyle={'h1'} color={'custom.error'}>
                {title ?? t('common:errors.unknown')}
              </chakra.span>
            </div>
          </chakra.div>
        </chakra.div>
      </Layout>
    </ErrorBoundary>
  )
}
