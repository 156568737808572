import { chakra, Button, StyleProps } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { textStyles } from 'src/theme/typography'
import { defaultFocusCss } from 'src/theme/utils'

const getText = (text: string, characterLimit: number, showMore: boolean) => {
  let result = text
  if (showMore) return result
  else if (text) {
    const teaserWordsArray = text.split(' ')

    while (teaserWordsArray.join(' ').length > characterLimit) {
      teaserWordsArray.pop()
    }

    result = teaserWordsArray.join(' ')
  }
  return result
}

export interface ReadMore extends StyleProps {
  text: string
  characterLimit?: number
  textStyle?: keyof typeof textStyles
}
export const ReadMore = ({
  text,
  characterLimit = 140,
  textStyle = 'text',
}: ReadMore): React.ReactElement => {
  const [showMore, setShowMore] = useState(false)
  const textRef = useRef<HTMLSpanElement>(null)
  const [disable] = useState(text.length <= characterLimit)
  const { t } = useTranslation()

  const [displayedText, setDisplayedText] = useState(
    getText(text, characterLimit, showMore),
  )

  const toggleState = () => {
    !showMore && textRef.current?.focus()
    setShowMore(!showMore)
  }

  useEffect(() => {
    setDisplayedText(getText(text, characterLimit, showMore))
  }, [showMore, characterLimit, text])

  return (
    <>
      <chakra.span
        css={defaultFocusCss}
        textStyle={textStyle}
        ref={textRef}
        tabIndex={-1}
      >
        {displayedText}
      </chakra.span>{' '}
      {!!text && !disable && (
        <Button textStyle={textStyle} variant={'link'} onClick={toggleState}>
          {showMore ? t('showLess') : `... ${t('showMore')}`}
        </Button>
      )}
    </>
  )
}
