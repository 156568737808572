import {
  Flex,
  Stack,
  Button,
  useDisclosure,
  Link,
  FlexProps,
  chakra,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  Drawer,
  DrawerHeader,
  DrawerCloseButton,
  useMediaQuery,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { GiHamburgerMenu as HamburgerIcon } from 'react-icons/gi'
import { NavLink, Link as RouterLink } from 'react-router-dom'

import { useUser } from 'src/auth/hooks/useUser'
import { Logo } from 'src/common/components/Logo'
import { HEADER_HEIGHT } from 'src/common/constants'
import { routes } from 'src/common/routes'
import { visuallyHiddenCss } from 'src/common/utils/style.utils'
import { theme } from 'src/theme'
import { breakpointsEm } from 'src/theme/breakpoints'
import { maxWidthCss, paddedSectionCss } from 'src/theme/layout'
import { defaultFocusCss } from 'src/theme/utils'

import { navLinkCss, wrapperCss } from './Header.styles'
import { LanguageMenu } from './LanguageMenu'
import { UserMenu } from './UserMenu'
import { NAV_ITEMS } from './constants'

type MenuToggle = {
  toggle: () => void
}
const MenuToggle = ({ toggle }: MenuToggle): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <chakra.button
      display={{ base: 'block', md: 'none' }}
      onClick={toggle}
      css={defaultFocusCss}
      justifySelf={'start'}
    >
      <chakra.span css={visuallyHiddenCss}>
        {t('common:mainNavigation.open')}
      </chakra.span>
      <HamburgerIcon
        color={'custom.blueDark'}
        fontSize={'2.4rem'}
        aria-hidden
      />
    </chakra.button>
  )
}

interface NavItems {
  textStyle?: string
  spacing?: string
  onClose?: () => void
}

const NavItems = ({
  textStyle = 'h5',
  spacing = '2.4rem',
  onClose,
}: NavItems): React.ReactElement => {
  const { t } = useTranslation()

  const onClick = () => {
    onClose && onClose()
  }

  return (
    <Stack
      as={'ul'}
      spacing={spacing}
      align={'center'}
      justify={['center', 'space-between', 'flex-end']}
      direction={{ base: 'column', md: 'row' }}
    >
      {NAV_ITEMS(t).map((item, index) => (
        <chakra.li key={index}>
          <chakra.a
            as={NavLink}
            exact
            activeClassName={'active'}
            to={item.link}
            textStyle={textStyle}
            css={navLinkCss}
            onClick={onClick}
          >
            {item.title}
          </chakra.a>
        </chakra.li>
      ))}
    </Stack>
  )
}
const Menu = (): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <chakra.nav
      display={{ base: 'none', md: 'block' }}
      aria-label={t('common:mainNavigation.label')}
    >
      <NavItems />
    </chakra.nav>
  )
}

type AccountControls = FlexProps & {
  isOpen: boolean
}
const AccountControls = ({
  isOpen,
  ...flexProps
}: AccountControls): React.ReactElement => {
  const { t } = useTranslation()
  const { loggedIn } = useUser()

  return (
    <Flex
      justify={'flex-end'}
      flexBasis={{ base: isOpen ? '100%' : '0', md: '0' }}
      // max width is "parent width" - "burger menu width"
      maxWidth={'calc(100% - 2.4rem)'}
      {...flexProps}
    >
      {!loggedIn ? (
        <>
          <Button variant={'ghost'} as={RouterLink} to={routes.login}>
            {t('common:login')}
          </Button>
          <Button ml={'2rem'} as={RouterLink} to={routes.register}>
            {t('common:register')}
          </Button>
        </>
      ) : (
        <UserMenu />
      )}
      <LanguageMenu />
    </Flex>
  )
}

const NavDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation()
  return (
    <Drawer isOpen={isOpen} placement={'top'} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxHeight={'100vh'} paddingTop={'3.6rem'}>
        <DrawerCloseButton
          left={3}
          top={4}
          right={'auto'}
          aria-label={t('common:mainNavigation.close')}
        />
        <DrawerHeader>
          <chakra.div textStyle={'h6'} textAlign={'center'} color={'gray.500'}>
            {t('common:mainNavigation.menu')}
          </chakra.div>
        </DrawerHeader>
        <DrawerBody pt={'3.2rem'} pb={'6.4rem'}>
          <chakra.nav aria-label={t('common:mainNavigation.label')}>
            <NavItems textStyle={'h4'} spacing={'1.6rem'} onClose={onClose} />
          </chakra.nav>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const Header = (): React.ReactElement => {
  const { isOpen, onClose, onToggle } = useDisclosure()

  const [isTablet] = useMediaQuery(`(min-width: ${breakpointsEm.md})`)

  useEffect(() => {
    if (isTablet && isOpen) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet])

  return (
    <chakra.header
      top={0}
      position={'sticky'}
      h={HEADER_HEIGHT}
      bg={'gray.50'}
      zIndex={theme.zIndices.header}
    >
      <chakra.div
        css={[paddedSectionCss, maxWidthCss, wrapperCss]}
        h={'inherit'}
      >
        <Link
          as={RouterLink}
          to={'/'}
          display={{ base: 'none', md: 'flex' }}
          justifySelf={'flex-start'}
        >
          <Logo />
        </Link>
        <MenuToggle toggle={onToggle} />
        <Menu />
        <AccountControls isOpen={isOpen} />
      </chakra.div>
      <NavDrawer isOpen={isOpen} onClose={onClose} />
    </chakra.header>
  )
}
