import { ApolloError } from '@apollo/client'
import { FetchResult } from '@apollo/client/link/core'
import {
  Button,
  chakra,
  Skeleton,
  useToast,
  Select,
  Stack,
  FormLabel,
  FormControl,
} from '@chakra-ui/react'
import { ClassNames } from '@emotion/react'
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { FaBuffer, FaFilter } from 'react-icons/fa'
import Masonry from 'react-masonry-css'
import { useParams } from 'react-router'
import { Link, Redirect } from 'react-router-dom'

import { User } from 'src/auth/types'
import { ApolloErrorMessage } from 'src/common/components/Error'
import { routes } from 'src/common/routes'
import { visuallyHiddenCss } from 'src/common/utils/style.utils'
import { mapToNodes } from 'src/common/utils/typescript'
import {
  CancelMatchingRequestMutationHookResult,
  MatchingEventParticipantsDocument,
  RejectMatchingRequestMutationHookResult,
  SendMatchingRequestMutationHookResult,
  useCancelMatchingRequestMutation,
  useMatchingEventParticipantsQuery,
  useRejectMatchingRequestMutation,
  useSendMatchingRequestMutation,
} from 'src/graphql/__generated__/types'
import {
  ItemType,
  TopicMultiSelect,
} from 'src/matching/components/TopicMultiSelect'
import { ParticipantListItem } from 'src/matchingevent/components/ParticipantListItem'
import {
  contentColumnNormalCss,
  defaultPageCss,
  defaultSectionCss,
} from 'src/theme/layout'
import { defaultFocusCss } from 'src/theme/utils'

const breakpointColumnsObj = {
  default: 2,
  1024: 1,
}

interface MatchingEventDetailsPageParams {
  slug: string
}

const FILTER_SHOW_ALL = 'alle'
const FILTER_BY_HAS_REQUEST_TO_CURRENT_USER = 'hasRequestToCurrentUser'
const FILTER_BY_HAS_REQUEST_FROM_CURRENT_USER = 'hasRequestFromCurrentUser'

export const ParticipantListPage = (): React.ReactElement => {
  const { t } = useTranslation()
  const [filterBy, setFilterBy] = useState<string>(FILTER_SHOW_ALL)
  const [groupFilter, setGroupFilter] = useState<string>(FILTER_SHOW_ALL)
  const [users, setUsers] = useState<User[]>([])
  const { slug } = useParams<MatchingEventDetailsPageParams>()
  const matchingEventDetailsUrl = routes.matchingEventDetails(slug)
  const toast = useToast()
  const resultsRef = useRef<HTMLDivElement>(null)
  const [selectedDemandedTopics, setSelectedDemandedTopics] = useState<
    ItemType[]
  >([])

  const selectedDemandedTopicsIds = useMemo(() => {
    // we sort the IDs to improve apollo caching
    return selectedDemandedTopics.map((topic) => topic.id).sort()
  }, [selectedDemandedTopics])

  const { data, loading, previousData } = useMatchingEventParticipantsQuery({
    variables: {
      slug,
      matchingTopics: selectedDemandedTopicsIds,
      hasRequestFromCurrentUser:
        filterBy !== FILTER_SHOW_ALL &&
        filterBy === FILTER_BY_HAS_REQUEST_FROM_CURRENT_USER,
      hasRequestToCurrentUser:
        filterBy !== FILTER_SHOW_ALL &&
        filterBy === FILTER_BY_HAS_REQUEST_TO_CURRENT_USER,
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setUsers(
      groupFilter === FILTER_SHOW_ALL
        ? mapToNodes(
            data?.matchingEvent?.users || previousData?.matchingEvent?.users,
          )
        : mapToNodes(
            data?.matchingEvent?.users || previousData?.matchingEvent?.users,
          ).filter((user) => user.matchingEventGroupTag === groupFilter),
    )
  }, [groupFilter, data, previousData])

  const sendErrorToast = (apolloError: ApolloError) => {
    // ignore network errors --> displayed by ApolloErrorMessage
    if (apolloError.graphQLErrors) {
      apolloError.graphQLErrors.forEach((error) => {
        toast({
          title: t('common:error'),
          description: t([
            `common:apiErrors.${error.message}`,
            'common:errors.unknown',
          ]),
          status: 'error',
          duration: null, // to make sure the user read the message
          isClosable: true,
        })
      })
    }
  }

  const [
    sendMatchingRequest,
    { loading: sendMatchingRequestLoading, error: sendMatchingRequestError },
  ]: SendMatchingRequestMutationHookResult = useSendMatchingRequestMutation({
    onError: sendErrorToast,
  })

  const [
    cancelMatchingRequest,
    {
      loading: cancelMatchingRequestLoading,
      error: cancelMatchingRequestError,
    },
  ]: CancelMatchingRequestMutationHookResult = useCancelMatchingRequestMutation(
    { onError: sendErrorToast },
  )

  const [
    rejectMatchingRequest,
    {
      loading: rejectMatchingRequestLoading,
      error: rejectMatchingRequestError,
    },
  ]: RejectMatchingRequestMutationHookResult = useRejectMatchingRequestMutation(
    {
      onError: sendErrorToast,
      refetchQueries: [
        {
          query: MatchingEventParticipantsDocument,
          variables: {
            slug,
            matchingTopics: selectedDemandedTopicsIds,
            hasRequestFromCurrentUser:
              filterBy !== FILTER_SHOW_ALL &&
              filterBy === FILTER_BY_HAS_REQUEST_FROM_CURRENT_USER,
            hasRequestToCurrentUser:
              filterBy !== FILTER_SHOW_ALL &&
              filterBy === FILTER_BY_HAS_REQUEST_TO_CURRENT_USER,
          },
        },
      ],
    },
  )

  const handleSendMatchingRequest = (
    participantId: string,
  ): Promise<void | FetchResult> => {
    return sendMatchingRequest({
      variables: { toUserId: participantId, matchingEventSlug: slug },
    })
  }

  const handleCancelMatchingRequest = (
    participantId: string,
  ): Promise<void | FetchResult> => {
    return cancelMatchingRequest({
      variables: { toUserId: participantId, matchingEventSlug: slug },
    })
  }

  const handleRejectMatchingRequest = (
    participantId: string,
  ): Promise<void | FetchResult> => {
    return rejectMatchingRequest({
      variables: { fromUserId: participantId, matchingEventSlug: slug },
    })
  }

  const handleFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target?.value
    setFilterBy(value)
  }

  const handleGroupFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target?.value
    setGroupFilter(value)
  }

  const isCurrentUserAParticipant = !!data?.matchingEvent
    ?.currentUserParticipation

  if (!loading && !isCurrentUserAParticipant) {
    return <Redirect to={matchingEventDetailsUrl} />
  }

  const isMatchingRequestActionActive = data?.matchingEvent
    ?.allowOverlappingDates
    ? new Date(data?.matchingEvent?.matchingRequestDeadline) > new Date()
    : new Date(data?.matchingEvent?.matchingRequestDeadline) > new Date() &&
      new Date() > new Date(data?.matchingEvent?.subscriptionDeadline)

  const groupTags = data?.matchingEvent?.groupTags || []

  const isRequestRejectionAllowed =
    data?.matchingEvent?.isRequestRejectionAllowed || false

  const isListFiltered = selectedDemandedTopics.length > 0

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    resultsRef.current?.focus()
  }

  const resetFilter = () => {
    setSelectedDemandedTopics([])
    setFilterBy(FILTER_SHOW_ALL)
    resultsRef.current?.focus()
  }

  const hasMatchingRequest = (id: string): boolean => {
    const list = data?.matchingEvent?.currentUserMatchingRequests ?? []
    return list.findIndex((mr) => mr.toUser.id === id) !== -1
  }

  const isRequestedForMatch = (id: string): boolean => {
    const list = data?.matchingEvent?.currentUserRequestedMatches ?? []
    return (
      list.findIndex(
        (mr) => mr.fromUser.id === id && mr.isRejected !== true,
      ) !== -1
    )
  }

  const isMatchRejected = (id: string): boolean => {
    const list = data?.matchingEvent?.currentUserMatchingRequests ?? []
    const index = list.findIndex((mr) => mr.toUser.id === id)
    return index !== -1 ? list[index].isRejected : false
  }

  return (
    <chakra.div css={[defaultPageCss, defaultSectionCss]}>
      <chakra.h1 css={visuallyHiddenCss}>
        {t('common:matchingEvent.participants')}
      </chakra.h1>
      <chakra.div css={contentColumnNormalCss}>
        <Button
          as={Link}
          variant={'secondary'}
          to={matchingEventDetailsUrl}
          width={{ base: '100%', md: 'auto' }}
        >
          <chakra.span display={{ base: 'none', md: 'inline' }}>
            {t('common:matchingEvent.backToMatchingEventDetails')}
          </chakra.span>
          <chakra.span display={{ md: 'none' }}>{t('common:back')}</chakra.span>
        </Button>
      </chakra.div>
      <chakra.form
        css={contentColumnNormalCss}
        mt={{ base: '2.4rem', md: '4rem' }}
        onSubmit={onSubmit}
      >
        <Stack
          justifyContent={'center'}
          alignItems={'baseline'}
          direction={{ base: 'column', md: 'row' }}
          wrap={'revert'}
        >
          <TopicMultiSelect
            label={t('common:myProfile.demandedTopics')}
            selectedItems={selectedDemandedTopics}
            setSelectedItems={setSelectedDemandedTopics}
            chipVariant={'outline'}
            isRequired={false}
          />
          <FormControl>
            <FormLabel> {t('common:participantList.filter.label')}</FormLabel>
            <Select
              onChange={handleFilterChange}
              value={filterBy}
              icon={<FaFilter />}
              mb={'1.2rem'}
            >
              <option value={FILTER_BY_HAS_REQUEST_FROM_CURRENT_USER}>
                {t('common:participantList.filter.requestFromUser')}
              </option>
              <option value={FILTER_BY_HAS_REQUEST_TO_CURRENT_USER}>
                {t('common:participantList.filter.requestToUser')}
              </option>
              <option value={FILTER_SHOW_ALL}>
                {t('common:participantList.filter.all')}
              </option>
            </Select>
          </FormControl>
          {!!groupTags.length && (
            <FormControl>
              <FormLabel>
                {' '}
                {t('common:participantList.filter.groupLabel')}
              </FormLabel>
              <Select
                onChange={handleGroupFilterChange}
                value={groupFilter}
                icon={<FaBuffer />}
                mb={'1.2rem'}
              >
                {groupTags.map((groupTag) => (
                  <option key={groupTag} value={groupTag}>
                    {groupTag}
                  </option>
                ))}
                <option value={FILTER_SHOW_ALL}>
                  {t('common:participantList.filter.all')}
                </option>
              </Select>
            </FormControl>
          )}
        </Stack>
        <Button
          type={'submit'}
          variant={'secondary'}
          css={visuallyHiddenCss}
          transition={'none'}
          mt={'1rem'}
        >
          {t('common:matchingEvent.searchParticipants')}
        </Button>
      </chakra.form>

      <chakra.div
        ref={resultsRef}
        tabIndex={-1}
        mt={{ base: '2.4rem', md: '4.8rem' }}
        css={[contentColumnNormalCss, defaultFocusCss]}
      >
        <ApolloErrorMessage
          error={
            sendMatchingRequestError ||
            cancelMatchingRequestError ||
            rejectMatchingRequestError
          }
          mb={'2.4rem'}
        />
        {isListFiltered && (
          <chakra.div mb={'2.4rem'} css={contentColumnNormalCss}>
            {loading || users.length > 0 ? (
              <chakra.p textStyle={'h4'} color={'gray.400'}>
                {t('common:matchingEvent.matchingResults')}
              </chakra.p>
            ) : (
              <>
                <chakra.p textStyle={'h4'} color={'gray.400'}>
                  {t('common:noResultsFound')}
                </chakra.p>
                <Button mt={'1rem'} variant={'link'} onClick={resetFilter}>
                  {t('common:resetFilter')}
                </Button>
              </>
            )}
          </chakra.div>
        )}
        <ClassNames>
          {({ css }) => (
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className={css`
                display: flex;
                width: auto;
              `}
              columnClassName={css`
                background-clip: padding-box;

                :nth-of-type(2) {
                  padding-left: 2rem;
                }

                > *:not(:first-of-type) {
                  margin-top: 2rem;
                }
              `}
            >
              {loading
                ? Array(4)
                    .fill(null)
                    .map((_, indx) => (
                      <Skeleton key={indx} height={'63rem'} width={'100%'} />
                    ))
                : users.map((user) => (
                    <ParticipantListItem
                      hasMatchingRequest={hasMatchingRequest(user.id)}
                      isMatchingRequestActionActive={
                        isMatchingRequestActionActive
                      }
                      isRejected={isMatchRejected(user.id)}
                      isRequestRejectionAllowed={isRequestRejectionAllowed}
                      isRequestedForMatch={isRequestedForMatch(user.id)}
                      key={user.id}
                      loading={
                        rejectMatchingRequestLoading ||
                        cancelMatchingRequestLoading ||
                        sendMatchingRequestLoading
                      }
                      onCancel={handleCancelMatchingRequest}
                      onReject={handleRejectMatchingRequest}
                      onRequest={handleSendMatchingRequest}
                      user={user}
                    />
                  ))}
            </Masonry>
          )}
        </ClassNames>
      </chakra.div>
    </chakra.div>
  )
}
