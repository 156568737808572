import { chakra, Stack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useUser } from 'src/auth/hooks/useUser'
import { ApolloErrorMessage } from 'src/common/components/Error'
import { useMatchingEventsQuery } from 'src/graphql/__generated__/types'
import {
  MatchingEventListItem,
  MatchingEventListItemSkeleton,
} from 'src/matchingevent/components/MatchingEventListItem/'
import {
  contentColumnNormalCss,
  defaultPageCss,
  defaultSectionCss,
} from 'src/theme/layout'

export const MatchingEventListPage = (): React.ReactElement => {
  const { t } = useTranslation()
  const { loading: loadingUser } = useUser()
  const { data, loading, error, previousData } = useMatchingEventsQuery({
    skip: loadingUser,
    fetchPolicy: 'cache-and-network', // populate 'previousData' + refetch every render
  })

  const matchingEvents = data?.matchingEvents || previousData?.matchingEvents

  return (
    <chakra.div css={[defaultPageCss, defaultSectionCss]}>
      <chakra.div css={contentColumnNormalCss}>
        <chakra.h1 textStyle={'h1'}>
          {t('common:matchingEvent.events')}
        </chakra.h1>
        <ApolloErrorMessage error={error} mt={'2.4rem'} />
        <Stack as={'ul'} spacing={'4rem'} mt={'4rem'}>
          {!matchingEvents && loading ? (
            <>
              <MatchingEventListItemSkeleton />
              <MatchingEventListItemSkeleton />
            </>
          ) : (
            matchingEvents?.map((matchingEvent) => (
              <MatchingEventListItem
                key={matchingEvent.id}
                event={matchingEvent}
              />
            ))
          )}
        </Stack>
      </chakra.div>
    </chakra.div>
  )
}
