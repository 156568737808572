import { css } from '@emotion/react'

import { colors } from './colors'

export const defaultFocusCss = css`
  box-shadow: 0 0 0 0 ${colors.focus};
  &:focus {
    outline: none;
  }
  &.focus-visible {
    box-shadow: 0 0 0 0.3rem ${colors.focus};
  }
`

export const defaultTransitionCss = css`
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
`
