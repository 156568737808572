import { ThemeOverride } from '@chakra-ui/react'

import { Alert } from './alert'
import { Badge } from './badge'
import { Button } from './button'
import { Checkbox } from './checkbox'
import { FormError, FormLabel } from './form'
import { Input } from './input'
import { Link } from './link'
import { Modal } from './modal'
import { Select } from './select'
import { Textarea } from './textarea'

export const components: ThemeOverride['components'] = {
  Alert,
  Badge,
  Button,
  Checkbox,
  FormError,
  FormLabel,
  Input,
  Link,
  Modal,
  Textarea,
  Select,
}
