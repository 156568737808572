import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'

import { EmailSubmissionConfirmation } from 'src/auth/components/EmailSubmissionConfirmation'
import { useUser } from 'src/auth/hooks/useUser'
import { routes } from 'src/common/routes'

export type AccountActivationPage = Record<string, never>

export const AccountActivationPage = (): React.ReactElement => {
  const { t } = useTranslation()
  const searchQuery = new URLSearchParams(useLocation().search)
  const { activateEmailAddress, loading: loadingUser } = useUser()
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const { push } = useHistory()

  const ident = searchQuery.get('ident')
  const token = searchQuery.get('token')

  useEffect(() => {
    // it is important to wait until the initial ME query has finished
    // so the response does not conflict with the second ME query after activation
    if (!loadingUser && !loading && ident && token) {
      setLoading(true)
      activateEmailAddress(ident, token)
        .then(() => push(routes.profile))
        .catch((error) => {
          setLoading(false)
          setError(error.response?.data.error || 'unknown')
        })
    }
  }, [ident, token, activateEmailAddress, loadingUser, push, loading])

  return (
    <EmailSubmissionConfirmation
      title={t('common:accountActivation.heading')}
      subTitle={t('common:accountActivation.subHeading')}
      loading={loading}
      error={error}
    />
  )
}
