import { css } from '@emotion/react'

import { theme } from 'src/theme'

export const footerCss = {
  base: css`
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;

    ${theme.media.sm} {
      flex-direction: row;
    }

    > * {
      ${theme.media.sm} {
        flex-basis: 0;
        flex-grow: 1;
      }

      &:nth-of-type(2) {
        margin: 0 0 1.6rem 0;

        ${theme.media.sm} {
          margin: 0 0 0 1.6rem;
        }
      }
    }
  `,
  horizontal: css`
    flex-direction: row;
    justify-content: space-between;

    > * {
      &:nth-of-type(2) {
        margin: 0 0 0 1.6rem;
      }

      ${theme.media.sm} {
        flex-basis: 0;
        flex-grow: 1;
      }
    }
  `,
}
