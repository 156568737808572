import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosTransformer,
} from 'axios'
import camelcaseKeys from 'camelcase-keys'
import cookie from 'cookie'

import config from 'src/config/config'

import { LoginResponseData } from './api.types'

const authApiUrl = config.apiUrl + 'api/v1/auth/'

const axiosConfig: AxiosRequestConfig = {
  // pass cookies
  withCredentials: true,
  baseURL: authApiUrl,
  headers: axios.defaults.headers,
  transformRequest: [
    (data, headers) => {
      // pass csrf token from cookie
      headers['X-CSRFToken'] = cookie.parse(document.cookie).csrftoken
      return data
    },
    ...(axios.defaults.transformRequest as AxiosTransformer[]),
  ],
}

const camelCaseError = <D>(
  apiCall: Promise<AxiosResponse<D>>,
): Promise<AxiosResponse<D>> =>
  apiCall.catch((error) => {
    if ('response' in error) {
      throw {
        ...error,
        response: {
          ...error.response,
          data: camelcaseKeys(error.response.data),
        },
      }
    }
    throw error
  })

export const loginAPI = (
  ident: string,
  password: string,
  rememberMe: boolean,
): Promise<LoginResponseData> =>
  camelCaseError(
    axios.post<LoginResponseData>(
      'login/',
      { ident, password, rememberMe },
      axiosConfig,
    ),
  ).then(({ data }) => data)

export const logoutAPI = (): Promise<unknown> =>
  camelCaseError(axios.post('logout/', {}, axiosConfig)).then(
    ({ data }) => data,
  )

export const activateEmailAddressAPI = (
  ident: string,
  token: string,
): Promise<unknown> =>
  camelCaseError(
    axios.post('activate_email/', { ident, token }, axiosConfig),
  ).then(({ data }) => data)

export const sendPWResetEmail = (email: string): Promise<unknown> =>
  camelCaseError(
    axios.post('send_pw_reset_email/', { email }, axiosConfig),
  ).then(({ data }) => data)

export const resetPasswordAPI = (
  ident: string,
  token: string,
  password: string,
): Promise<unknown> =>
  camelCaseError(
    axios.post('reset_password/', { ident, token, password }, axiosConfig),
  ).then(({ data }) => data)

export const registerAPI = (
  email: string,
  password: string,
): Promise<unknown> =>
  camelCaseError(
    axios.post('register/', { email, password }, axiosConfig),
  ).then(({ data }) => data)
