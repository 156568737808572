import { css } from '@emotion/react'

import { theme } from 'src/theme'

const config = {
  color: theme.colors.blackAlpha['300'],
  shift: '1.2rem',
  blur: '1.2rem',
}

export const _noShadow = `inset 0 0 0 0 ${config.color}`
const _topShadow = `inset 0 -${config.shift} ${config.blur} -${config.shift} ${config.color}`
const _bottomShadow = `inset 0 ${config.shift} ${config.blur} -${config.shift} ${config.color}`

// We always need to set both, top and bottom shadow to ensure a nice css transition
export const noShadow = `${_noShadow}, ${_noShadow}`
export const topShadow = `${_topShadow}, ${_noShadow}`
export const bottomShadow = `${_noShadow}, ${_bottomShadow}`
export const topAndBottomShadow = `${_topShadow}, ${_bottomShadow}`

export const bodyCss = css`
  box-shadow: ${noShadow};
  transition: box-shadow 200ms ease-out;
  white-space: pre-line;
`
