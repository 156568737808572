import { css } from '@emotion/react'

import { colors } from 'src/theme/colors'
import { radii } from 'src/theme/radii'
import { defaultFocusCss } from 'src/theme/utils'

export const containerCss = css`
  display: flex;
  flex-direction: column;
`

export const menuMultipleStyles = css`
  position: absolute;

  max-height: 18rem;
  overflow-y: auto;
  width: 100%;
  background: white;
  z-index: 1000;
  left: 0;
  top: calc(100% + 0.4rem);

  border-radius: ${radii.md};

  li {
    min-height: 4rem;
    display: flex;
    align-items: center;
    padding: 0.6rem 1.3rem;

    &:not(:first-of-type) {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        display: block;
        height: 0.1rem;
        left: 0.8rem;
        right: 0.8rem;
        top: 0;
        background: ${colors.custom.blueFill};
      }
    }
  }
`

export const clearButtonCss = css`
  ${defaultFocusCss};
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

export const comboboxStyles = css`
  position: relative;
`

export const comboboxWrapperStyles = css`
  position: relative;
  max-width: 58rem;
`

export const progressCss = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`

export const placeholderCss = css`
  height: 4rem;
  display: flex;
  align-items: center;
`
