import imageCompression from 'browser-image-compression'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface UseCompressedImageReturnType {
  image: File | null
  compressing: boolean
  updateImage: (img: null | File) => void
  error: string | undefined
}

export const useCompressedImage = (): UseCompressedImageReturnType => {
  const [compressing, setCompressing] = useState(false)
  const [image, setImage] = useState<null | File>(null)
  const [error, setError] = useState<string | undefined>(undefined)
  const { t } = useTranslation()
  const handleChangeFile = useCallback(
    (img: null | File) => {
      if (img === null) return setImage(img)
      setError(undefined)
      setCompressing(true)
      imageCompression(img, {
        // Use webworker for faster compression with
        // the help of threads
        useWebWorker: true,
        maxSizeMB: 4,
        maxWidthOrHeight: 1920,
      })
        .then((compressedFile) => {
          setImage(compressedFile)
        })
        .catch(() => {
          setError(t('common:errors.fileCompressingError'))
        })
        .finally(() => setCompressing(false))
    },
    [t, setImage],
  )

  return {
    image,
    compressing,
    updateImage: handleChangeFile,
    error,
  }
}
