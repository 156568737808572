import { Button, chakra, Divider, HStack, Link } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link as ReactLink } from 'react-router-dom'

import { routes } from 'src/common/routes'
import { DateFormat, dateTimeFormat } from 'src/common/utils/date.utils'
import {
  MatchEnum,
  Maybe,
  SessionSlotType,
  UserProfileType,
  UserType,
} from 'src/graphql/__generated__/types'
import { colors } from 'src/theme/colors'
import { UserAvatar } from 'src/userprofile/components/UserAvatar'
import { getUserName } from 'src/userprofile/utils'

import { sessionItemCss } from './SessionListItem.styles'

export interface SessionListItem {
  sessionId: string
  matchType: MatchEnum
  slot: Pick<SessionSlotType, 'start' | 'end'>
  matchUser: Pick<UserType, 'id' | 'email'> & {
    profile?: Maybe<
      Pick<UserProfileType, 'title' | 'firstName' | 'lastName' | 'organisation'>
    >
  }
  tableNumber: number
  onCancelSession: (sessionId: string) => void
  slotIsActive: boolean
}

export const SessionListItem = ({
  sessionId,
  matchType,
  slot,
  matchUser,
  tableNumber,
  onCancelSession,
  slotIsActive,
}: SessionListItem): React.ReactElement => {
  const { t, i18n } = useTranslation()
  const handleCancelSession = () => {
    onCancelSession(sessionId)
  }

  const isMatch = matchType === MatchEnum.MATCH
  const formatTime = (dateString: string) =>
    dateTimeFormat(new Date(dateString), DateFormat.Time, i18n.language)
  const fullName = getUserName(matchUser)

  return (
    <chakra.li
      css={sessionItemCss}
      bg={isMatch ? 'custom.blueFill' : 'gray.50'}
      border={
        slotIsActive
          ? `0.2rem solid ${colors.custom.yellowLight}`
          : isMatch
          ? 'unset'
          : `0.1rem solid ${colors.custom.blueFill}`
      }
    >
      <chakra.p textStyle={'small'} color={'gray.500'}>
        {t('common:schedule.sessionSlot', {
          startTime: formatTime(slot.start),
          endTime: formatTime(slot.end),
          tableNumber,
        })}
      </chakra.p>
      <HStack mt={'2.4rem'} spacing={'1.6rem'}>
        <UserAvatar boxSize={'4.7rem'} userProfile={matchUser?.profile} />
        <chakra.div>
          <Link
            as={ReactLink}
            marginTop={'2rem'}
            textStyle={'h5'}
            to={routes.participantProfile(matchUser.id)}
          >
            {`${fullName} (${matchUser?.profile?.organisation})`}
          </Link>

          <chakra.p textStyle={'small'} color={'gray.500'}>
            {isMatch
              ? t('common:schedule.match')
              : matchType === MatchEnum.TO_CURRENT_USER
              ? t('common:schedule.currentUserRequested', {
                  fullName,
                })
              : t('common:schedule.requestFromCurrentUser')}
          </chakra.p>
        </chakra.div>
      </HStack>
      <Divider mt={'2.4rem'} />
      <Button
        variant={'link'}
        disabled={Date.now() > Date.parse(slot.start)}
        onClick={handleCancelSession}
        mt={'1.6rem'}
      >
        {t('schedule.cancelSession')}
      </Button>
    </chakra.li>
  )
}
