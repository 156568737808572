import { useModalContext, chakra } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'

import { visuallyHiddenCss } from 'src/common/utils/style.utils'

import { buttonCss } from './ModalCloseButton.styles'

export interface ModalCloseButton {
  label?: string
}

/**
 * Custom modal close button using our custom svg icon
 * */
export const ModalCloseButton = ({
  label,
}: ModalCloseButton): React.ReactElement => {
  const { onClose } = useModalContext()
  const { t } = useTranslation()
  return (
    <chakra.button onClick={onClose} css={buttonCss}>
      <MdClose aria-hidden fontSize={'2.4rem'} />
      <chakra.span css={visuallyHiddenCss}>
        {label || t('common:close')}
      </chakra.span>
    </chakra.button>
  )
}
