import { chakra, Skeleton, SkeletonText } from '@chakra-ui/react'

import { containerCss } from 'src/matchingevent/components/MatchingEventListItem/MatchingEventListItem.styles'
import { colors } from 'src/theme/colors'

export const MatchingEventListItemSkeleton = (): React.ReactElement => {
  return (
    <chakra.li css={containerCss} layerStyle={'elevated'}>
      <Skeleton
        height={'1.4rem'}
        width={'33%'}
        margin={{ base: '1.4rem 0 0 0', md: '0 0 1rem 0' }}
      />
      <Skeleton
        height={'1.8rem'}
        color={colors.custom.blueDark}
        marginTop={'1.2rem'}
      />
      <chakra.div textStyle={'text'} marginTop={'3.2rem'}>
        <SkeletonText noOfLines={10} spacing={'4'} />
      </chakra.div>
    </chakra.li>
  )
}
