import { css } from '@emotion/react'

export const userMenuButtonCss = css`
  border-radius: 4.8rem;
  padding: 0.4rem 0.4rem 0.4rem 1.6rem;
  display: flex;

  > * {
    // chakra renders another span inside the button
    // that we want to style
    display: flex;
    align-items: center;
  }

  + * {
    // fix: the menu exceeds the right edge of the viewport
    // when the menu is not expanded, what causes layout shifts
    right: 0;
  }
`

export const userNameCss = css`
  flex-basis: 0;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 20rem;
  padding: 0.5rem 0; // make sure text isn't cut-of
`

export const itemCss = css`
  padding: 1.2rem 1.6rem;
`
