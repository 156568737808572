import { ComponentStyleConfig } from '@chakra-ui/react'

import { colors, ColorSchemeKey } from '../colors'
import { textStyles, fontWeights } from '../typography'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '0.5rem',
    fontWeight: fontWeights.bold,
    boxSizing: 'borderBox',
    _disabled: {
      opacity: 1,
      bg: `${colors.gray['100']} !important`,
      color: `${colors.gray['400']} !important`,
    },
  },
  sizes: {
    md: {
      fontSize: textStyles.small.fontSize,
      height: '4.2rem',
      padding: '0 1.8rem',
    },
  },
  variants: {
    primary: {
      bg: colors.custom.blueDark,
      color: colors.white,
      _hover: {
        bg: colors.custom.blueLight,
      },
    },
    secondary: ({ colorScheme = 'blue' }) =>
      ({
        bg: colors[colorScheme as ColorSchemeKey]['50'],
        color: colors[colorScheme as ColorSchemeKey]['500'],
        _hover: {
          bg: colors.gray['50'],
        },
      } as const),
    tertiary: {
      bg: colors.white,
      color: colors.custom.blueLight,
      boxShadow: `inset 0 0 0 0.2rem ${colors.custom.blueLight}`,
      _hover: {
        bg: colors.gray['50'],
      },
    },
    inverted: {
      bg: colors.white,
      color: colors.custom.blueDark,
      _hover: {
        bg: colors.gray['50'],
      },
    },
    ghost: {
      color: colors.custom.blueLight,
      padding: '0',
      _hover: {
        background: colors.gray['50'],
      },
    },
    link: {
      color: colors.custom.blueLight,
      padding: '0',
      fontSize: '1.6rem',
      lineHeight: '175%',
      fontWeight: 'normal',
      _hover: {
        color: colors.custom.blueDark,
        textDecoration: 'none',
      },
    },
    tag: {
      bg: colors.custom.yellowDark,
      color: colors.custom.yellowButton,
      _hover: {
        bg: colors.custom.yellowLight,
      },
    },
  },
  defaultProps: {
    variant: 'primary',
    colorScheme: 'blue',
  },
}
