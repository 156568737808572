import { chakra, Stack, VStack, HStack, Link, Divider } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'react-i18next'
import { FaLinkedin, FaTwitter, FaXing } from 'react-icons/fa'
import { useParams } from 'react-router'

import { ChipList } from 'src/common/components/ChipList'
import { ErrorPage404 } from 'src/common/components/Error'
import { ReadMore } from 'src/common/components/ReadMore'
import { TopicType, useParticipantQuery } from 'src/graphql/__generated__/types'
import {
  contentColumnNormalCss,
  defaultPageCss,
  defaultSectionCss,
} from 'src/theme/layout'

import { getUserName } from '../../utils'
import { MyProfilePageSkeleton } from '../MyProfilePage/MyProfilePageSkeleton'
import { UserAvatar } from '../UserAvatar'

const itemToString = (item: TopicType | null) => item?.name || ''

const genders = (t: TFunction): Record<string, string> => ({
  ['M']: t(`common:salutation.M`),
  ['F']: t(`common:salutation.F`),
  ['D']: '',
})
export const ParticipantProfilePage = (): React.ReactElement => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useParticipantQuery({
    variables: { id },
  })
  const participant = data?.participant || undefined

  const gender =
    participant && participant.profile?.gender
      ? genders(t)[participant.profile?.gender]
      : ''

  const titleId = 'profile_page__title'
  if (!loading && !participant) {
    return <ErrorPage404 />
  }

  if (loading || !participant) {
    return <MyProfilePageSkeleton />
  }

  return (
    <chakra.article
      css={[defaultSectionCss, defaultPageCss]}
      role={'main'}
      aria-labelledby={titleId}
    >
      <chakra.div
        css={contentColumnNormalCss}
        marginTop={{ base: '1rem', lg: '1.8rem' }}
      >
        <Stack
          spacing={{ md: '4.6rem' }}
          direction={{ base: 'column', md: 'row' }}
          align={'center'}
        >
          <UserAvatar
            boxSize={'16rem'}
            userProfile={participant?.profile}
            bg={'gray.300'}
          />
        </Stack>
      </chakra.div>

      <chakra.div
        css={contentColumnNormalCss}
        layerStyle={'elevated'}
        padding={{ base: '2.4rem', lg: '4.8rem' }}
        marginTop={{ base: '4rem', lg: '4.8rem' }}
      >
        <chakra.section aria-labelledby={titleId}>
          <chakra.header gridArea={'title'}>
            <chakra.h1 id={titleId} textStyle={'h3'} color={'custom.blueDark'}>
              {gender} {getUserName(participant)}
            </chakra.h1>
          </chakra.header>
          <VStack alignItems={'start'} spacing={1}>
            <chakra.h3 textStyle={'h5'}>
              {t('common:userProfile.organisation')}
            </chakra.h3>
            <chakra.p textStyle={'text'}>
              {participant.profile?.organisation}
            </chakra.p>
            <chakra.h3 textStyle={'h5'}>
              {t('common:userProfile.job')}
            </chakra.h3>
            <chakra.p textStyle={'text'}>{participant.profile?.job}</chakra.p>
            {participant.profile?.aboutMe && (
              <>
                <chakra.h3 textStyle={'h5'}>
                  {t('common:userProfile.aboutMe')}
                </chakra.h3>
                <chakra.div maxWidth={'100%'} mt={'2.4rem'}>
                  <ReadMore text={participant.profile?.aboutMe} />
                </chakra.div>
              </>
            )}
            <chakra.h3 textStyle={'h5'}>{t('common:contacts')}</chakra.h3>
            {participant.profile?.phone && (
              <chakra.div
                maxWidth={'100%'}
                mt={'2.4rem'}
                display={'flex'}
                alignItems={'center'}
              >
                <Link href={'tel:' + participant.profile.phone} isExternal>
                  {participant.profile.phone}
                </Link>
              </chakra.div>
            )}
            <Link href={'mailto:' + participant.email} isExternal>
              {participant.email}
            </Link>
            {!participant.profile?.phone && !participant.email && <>-</>}

            <chakra.h3 textStyle={'h5'}>{t('common:socialMedia')}</chakra.h3>
            <HStack>
              {participant.profile?.twitter && (
                <Link href={participant.profile.twitter} isExternal>
                  <FaTwitter size={30} />
                </Link>
              )}
              {participant.profile?.linkedIn && (
                <Link href={participant.profile.linkedIn} isExternal>
                  <FaLinkedin size={30} />
                </Link>
              )}
              {participant.profile?.xing && (
                <Link href={participant.profile.xing} isExternal>
                  <FaXing size={30} />
                </Link>
              )}
            </HStack>
            {!participant.profile?.twitter &&
              !participant.profile?.linkedIn &&
              !participant.profile?.xing && <>-</>}
          </VStack>
        </chakra.section>
      </chakra.div>
      <chakra.div
        css={contentColumnNormalCss}
        layerStyle={'elevated'}
        padding={{ base: '2.4rem', lg: '4.8rem' }}
        marginTop={{ base: '4rem', lg: '4.8rem' }}
      >
        <chakra.section>
          <VStack alignItems={'start'} spacing={1}>
            <chakra.div mt={'1.6rem'}>
              <chakra.h3 textStyle={'h5'}>
                {t('common:myProfile.offeredTopics')}
              </chakra.h3>
              {participant.offeredTopics.length > 0 && (
                <ChipList
                  items={participant.offeredTopics}
                  itemToString={itemToString}
                  isReadonly
                />
              )}
            </chakra.div>
            <Divider mt={'1.6reem'} mb={'1.6rem'} />
            <chakra.div mt={'1.6rem'}>
              <chakra.h3 textStyle={'h5'}>
                {t('common:myProfile.demandedTopics')}
              </chakra.h3>
              {participant.demandedTopics.length > 0 && (
                <ChipList
                  items={participant.demandedTopics}
                  itemToString={itemToString}
                  isReadonly
                />
              )}
            </chakra.div>
          </VStack>
        </chakra.section>
      </chakra.div>
    </chakra.article>
  )
}
