import { useApolloClient } from '@apollo/client'
import {
  chakra,
  Menu,
  MenuButton,
  MenuList,
  Button,
  StyleProps,
  useDisclosure,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdLanguage } from 'react-icons/md'

import { theme } from 'src/theme'

import { itemCss } from './UserMenu.styles'

export type LanguageMenu = StyleProps

export const LanguageMenu = (
  props: LanguageMenu,
): React.ReactElement | null => {
  const { t, i18n } = useTranslation()
  const { isOpen, onClose, onToggle } = useDisclosure()
  const client = useApolloClient()

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng).then(() => client.resetStore())
  }

  return (
    <Menu
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onToggle}
      placement={'bottom-end'}
      {...props}
    >
      <MenuButton
        as={Button}
        variant={'ghost'}
        aria-label={t('common:languageMenu.label')}
        marginLeft={'1.2rem'}
        borderRadius={'4.8rem'}
      >
        <chakra.div
          aria-hidden
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <MdLanguage size={'2rem'} color={theme.colors.custom.blueDark} />
        </chakra.div>
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          defaultValue={
            i18n.language === ('de-DE' || 'en-US') ? i18n.language : 'en-US'
          }
        >
          <MenuItemOption
            value={'de-DE'}
            onClick={() => handleLanguageChange('de-DE')}
            css={itemCss}
          >
            {t('common:languageMenu.german')}
          </MenuItemOption>
          <MenuItemOption
            value={'en-US'}
            onClick={() => handleLanguageChange('en-US')}
            css={itemCss}
          >
            {t('common:languageMenu.english')}
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
