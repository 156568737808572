import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  Link,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Link as ReactLink } from 'react-router-dom'

import {
  AuthSection,
  AuthSectionActions,
  AuthSectionContent,
} from 'src/auth/components/AuthSection'
import { useUser } from 'src/auth/hooks/useUser'
import { emailPattern, passwordOptions } from 'src/auth/utils/validation'
import { NonFieldErrors, PasswordInput } from 'src/common/components/Form'
import { routes } from 'src/common/routes'
import { fullHeightCss } from 'src/common/utils/style.utils'

interface RegisterFormInput {
  password: string
  email: string
}

export type RegisterSection = Record<string, never>

export const RegisterSection = (): React.ReactElement => {
  const { t } = useTranslation()
  const { register } = useUser()
  const [loading, setLoading] = useState(false)
  const { push } = useHistory()
  const [nonFieldErrors, setNonFieldErrors] = useState<string[]>([])

  const {
    register: registerInput,
    handleSubmit,
    formState,
    setFocus,
  } = useForm<RegisterFormInput>({
    mode: 'onTouched',
  })

  const onSubmit = ({ email, password }: RegisterFormInput) => {
    setLoading(true)
    register(email, password)
      .then(() => push(routes.activateAccount))
      .catch((error: AxiosError) => {
        const passwordError = error.response?.data?.password ?? []
        const emailError = error.response?.data?.email ?? []
        const nonFieldErrors = error.response?.data?.nonFieldErrors ?? []
        const _errors = [...nonFieldErrors, ...passwordError, ...emailError]
        setNonFieldErrors(_errors.length > 0 ? _errors : ['unknown'])
        setLoading(false)
      })
  }

  return (
    <chakra.form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      css={fullHeightCss}
    >
      <AuthSection>
        <AuthSectionContent>
          <chakra.h1 textStyle={'h2'} mb={'2.4rem'}>
            {t('common:registerPage.heading')}
          </chakra.h1>
          <chakra.p textStyle={'text'} mb={'2.4rem'}>
            {t('common:registerPage.subHeading')}
          </chakra.p>
          <VStack spacing={'2.4rem'} align={'stretch'}>
            <FormControl id={'email'} isInvalid={!!formState.errors.email}>
              <FormLabel> {t('common:email')}</FormLabel>
              <Input
                type={'email'}
                {...registerInput('email', {
                  required: t('common:errors.missingRequired') as string,
                  pattern: {
                    value: emailPattern,
                    message: t('common:errors.invalidEmail'),
                  },
                })}
              />
              <FormErrorMessage>
                {formState.errors.email?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id={'password'}
              isInvalid={!!formState.errors.password}
            >
              <FormLabel>{t('common:password')}</FormLabel>
              <PasswordInput
                {...registerInput('password', passwordOptions(t))}
                autoComplete={'on'}
              />
              <FormErrorMessage>
                {formState.errors?.password?.message}
              </FormErrorMessage>
            </FormControl>
            <NonFieldErrors
              errors={nonFieldErrors}
              setErrors={setNonFieldErrors}
              setFocus={setFocus}
              focusOnClose={'email'}
            />
          </VStack>
        </AuthSectionContent>
        <AuthSectionActions>
          <chakra.p textStyle={'text'}>
            <Trans i18nKey={'registerTermsAndConditions'}>
              Durch Klicken auf „Zustimmen & Anmelden“ stimmen Sie der
              <Link as={ReactLink} variant={'primary'} to={'#'}>
                Nutzervereinbarung
              </Link>
              , der
              <Link as={ReactLink} variant={'primary'} to={'/datenschutz'}>
                Datenschutzrichtlinie
              </Link>
              und der
              <Link as={ReactLink} variant={'primary'} to={'#'}>
                Cookie-Richtlinie
              </Link>
              von Next Match zu.
            </Trans>
          </chakra.p>
          <Button
            type={'submit'}
            width={'100%'}
            isLoading={loading}
            mt={'2.4rem'}
          >
            {t('common:registerPage.doRegister')}
          </Button>
        </AuthSectionActions>
      </AuthSection>
    </chakra.form>
  )
}
