import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import {
  AuthSection,
  AuthSectionActions,
  AuthSectionContent,
} from 'src/auth/components/AuthSection'
import { useUser } from 'src/auth/hooks/useUser'
import { passwordOptions } from 'src/auth/utils/validation'
import { NonFieldErrors, PasswordInput } from 'src/common/components/Form'
import { routes } from 'src/common/routes'
import { fullHeightCss } from 'src/common/utils/style.utils'

interface RegisterFormInput {
  password: string
}

export type PasswordResetPage = Record<string, never>

export const PasswordResetPage = (): React.ReactElement => {
  const { t } = useTranslation()
  const { resetPassword } = useUser()
  const [loading, setLoading] = useState(false)
  const { push } = useHistory()
  const [errors, setErrors] = useState<string[]>([])

  const searchQuery = new URLSearchParams(useLocation().search)
  const ident = searchQuery.get('ident')
  const token = searchQuery.get('token')

  const toast = useToast()

  const {
    register: registerInput,
    handleSubmit,
    formState,
    setFocus,
  } = useForm<RegisterFormInput>({
    mode: 'onTouched',
  })

  const onSubmit = ({ password }: RegisterFormInput) => {
    setLoading(true)
    if (ident && token) {
      resetPassword(ident, token, password)
        .then(() => {
          toast({
            title: t('common:resetPassword.passwordReset'),
            description: t('common:resetPassword.passwordResetConfirmation'),
            duration: 10000, // 10s
            status: 'success',
            isClosable: true,
          })
          push(routes.login)
        })
        .catch((error: AxiosError) => {
          const errorCode = error.response?.data?.error
          setErrors([errorCode || 'unknown'])
          setLoading(false)
        })
    }
  }

  return (
    <chakra.form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      css={fullHeightCss}
    >
      <AuthSection>
        <AuthSectionContent>
          <chakra.h1 textStyle={'h2'} mb={'2.4rem'}>
            {t('common:resetPassword.heading')}
          </chakra.h1>
          <chakra.p textStyle={'text'} mb={'2.4rem'}>
            {t('common:resetPassword.subHeading')}
          </chakra.p>
          <VStack spacing={'2.4rem'} align={'stretch'}>
            <FormControl
              id={'password'}
              isInvalid={!!formState.errors.password}
            >
              <FormLabel>{t('common:resetPassword.newPassword')}</FormLabel>
              <PasswordInput
                {...registerInput('password', passwordOptions(t))}
                autoComplete={'on'}
              />
              <FormErrorMessage>
                {formState.errors?.password?.message}
              </FormErrorMessage>
            </FormControl>
            <NonFieldErrors
              errors={errors}
              setErrors={setErrors}
              setFocus={setFocus}
              focusOnClose={'password'}
            />
          </VStack>
        </AuthSectionContent>
        <AuthSectionActions>
          <Button
            type={'submit'}
            width={'100%'}
            isLoading={loading}
            mt={'2.4rem'}
          >
            {t('common:resetPassword.saveNewPassword')}
          </Button>
        </AuthSectionActions>
      </AuthSection>
    </chakra.form>
  )
}
