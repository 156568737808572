import { chakra, Flex, FlexProps } from '@chakra-ui/react'

export interface ErrorDisplay extends FlexProps {
  title: string
}

export const ErrorDisplay = ({
  title,
  ...flexProps
}: ErrorDisplay): React.ReactElement => (
  <Flex justify={'center'} align={'center'} {...flexProps}>
    <chakra.h1 color={'custom.error'} textStyle={'h1'}>
      {title}
    </chakra.h1>
  </Flex>
)
