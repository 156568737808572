import { extendTheme } from '@chakra-ui/react'

import { breakpoints, media } from './breakpoints'
import { colors } from './colors'
import { components } from './components'
import { layerStyles } from './layer'
import { grid, layout } from './layout'
import { radii } from './radii'
import { sizes } from './sizes'
import { space } from './space'
import {
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  textStyles,
} from './typography'
import { zIndices } from './zIndices'

export const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
} as const

// ** Global styles ** //
const styles = {
  global: {
    html: {
      // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
      fontSize: '62.5%' /* 62.5% of 16px = 10px = 1rem */,
    },
    body: {
      fontSize: fontSizes.md,
      lineHeight: lineHeights.normal,
      fontWeight: fontWeights.normal,
      color: colors.text.default,
      overflowY: 'scroll', // enable scrollbar to prevent content shifting when switching between page
    },
    'ul, ol': {
      listStyleType: 'none',
    },
    li: {
      display: 'block',
    },
  },
}

export const theme = extendTheme({
  components,
  // --- config ---
  config,
  styles,
  // --- colors ---
  colors,
  // --- fonts ---
  fonts,
  fontWeights,
  fontSizes,
  textStyles,
  // --- breakpoints ---
  breakpoints,
  media,
  // --- layout ---
  grid,
  layout,
  space,
  sizes,
  // --- layer ---
  layerStyles,
  // --- indices ---
  zIndices,
  // radii
  radii,
})

export type Theme = typeof theme
