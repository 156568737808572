import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any
}

export type BlogArticleType = Node & {
  __typename?: 'BlogArticleType'
  /** The ID of the object. */
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  /** Überschrift des Artikels */
  title: Scalars['String']
  body: Scalars['String']
  /** z.B. "Artikel" oder "Ausschreibung" */
  category: Scalars['String']
  /** z.B. bei Ausschreibungen */
  region: Scalars['String']
  topics: Array<TopicType>
}

export type BlogArticleTypeConnection = {
  __typename?: 'BlogArticleTypeConnection'
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BlogArticleTypeEdge>>
}

/** A Relay edge containing a `BlogArticleType` and its cursor. */
export type BlogArticleTypeEdge = {
  __typename?: 'BlogArticleTypeEdge'
  /** The item at the end of the edge */
  node?: Maybe<BlogArticleType>
  /** A cursor for use in pagination */
  cursor: Scalars['String']
}

export type CancelMatchingRequestMutationInput = {
  toUserId: Scalars['ID']
  matchingEventSlug: Scalars['String']
  clientMutationId?: Maybe<Scalars['String']>
}

export type CancelMatchingRequestMutationPayload = {
  __typename?: 'CancelMatchingRequestMutationPayload'
  matchingEvent?: Maybe<MatchingEventNode>
  clientMutationId?: Maybe<Scalars['String']>
}

export type CancelSessionMutationInput = {
  eventId: Scalars['ID']
  sessionId: Scalars['ID']
  clientMutationId?: Maybe<Scalars['String']>
}

export type CancelSessionMutationPayload = {
  __typename?: 'CancelSessionMutationPayload'
  matchingEvent?: Maybe<MatchingEventNode>
  clientMutationId?: Maybe<Scalars['String']>
}

export type CreateUpdateUserProfileMutation = {
  __typename?: 'CreateUpdateUserProfileMutation'
  me?: Maybe<UserType>
}

export type EntryNode = Node & {
  __typename?: 'EntryNode'
  slug: Scalars['String']
  title: Scalars['String']
  body: Scalars['String']
  /** The ID of the object. */
  id: Scalars['ID']
}

/** An enumeration. */
export enum Gender {
  M = 'M',
  F = 'F',
  D = 'D',
}

export type GroupTagNode = Node & {
  __typename?: 'GroupTagNode'
  name: Scalars['String']
  /** The ID of the object. */
  id: Scalars['ID']
}

export enum MatchEnum {
  MATCH = 'MATCH',
  FROM_CURRENT_USER = 'FROM_CURRENT_USER',
  TO_CURRENT_USER = 'TO_CURRENT_USER',
}

export type MatchingEventNode = Node & {
  __typename?: 'MatchingEventNode'
  /** The ID of the object. */
  id: Scalars['ID']
  name: Scalars['String']
  slug: Scalars['String']
  description: Scalars['String']
  /** Der Teaser Text sollte nicht mehr als 50 Wörter enthalten */
  teaserText: Scalars['String']
  startDate: Scalars['DateTime']
  endDate: Scalars['DateTime']
  subscriptionDeadline: Scalars['DateTime']
  matchingRequestDeadline: Scalars['DateTime']
  streetAndNumber: Scalars['String']
  zipCode: Scalars['String']
  city: Scalars['String']
  conferenceUrl: Scalars['String']
  isExclusive: Scalars['Boolean']
  allowOverlappingDates: Scalars['Boolean']
  isRequestRejectionAllowed: Scalars['Boolean']
  users?: Maybe<UserTypeConnection>
  groupTags: Array<Scalars['String']>
  currentUserParticipation?: Maybe<ParticipationNode>
  usersCount: Scalars['Int']
  currentUserMatchingRequests: Array<MatchingRequestType>
  currentUserRequestedMatches: Array<MatchingRequestType>
  showTemporarySchedule?: Maybe<Scalars['Boolean']>
}

export type MatchingEventNodeUsersArgs = {
  matchingTopics?: Maybe<Array<Scalars['ID']>>
  hasRequestFromCurrentUser?: Maybe<Scalars['Boolean']>
  hasRequestToCurrentUser?: Maybe<Scalars['Boolean']>
  offset?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type MatchingRequestType = {
  __typename?: 'MatchingRequestType'
  id: Scalars['ID']
  fromUser: UserType
  toUser: UserType
  isRejected: Scalars['Boolean']
}

export type Mutation = {
  __typename?: 'Mutation'
  subscribeForMatchingEvent?: Maybe<SubscribeMutationPayload>
  unsubscribeForMatchingEvent?: Maybe<UnsubscribeMutationPayload>
  cancelSession?: Maybe<CancelSessionMutationPayload>
  userTopics?: Maybe<UpdateUserTopicMutationPayload>
  matchingRequest?: Maybe<SendMatchingRequestMutationPayload>
  rejectMatchingRequest?: Maybe<RejectMatchingRequestMutationPayload>
  cancelMatchingRequest?: Maybe<CancelMatchingRequestMutationPayload>
  deleteUser?: Maybe<UserDeleteMutation>
  userProfile?: Maybe<CreateUpdateUserProfileMutation>
  uploadUserProfileImage?: Maybe<UploadUserProfileImageMutation>
}

export type MutationSubscribeForMatchingEventArgs = {
  input: SubscribeMutationInput
}

export type MutationUnsubscribeForMatchingEventArgs = {
  input: UnsubscribeMutationInput
}

export type MutationCancelSessionArgs = {
  input: CancelSessionMutationInput
}

export type MutationUserTopicsArgs = {
  input: UpdateUserTopicMutationInput
}

export type MutationMatchingRequestArgs = {
  input: SendMatchingRequestMutationInput
}

export type MutationRejectMatchingRequestArgs = {
  input: RejectMatchingRequestMutationInput
}

export type MutationCancelMatchingRequestArgs = {
  input: CancelMatchingRequestMutationInput
}

export type MutationUserProfileArgs = {
  userData?: Maybe<UserProfileInput>
}

export type MutationUploadUserProfileImageArgs = {
  profileImage?: Maybe<Scalars['Upload']>
}

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
}

export type ParticipationNode = Node & {
  __typename?: 'ParticipationNode'
  /** The ID of the object. */
  id: Scalars['ID']
  user: UserType
  groupTag?: Maybe<GroupTagNode>
}

export type Query = {
  __typename?: 'Query'
  blogArticles?: Maybe<BlogArticleTypeConnection>
  scheduleSessionList: Array<SessionType>
  topics?: Maybe<Array<TopicType>>
  matchingEvent?: Maybe<MatchingEventNode>
  matchingEvents: Array<MatchingEventNode>
  entry?: Maybe<EntryNode>
  /** Get currently authenticated user */
  me?: Maybe<UserType>
  /** Get user profile */
  participant?: Maybe<UserType>
  hello: Scalars['String']
  helloUser: Scalars['String']
  helloStaff: Scalars['String']
}

export type QueryBlogArticlesArgs = {
  offset?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
}

export type QueryScheduleSessionListArgs = {
  matchingEventId: Scalars['ID']
}

export type QueryTopicsArgs = {
  search?: Maybe<Scalars['String']>
}

export type QueryMatchingEventArgs = {
  slug: Scalars['String']
  passphrase?: Maybe<Scalars['String']>
}

export type QueryEntryArgs = {
  slug: Scalars['String']
}

export type QueryParticipantArgs = {
  id: Scalars['ID']
}

export type RejectMatchingRequestMutationInput = {
  fromUserId: Scalars['ID']
  matchingEventSlug: Scalars['String']
  clientMutationId?: Maybe<Scalars['String']>
}

export type RejectMatchingRequestMutationPayload = {
  __typename?: 'RejectMatchingRequestMutationPayload'
  matchingEvent?: Maybe<MatchingEventNode>
  clientMutationId?: Maybe<Scalars['String']>
}

export type SendMatchingRequestMutationInput = {
  toUserId: Scalars['ID']
  matchingEventSlug: Scalars['String']
  clientMutationId?: Maybe<Scalars['String']>
}

export type SendMatchingRequestMutationPayload = {
  __typename?: 'SendMatchingRequestMutationPayload'
  matchingEvent?: Maybe<MatchingEventNode>
  clientMutationId?: Maybe<Scalars['String']>
}

export type SessionSlotType = {
  __typename?: 'SessionSlotType'
  start: Scalars['DateTime']
  end: Scalars['DateTime']
}

export type SessionType = {
  __typename?: 'SessionType'
  id: Scalars['ID']
  tableNumber: Scalars['Int']
  /** Im Fall von Prio 2 die anfragende Person */
  user1?: Maybe<UserType>
  /** Im Fall von Prio 2 die angefragte Person */
  user2?: Maybe<UserType>
  matchType?: Maybe<MatchEnum>
  slot: SessionSlotType
}

export type SubscribeMutationInput = {
  matchingEventId: Scalars['ID']
  clientMutationId?: Maybe<Scalars['String']>
}

export type SubscribeMutationPayload = {
  __typename?: 'SubscribeMutationPayload'
  matchingEvent: MatchingEventNode
  clientMutationId?: Maybe<Scalars['String']>
}

export type TopicType = {
  __typename?: 'TopicType'
  id: Scalars['ID']
  name: Scalars['String']
}

export type UnsubscribeMutationInput = {
  matchingEventId: Scalars['ID']
  clientMutationId?: Maybe<Scalars['String']>
}

export type UnsubscribeMutationPayload = {
  __typename?: 'UnsubscribeMutationPayload'
  matchingEvent?: Maybe<MatchingEventNode>
  clientMutationId?: Maybe<Scalars['String']>
}

export type UpdateUserTopicMutationInput = {
  demandedTopics: Array<Scalars['Int']>
  offeredTopics: Array<Scalars['Int']>
  clientMutationId?: Maybe<Scalars['String']>
}

export type UpdateUserTopicMutationPayload = {
  __typename?: 'UpdateUserTopicMutationPayload'
  me?: Maybe<UserType>
  clientMutationId?: Maybe<Scalars['String']>
}

export type UploadUserProfileImageMutation = {
  __typename?: 'UploadUserProfileImageMutation'
  me?: Maybe<UserType>
}

export type UserDeleteMutation = {
  __typename?: 'UserDeleteMutation'
  ok?: Maybe<Scalars['Boolean']>
}

/** An enumeration. */
export enum UserProfileGender {
  /** Männlich */
  M = 'M',
  /** Weiblich */
  F = 'F',
  /** Divers */
  D = 'D',
}

export type UserProfileInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  gender?: Maybe<Gender>
  title?: Maybe<Scalars['String']>
  job?: Maybe<Scalars['String']>
  organisation?: Maybe<Scalars['String']>
  aboutMe?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  xing?: Maybe<Scalars['String']>
  linkedIn?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  showEmail?: Maybe<Scalars['Boolean']>
}

export type UserProfileType = Node & {
  __typename?: 'UserProfileType'
  firstName: Scalars['String']
  lastName: Scalars['String']
  title: Scalars['String']
  gender?: Maybe<UserProfileGender>
  job: Scalars['String']
  organisation: Scalars['String']
  aboutMe: Scalars['String']
  profileImage?: Maybe<Scalars['String']>
  showEmail: Scalars['Boolean']
  phone?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  linkedIn?: Maybe<Scalars['String']>
  xing?: Maybe<Scalars['String']>
  /** The ID of the object. */
  id: Scalars['ID']
  isProfileComplete: Scalars['Boolean']
}

export type UserType = Node & {
  __typename?: 'UserType'
  /** The ID of the object. */
  id: Scalars['ID']
  email: Scalars['String']
  profile?: Maybe<UserProfileType>
  demandedTopics: Array<TopicType>
  offeredTopics: Array<TopicType>
  matchingEventGroupTag?: Maybe<Scalars['String']>
}

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection'
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserTypeEdge>>
}

/** A Relay edge containing a `UserType` and its cursor. */
export type UserTypeEdge = {
  __typename?: 'UserTypeEdge'
  /** The item at the end of the edge */
  node?: Maybe<UserType>
  /** A cursor for use in pagination */
  cursor: Scalars['String']
}

export type DeleteUserMutationVariables = Exact<{ [key: string]: never }>

export type DeleteUserMutation = { __typename?: 'Mutation' } & { deleteUser?: Maybe<{ __typename?: 'UserDeleteMutation' } & Pick<UserDeleteMutation, 'ok'>> }

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'UserType' } & Pick<UserType, 'id' | 'email'> & {
        profile?: Maybe<
          { __typename?: 'UserProfileType' } & Pick<
            UserProfileType,
            'id' | 'title' | 'job' | 'firstName' | 'lastName' | 'organisation' | 'aboutMe' | 'gender' | 'profileImage' | 'isProfileComplete' | 'showEmail' | 'twitter' | 'xing' | 'phone' | 'linkedIn'
          >
        >
        offeredTopics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>
        demandedTopics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>
      }
  >
}

export type BlogArticlesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}>

export type BlogArticlesQuery = { __typename?: 'Query' } & {
  blogArticles?: Maybe<
    { __typename?: 'BlogArticleTypeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'BlogArticleTypeEdge' } & {
            node?: Maybe<
              { __typename?: 'BlogArticleType' } & Pick<BlogArticleType, 'id' | 'title' | 'body' | 'category' | 'region' | 'createdAt'> & {
                  topics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'name'>>
                }
            >
          }
        >
      >
      pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    }
  >
}

export type EntryQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type EntryQuery = { __typename?: 'Query' } & { entry?: Maybe<{ __typename?: 'EntryNode' } & Pick<EntryNode, 'id' | 'title' | 'body'>> }

export type TopicsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>
}>

export type TopicsQuery = { __typename?: 'Query' } & { topics?: Maybe<Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>> }

export type SubscribeForMatchingEventMutationVariables = Exact<{
  matchingEventId: Scalars['ID']
}>

export type SubscribeForMatchingEventMutation = { __typename?: 'Mutation' } & {
  subscribeForMatchingEvent?: Maybe<
    { __typename?: 'SubscribeMutationPayload' } & {
      matchingEvent: { __typename?: 'MatchingEventNode' } & Pick<MatchingEventNode, 'id' | 'slug' | 'usersCount'> & {
          currentUserParticipation?: Maybe<{ __typename?: 'ParticipationNode' } & Pick<ParticipationNode, 'id'>>
        }
    }
  >
}

export type UnsubscribeForMatchingEventMutationVariables = Exact<{
  matchingEventId: Scalars['ID']
}>

export type UnsubscribeForMatchingEventMutation = { __typename?: 'Mutation' } & {
  unsubscribeForMatchingEvent?: Maybe<
    { __typename?: 'UnsubscribeMutationPayload' } & {
      matchingEvent?: Maybe<
        { __typename?: 'MatchingEventNode' } & Pick<MatchingEventNode, 'id' | 'slug' | 'usersCount'> & {
            currentUserParticipation?: Maybe<{ __typename?: 'ParticipationNode' } & Pick<ParticipationNode, 'id'>>
          }
      >
    }
  >
}

export type SendMatchingRequestMutationVariables = Exact<{
  toUserId: Scalars['ID']
  matchingEventSlug: Scalars['String']
}>

export type SendMatchingRequestMutation = { __typename?: 'Mutation' } & {
  matchingRequest?: Maybe<
    { __typename?: 'SendMatchingRequestMutationPayload' } & {
      matchingEvent?: Maybe<
        { __typename?: 'MatchingEventNode' } & Pick<MatchingEventNode, 'id' | 'slug'> & {
            currentUserMatchingRequests: Array<{ __typename?: 'MatchingRequestType' } & Pick<MatchingRequestType, 'id'> & { toUser: { __typename?: 'UserType' } & Pick<UserType, 'id'> }>
          }
      >
    }
  >
}

export type CancelMatchingRequestMutationVariables = Exact<{
  toUserId: Scalars['ID']
  matchingEventSlug: Scalars['String']
}>

export type CancelMatchingRequestMutation = { __typename?: 'Mutation' } & {
  cancelMatchingRequest?: Maybe<
    { __typename?: 'CancelMatchingRequestMutationPayload' } & {
      matchingEvent?: Maybe<
        { __typename?: 'MatchingEventNode' } & Pick<MatchingEventNode, 'id' | 'slug'> & {
            currentUserMatchingRequests: Array<{ __typename?: 'MatchingRequestType' } & Pick<MatchingRequestType, 'id'> & { toUser: { __typename?: 'UserType' } & Pick<UserType, 'id'> }>
          }
      >
    }
  >
}

export type RejectMatchingRequestMutationVariables = Exact<{
  fromUserId: Scalars['ID']
  matchingEventSlug: Scalars['String']
}>

export type RejectMatchingRequestMutation = { __typename?: 'Mutation' } & {
  rejectMatchingRequest?: Maybe<
    { __typename?: 'RejectMatchingRequestMutationPayload' } & {
      matchingEvent?: Maybe<
        { __typename?: 'MatchingEventNode' } & Pick<MatchingEventNode, 'id' | 'slug'> & {
            currentUserMatchingRequests: Array<{ __typename?: 'MatchingRequestType' } & Pick<MatchingRequestType, 'id'> & { toUser: { __typename?: 'UserType' } & Pick<UserType, 'id'> }>
          }
      >
    }
  >
}

export type CancelSessionMutationVariables = Exact<{
  matchingEventId: Scalars['ID']
  sessionId: Scalars['ID']
}>

export type CancelSessionMutation = { __typename?: 'Mutation' } & {
  cancelSession?: Maybe<{ __typename?: 'CancelSessionMutationPayload' } & { matchingEvent?: Maybe<{ __typename?: 'MatchingEventNode' } & Pick<MatchingEventNode, 'id' | 'slug' | 'name'>> }>
}

export type MatchingEventsQueryVariables = Exact<{ [key: string]: never }>

export type MatchingEventsQuery = { __typename?: 'Query' } & {
  matchingEvents: Array<{ __typename?: 'MatchingEventNode' } & Pick<MatchingEventNode, 'id' | 'city' | 'isExclusive' | 'name' | 'slug' | 'startDate' | 'teaserText'>>
}

export type MatchingEventQueryVariables = Exact<{
  slug: Scalars['String']
  passphrase?: Maybe<Scalars['String']>
}>

export type MatchingEventQuery = { __typename?: 'Query' } & {
  matchingEvent?: Maybe<
    { __typename?: 'MatchingEventNode' } & Pick<
      MatchingEventNode,
      | 'id'
      | 'slug'
      | 'name'
      | 'description'
      | 'startDate'
      | 'subscriptionDeadline'
      | 'matchingRequestDeadline'
      | 'endDate'
      | 'streetAndNumber'
      | 'zipCode'
      | 'city'
      | 'usersCount'
      | 'isExclusive'
      | 'allowOverlappingDates'
      | 'showTemporarySchedule'
    > & { currentUserParticipation?: Maybe<{ __typename?: 'ParticipationNode' } & Pick<ParticipationNode, 'id'>> }
  >
}

export type ScheduleSessionListQueryVariables = Exact<{
  matchingEventId: Scalars['ID']
}>

export type ScheduleSessionListQuery = { __typename?: 'Query' } & {
  scheduleSessionList: Array<
    { __typename?: 'SessionType' } & Pick<SessionType, 'id' | 'matchType' | 'tableNumber'> & {
        user1?: Maybe<
          { __typename?: 'UserType' } & Pick<UserType, 'id' | 'email'> & {
              profile?: Maybe<{ __typename?: 'UserProfileType' } & Pick<UserProfileType, 'title' | 'firstName' | 'lastName' | 'organisation' | 'profileImage'>>
            }
        >
        user2?: Maybe<
          { __typename?: 'UserType' } & Pick<UserType, 'id' | 'email'> & {
              profile?: Maybe<{ __typename?: 'UserProfileType' } & Pick<UserProfileType, 'title' | 'firstName' | 'lastName' | 'organisation' | 'profileImage'>>
            }
        >
        slot: { __typename?: 'SessionSlotType' } & Pick<SessionSlotType, 'start' | 'end'>
      }
  >
}

export type MatchingEventParticipantsQueryVariables = Exact<{
  slug: Scalars['String']
  matchingTopics?: Maybe<Array<Scalars['ID']> | Scalars['ID']>
  hasRequestToCurrentUser?: Maybe<Scalars['Boolean']>
  hasRequestFromCurrentUser?: Maybe<Scalars['Boolean']>
}>

export type MatchingEventParticipantsQuery = { __typename?: 'Query' } & {
  matchingEvent?: Maybe<
    { __typename?: 'MatchingEventNode' } & Pick<
      MatchingEventNode,
      'id' | 'slug' | 'subscriptionDeadline' | 'matchingRequestDeadline' | 'allowOverlappingDates' | 'isRequestRejectionAllowed' | 'groupTags'
    > & {
        currentUserParticipation?: Maybe<{ __typename?: 'ParticipationNode' } & Pick<ParticipationNode, 'id'>>
        currentUserMatchingRequests: Array<{ __typename?: 'MatchingRequestType' } & Pick<MatchingRequestType, 'id' | 'isRejected'> & { toUser: { __typename?: 'UserType' } & Pick<UserType, 'id'> }>
        currentUserRequestedMatches: Array<{ __typename?: 'MatchingRequestType' } & Pick<MatchingRequestType, 'id' | 'isRejected'> & { fromUser: { __typename?: 'UserType' } & Pick<UserType, 'id'> }>
        users?: Maybe<
          { __typename?: 'UserTypeConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'UserTypeEdge' } & {
                  node?: Maybe<
                    { __typename?: 'UserType' } & Pick<UserType, 'id' | 'email' | 'matchingEventGroupTag'> & {
                        profile?: Maybe<
                          { __typename?: 'UserProfileType' } & Pick<
                            UserProfileType,
                            | 'id'
                            | 'title'
                            | 'job'
                            | 'firstName'
                            | 'lastName'
                            | 'organisation'
                            | 'aboutMe'
                            | 'gender'
                            | 'profileImage'
                            | 'isProfileComplete'
                            | 'showEmail'
                            | 'twitter'
                            | 'xing'
                            | 'linkedIn'
                            | 'phone'
                          >
                        >
                        offeredTopics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>
                        demandedTopics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>
                      }
                  >
                }
              >
            >
          }
        >
      }
  >
}

export type UserProfileMutationVariables = Exact<{
  userData?: Maybe<UserProfileInput>
}>

export type UserProfileMutation = { __typename?: 'Mutation' } & {
  userProfile?: Maybe<
    { __typename?: 'CreateUpdateUserProfileMutation' } & {
      me?: Maybe<
        { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
            profile?: Maybe<
              { __typename?: 'UserProfileType' } & Pick<
                UserProfileType,
                'id' | 'title' | 'job' | 'firstName' | 'lastName' | 'organisation' | 'aboutMe' | 'gender' | 'isProfileComplete' | 'phone' | 'xing' | 'twitter' | 'linkedIn'
              >
            >
          }
      >
    }
  >
}

export type UpdateMyTopicsMutationVariables = Exact<{
  offeredTopics: Array<Scalars['Int']> | Scalars['Int']
  demandedTopics: Array<Scalars['Int']> | Scalars['Int']
}>

export type UpdateMyTopicsMutation = { __typename?: 'Mutation' } & {
  userTopics?: Maybe<
    { __typename?: 'UpdateUserTopicMutationPayload' } & {
      me?: Maybe<
        { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
            offeredTopics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>
            demandedTopics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>
          }
      >
    }
  >
}

export type UploadProfileImageMutationVariables = Exact<{
  profileImage?: Maybe<Scalars['Upload']>
}>

export type UploadProfileImageMutation = { __typename?: 'Mutation' } & {
  uploadUserProfileImage?: Maybe<
    { __typename?: 'UploadUserProfileImageMutation' } & {
      me?: Maybe<{ __typename?: 'UserType' } & Pick<UserType, 'id'> & { profile?: Maybe<{ __typename?: 'UserProfileType' } & Pick<UserProfileType, 'id' | 'profileImage'>> }>
    }
  >
}

export type MyTopicsQueryVariables = Exact<{ [key: string]: never }>

export type MyTopicsQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
        offeredTopics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>
        demandedTopics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>
      }
  >
}

export type ParticipantQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ParticipantQuery = { __typename?: 'Query' } & {
  participant?: Maybe<
    { __typename?: 'UserType' } & Pick<UserType, 'id' | 'email'> & {
        profile?: Maybe<
          { __typename?: 'UserProfileType' } & Pick<
            UserProfileType,
            'id' | 'title' | 'job' | 'firstName' | 'lastName' | 'organisation' | 'aboutMe' | 'gender' | 'profileImage' | 'showEmail' | 'phone' | 'xing' | 'twitter' | 'linkedIn'
          >
        >
        offeredTopics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>
        demandedTopics: Array<{ __typename?: 'TopicType' } & Pick<TopicType, 'id' | 'name'>>
      }
  >
}

export const DeleteUserDocument = gql`
  mutation deleteUser {
    deleteUser {
      ok
    }
  }
`
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options)
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>
export const MeDocument = gql`
  query me {
    me {
      id
      email
      profile {
        id
        title
        job
        firstName
        lastName
        organisation
        aboutMe
        gender
        profileImage
        isProfileComplete
        showEmail
        twitter
        xing
        phone
        linkedIn
      }
      offeredTopics {
        id
        name
      }
      demandedTopics {
        id
        name
      }
    }
  }
`
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const BlogArticlesDocument = gql`
  query blogArticles($first: Int, $after: String) {
    blogArticles(first: $first, after: $after) {
      edges {
        node {
          id
          title
          body
          category
          region
          createdAt
          topics {
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`
export function useBlogArticlesQuery(baseOptions?: Apollo.QueryHookOptions<BlogArticlesQuery, BlogArticlesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<BlogArticlesQuery, BlogArticlesQueryVariables>(BlogArticlesDocument, options)
}
export function useBlogArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogArticlesQuery, BlogArticlesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<BlogArticlesQuery, BlogArticlesQueryVariables>(BlogArticlesDocument, options)
}
export type BlogArticlesQueryHookResult = ReturnType<typeof useBlogArticlesQuery>
export type BlogArticlesLazyQueryHookResult = ReturnType<typeof useBlogArticlesLazyQuery>
export type BlogArticlesQueryResult = Apollo.QueryResult<BlogArticlesQuery, BlogArticlesQueryVariables>
export const EntryDocument = gql`
  query entry($slug: String!) {
    entry(slug: $slug) {
      id
      title
      body
    }
  }
`
export function useEntryQuery(baseOptions: Apollo.QueryHookOptions<EntryQuery, EntryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EntryQuery, EntryQueryVariables>(EntryDocument, options)
}
export function useEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EntryQuery, EntryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EntryQuery, EntryQueryVariables>(EntryDocument, options)
}
export type EntryQueryHookResult = ReturnType<typeof useEntryQuery>
export type EntryLazyQueryHookResult = ReturnType<typeof useEntryLazyQuery>
export type EntryQueryResult = Apollo.QueryResult<EntryQuery, EntryQueryVariables>
export const TopicsDocument = gql`
  query topics($search: String) {
    topics(search: $search) {
      id
      name
    }
  }
`
export function useTopicsQuery(baseOptions?: Apollo.QueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options)
}
export function useTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options)
}
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>
export type TopicsQueryResult = Apollo.QueryResult<TopicsQuery, TopicsQueryVariables>
export const SubscribeForMatchingEventDocument = gql`
  mutation subscribeForMatchingEvent($matchingEventId: ID!) {
    subscribeForMatchingEvent(input: { matchingEventId: $matchingEventId }) {
      matchingEvent {
        id
        slug
        usersCount
        currentUserParticipation {
          id
        }
      }
    }
  }
`
export type SubscribeForMatchingEventMutationFn = Apollo.MutationFunction<SubscribeForMatchingEventMutation, SubscribeForMatchingEventMutationVariables>
export function useSubscribeForMatchingEventMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeForMatchingEventMutation, SubscribeForMatchingEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubscribeForMatchingEventMutation, SubscribeForMatchingEventMutationVariables>(SubscribeForMatchingEventDocument, options)
}
export type SubscribeForMatchingEventMutationHookResult = ReturnType<typeof useSubscribeForMatchingEventMutation>
export type SubscribeForMatchingEventMutationResult = Apollo.MutationResult<SubscribeForMatchingEventMutation>
export type SubscribeForMatchingEventMutationOptions = Apollo.BaseMutationOptions<SubscribeForMatchingEventMutation, SubscribeForMatchingEventMutationVariables>
export const UnsubscribeForMatchingEventDocument = gql`
  mutation unsubscribeForMatchingEvent($matchingEventId: ID!) {
    unsubscribeForMatchingEvent(input: { matchingEventId: $matchingEventId }) {
      matchingEvent {
        id
        slug
        usersCount
        currentUserParticipation {
          id
        }
      }
    }
  }
`
export type UnsubscribeForMatchingEventMutationFn = Apollo.MutationFunction<UnsubscribeForMatchingEventMutation, UnsubscribeForMatchingEventMutationVariables>
export function useUnsubscribeForMatchingEventMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeForMatchingEventMutation, UnsubscribeForMatchingEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnsubscribeForMatchingEventMutation, UnsubscribeForMatchingEventMutationVariables>(UnsubscribeForMatchingEventDocument, options)
}
export type UnsubscribeForMatchingEventMutationHookResult = ReturnType<typeof useUnsubscribeForMatchingEventMutation>
export type UnsubscribeForMatchingEventMutationResult = Apollo.MutationResult<UnsubscribeForMatchingEventMutation>
export type UnsubscribeForMatchingEventMutationOptions = Apollo.BaseMutationOptions<UnsubscribeForMatchingEventMutation, UnsubscribeForMatchingEventMutationVariables>
export const SendMatchingRequestDocument = gql`
  mutation sendMatchingRequest($toUserId: ID!, $matchingEventSlug: String!) {
    matchingRequest(input: { toUserId: $toUserId, matchingEventSlug: $matchingEventSlug }) {
      matchingEvent {
        id
        slug
        currentUserMatchingRequests {
          id
          toUser {
            id
          }
        }
      }
    }
  }
`
export type SendMatchingRequestMutationFn = Apollo.MutationFunction<SendMatchingRequestMutation, SendMatchingRequestMutationVariables>
export function useSendMatchingRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendMatchingRequestMutation, SendMatchingRequestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendMatchingRequestMutation, SendMatchingRequestMutationVariables>(SendMatchingRequestDocument, options)
}
export type SendMatchingRequestMutationHookResult = ReturnType<typeof useSendMatchingRequestMutation>
export type SendMatchingRequestMutationResult = Apollo.MutationResult<SendMatchingRequestMutation>
export type SendMatchingRequestMutationOptions = Apollo.BaseMutationOptions<SendMatchingRequestMutation, SendMatchingRequestMutationVariables>
export const CancelMatchingRequestDocument = gql`
  mutation cancelMatchingRequest($toUserId: ID!, $matchingEventSlug: String!) {
    cancelMatchingRequest(input: { toUserId: $toUserId, matchingEventSlug: $matchingEventSlug }) {
      matchingEvent {
        id
        slug
        currentUserMatchingRequests {
          id
          toUser {
            id
          }
        }
      }
    }
  }
`
export type CancelMatchingRequestMutationFn = Apollo.MutationFunction<CancelMatchingRequestMutation, CancelMatchingRequestMutationVariables>
export function useCancelMatchingRequestMutation(baseOptions?: Apollo.MutationHookOptions<CancelMatchingRequestMutation, CancelMatchingRequestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelMatchingRequestMutation, CancelMatchingRequestMutationVariables>(CancelMatchingRequestDocument, options)
}
export type CancelMatchingRequestMutationHookResult = ReturnType<typeof useCancelMatchingRequestMutation>
export type CancelMatchingRequestMutationResult = Apollo.MutationResult<CancelMatchingRequestMutation>
export type CancelMatchingRequestMutationOptions = Apollo.BaseMutationOptions<CancelMatchingRequestMutation, CancelMatchingRequestMutationVariables>
export const RejectMatchingRequestDocument = gql`
  mutation rejectMatchingRequest($fromUserId: ID!, $matchingEventSlug: String!) {
    rejectMatchingRequest(input: { fromUserId: $fromUserId, matchingEventSlug: $matchingEventSlug }) {
      matchingEvent {
        id
        slug
        currentUserMatchingRequests {
          id
          toUser {
            id
          }
        }
      }
    }
  }
`
export type RejectMatchingRequestMutationFn = Apollo.MutationFunction<RejectMatchingRequestMutation, RejectMatchingRequestMutationVariables>
export function useRejectMatchingRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectMatchingRequestMutation, RejectMatchingRequestMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RejectMatchingRequestMutation, RejectMatchingRequestMutationVariables>(RejectMatchingRequestDocument, options)
}
export type RejectMatchingRequestMutationHookResult = ReturnType<typeof useRejectMatchingRequestMutation>
export type RejectMatchingRequestMutationResult = Apollo.MutationResult<RejectMatchingRequestMutation>
export type RejectMatchingRequestMutationOptions = Apollo.BaseMutationOptions<RejectMatchingRequestMutation, RejectMatchingRequestMutationVariables>
export const CancelSessionDocument = gql`
  mutation cancelSession($matchingEventId: ID!, $sessionId: ID!) {
    cancelSession(input: { sessionId: $sessionId, eventId: $matchingEventId }) {
      matchingEvent {
        id
        slug
        name
      }
    }
  }
`
export type CancelSessionMutationFn = Apollo.MutationFunction<CancelSessionMutation, CancelSessionMutationVariables>
export function useCancelSessionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSessionMutation, CancelSessionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelSessionMutation, CancelSessionMutationVariables>(CancelSessionDocument, options)
}
export type CancelSessionMutationHookResult = ReturnType<typeof useCancelSessionMutation>
export type CancelSessionMutationResult = Apollo.MutationResult<CancelSessionMutation>
export type CancelSessionMutationOptions = Apollo.BaseMutationOptions<CancelSessionMutation, CancelSessionMutationVariables>
export const MatchingEventsDocument = gql`
  query matchingEvents {
    matchingEvents {
      id
      city
      isExclusive
      name
      slug
      startDate
      teaserText
    }
  }
`
export function useMatchingEventsQuery(baseOptions?: Apollo.QueryHookOptions<MatchingEventsQuery, MatchingEventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MatchingEventsQuery, MatchingEventsQueryVariables>(MatchingEventsDocument, options)
}
export function useMatchingEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatchingEventsQuery, MatchingEventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MatchingEventsQuery, MatchingEventsQueryVariables>(MatchingEventsDocument, options)
}
export type MatchingEventsQueryHookResult = ReturnType<typeof useMatchingEventsQuery>
export type MatchingEventsLazyQueryHookResult = ReturnType<typeof useMatchingEventsLazyQuery>
export type MatchingEventsQueryResult = Apollo.QueryResult<MatchingEventsQuery, MatchingEventsQueryVariables>
export const MatchingEventDocument = gql`
  query matchingEvent($slug: String!, $passphrase: String) {
    matchingEvent(slug: $slug, passphrase: $passphrase) {
      id
      slug
      name
      description
      startDate
      subscriptionDeadline
      matchingRequestDeadline
      endDate
      streetAndNumber
      zipCode
      city
      usersCount
      isExclusive
      allowOverlappingDates
      currentUserParticipation {
        id
      }
      showTemporarySchedule
    }
  }
`
export function useMatchingEventQuery(baseOptions: Apollo.QueryHookOptions<MatchingEventQuery, MatchingEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MatchingEventQuery, MatchingEventQueryVariables>(MatchingEventDocument, options)
}
export function useMatchingEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatchingEventQuery, MatchingEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MatchingEventQuery, MatchingEventQueryVariables>(MatchingEventDocument, options)
}
export type MatchingEventQueryHookResult = ReturnType<typeof useMatchingEventQuery>
export type MatchingEventLazyQueryHookResult = ReturnType<typeof useMatchingEventLazyQuery>
export type MatchingEventQueryResult = Apollo.QueryResult<MatchingEventQuery, MatchingEventQueryVariables>
export const ScheduleSessionListDocument = gql`
  query scheduleSessionList($matchingEventId: ID!) {
    scheduleSessionList(matchingEventId: $matchingEventId) {
      id
      user1 {
        id
        email
        profile {
          title
          firstName
          lastName
          organisation
          profileImage
        }
      }
      user2 {
        id
        email
        profile {
          title
          firstName
          lastName
          organisation
          profileImage
        }
      }
      matchType
      tableNumber
      slot {
        start
        end
      }
    }
  }
`
export function useScheduleSessionListQuery(baseOptions: Apollo.QueryHookOptions<ScheduleSessionListQuery, ScheduleSessionListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ScheduleSessionListQuery, ScheduleSessionListQueryVariables>(ScheduleSessionListDocument, options)
}
export function useScheduleSessionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleSessionListQuery, ScheduleSessionListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ScheduleSessionListQuery, ScheduleSessionListQueryVariables>(ScheduleSessionListDocument, options)
}
export type ScheduleSessionListQueryHookResult = ReturnType<typeof useScheduleSessionListQuery>
export type ScheduleSessionListLazyQueryHookResult = ReturnType<typeof useScheduleSessionListLazyQuery>
export type ScheduleSessionListQueryResult = Apollo.QueryResult<ScheduleSessionListQuery, ScheduleSessionListQueryVariables>
export const MatchingEventParticipantsDocument = gql`
  query matchingEventParticipants($slug: String!, $matchingTopics: [ID!], $hasRequestToCurrentUser: Boolean, $hasRequestFromCurrentUser: Boolean) {
    matchingEvent(slug: $slug) {
      id
      slug
      subscriptionDeadline
      matchingRequestDeadline
      allowOverlappingDates
      isRequestRejectionAllowed
      groupTags
      currentUserParticipation {
        id
      }
      currentUserMatchingRequests {
        id
        toUser {
          id
        }
        isRejected
      }
      currentUserRequestedMatches {
        id
        fromUser {
          id
        }
        isRejected
      }
      users(matchingTopics: $matchingTopics, hasRequestToCurrentUser: $hasRequestToCurrentUser, hasRequestFromCurrentUser: $hasRequestFromCurrentUser) {
        edges {
          node {
            id
            email
            profile {
              id
              title
              job
              firstName
              lastName
              organisation
              aboutMe
              gender
              profileImage
              isProfileComplete
              showEmail
              twitter
              xing
              linkedIn
              phone
            }
            offeredTopics {
              id
              name
            }
            demandedTopics {
              id
              name
            }
            matchingEventGroupTag
          }
        }
      }
    }
  }
`
export function useMatchingEventParticipantsQuery(baseOptions: Apollo.QueryHookOptions<MatchingEventParticipantsQuery, MatchingEventParticipantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MatchingEventParticipantsQuery, MatchingEventParticipantsQueryVariables>(MatchingEventParticipantsDocument, options)
}
export function useMatchingEventParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatchingEventParticipantsQuery, MatchingEventParticipantsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MatchingEventParticipantsQuery, MatchingEventParticipantsQueryVariables>(MatchingEventParticipantsDocument, options)
}
export type MatchingEventParticipantsQueryHookResult = ReturnType<typeof useMatchingEventParticipantsQuery>
export type MatchingEventParticipantsLazyQueryHookResult = ReturnType<typeof useMatchingEventParticipantsLazyQuery>
export type MatchingEventParticipantsQueryResult = Apollo.QueryResult<MatchingEventParticipantsQuery, MatchingEventParticipantsQueryVariables>
export const UserProfileDocument = gql`
  mutation userProfile($userData: UserProfileInput) {
    userProfile(userData: $userData) {
      me {
        id
        profile {
          id
          title
          job
          firstName
          lastName
          organisation
          aboutMe
          gender
          isProfileComplete
          phone
          xing
          twitter
          linkedIn
        }
      }
    }
  }
`
export type UserProfileMutationFn = Apollo.MutationFunction<UserProfileMutation, UserProfileMutationVariables>
export function useUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UserProfileMutation, UserProfileMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UserProfileMutation, UserProfileMutationVariables>(UserProfileDocument, options)
}
export type UserProfileMutationHookResult = ReturnType<typeof useUserProfileMutation>
export type UserProfileMutationResult = Apollo.MutationResult<UserProfileMutation>
export type UserProfileMutationOptions = Apollo.BaseMutationOptions<UserProfileMutation, UserProfileMutationVariables>
export const UpdateMyTopicsDocument = gql`
  mutation updateMyTopics($offeredTopics: [Int!]!, $demandedTopics: [Int!]!) {
    userTopics(input: { offeredTopics: $offeredTopics, demandedTopics: $demandedTopics }) {
      me {
        id
        offeredTopics {
          id
          name
        }
        demandedTopics {
          id
          name
        }
      }
    }
  }
`
export type UpdateMyTopicsMutationFn = Apollo.MutationFunction<UpdateMyTopicsMutation, UpdateMyTopicsMutationVariables>
export function useUpdateMyTopicsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyTopicsMutation, UpdateMyTopicsMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMyTopicsMutation, UpdateMyTopicsMutationVariables>(UpdateMyTopicsDocument, options)
}
export type UpdateMyTopicsMutationHookResult = ReturnType<typeof useUpdateMyTopicsMutation>
export type UpdateMyTopicsMutationResult = Apollo.MutationResult<UpdateMyTopicsMutation>
export type UpdateMyTopicsMutationOptions = Apollo.BaseMutationOptions<UpdateMyTopicsMutation, UpdateMyTopicsMutationVariables>
export const UploadProfileImageDocument = gql`
  mutation uploadProfileImage($profileImage: Upload) {
    uploadUserProfileImage(profileImage: $profileImage) {
      me {
        id
        profile {
          id
          profileImage
        }
      }
    }
  }
`
export type UploadProfileImageMutationFn = Apollo.MutationFunction<UploadProfileImageMutation, UploadProfileImageMutationVariables>
export function useUploadProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadProfileImageMutation, UploadProfileImageMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadProfileImageMutation, UploadProfileImageMutationVariables>(UploadProfileImageDocument, options)
}
export type UploadProfileImageMutationHookResult = ReturnType<typeof useUploadProfileImageMutation>
export type UploadProfileImageMutationResult = Apollo.MutationResult<UploadProfileImageMutation>
export type UploadProfileImageMutationOptions = Apollo.BaseMutationOptions<UploadProfileImageMutation, UploadProfileImageMutationVariables>
export const MyTopicsDocument = gql`
  query myTopics {
    me {
      id
      offeredTopics {
        id
        name
      }
      demandedTopics {
        id
        name
      }
    }
  }
`
export function useMyTopicsQuery(baseOptions?: Apollo.QueryHookOptions<MyTopicsQuery, MyTopicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MyTopicsQuery, MyTopicsQueryVariables>(MyTopicsDocument, options)
}
export function useMyTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTopicsQuery, MyTopicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MyTopicsQuery, MyTopicsQueryVariables>(MyTopicsDocument, options)
}
export type MyTopicsQueryHookResult = ReturnType<typeof useMyTopicsQuery>
export type MyTopicsLazyQueryHookResult = ReturnType<typeof useMyTopicsLazyQuery>
export type MyTopicsQueryResult = Apollo.QueryResult<MyTopicsQuery, MyTopicsQueryVariables>
export const ParticipantDocument = gql`
  query participant($id: ID!) {
    participant(id: $id) {
      id
      email
      profile {
        id
        title
        job
        firstName
        lastName
        organisation
        aboutMe
        gender
        profileImage
        showEmail
        phone
        xing
        twitter
        linkedIn
      }
      offeredTopics {
        id
        name
      }
      demandedTopics {
        id
        name
      }
    }
  }
`
export function useParticipantQuery(baseOptions: Apollo.QueryHookOptions<ParticipantQuery, ParticipantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ParticipantQuery, ParticipantQueryVariables>(ParticipantDocument, options)
}
export function useParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantQuery, ParticipantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ParticipantQuery, ParticipantQueryVariables>(ParticipantDocument, options)
}
export type ParticipantQueryHookResult = ReturnType<typeof useParticipantQuery>
export type ParticipantLazyQueryHookResult = ReturnType<typeof useParticipantLazyQuery>
export type ParticipantQueryResult = Apollo.QueryResult<ParticipantQuery, ParticipantQueryVariables>
