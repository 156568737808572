import { chakra } from '@chakra-ui/react'
import React, { RefObject } from 'react'
import { useTranslation } from 'react-i18next'

import { forcedVisuallyHiddenCss } from 'src/common/utils/style.utils'

import { btnCss } from './SkipMenuButton.styles'

export const MAIN_ANCHOR_ID = 'main_content'

export interface SkipMenuButton {
  targetRef: RefObject<HTMLAnchorElement>
}

export const SkipMenuButton = ({
  targetRef,
}: SkipMenuButton): React.ReactElement => {
  const { t } = useTranslation()

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    // focus main anchor without scrolling + without changing the url
    e.preventDefault()
    targetRef.current?.focus({ preventScroll: true })
  }

  return (
    <chakra.a href={`#${MAIN_ANCHOR_ID}`} onClick={handleClick} css={btnCss}>
      {t('skipLinkLabel')}
    </chakra.a>
  )
}

export const MainAnchor = React.forwardRef<HTMLAnchorElement>((_, ref) => {
  const { t } = useTranslation()
  return (
    <chakra.a
      id={MAIN_ANCHOR_ID}
      ref={ref}
      css={forcedVisuallyHiddenCss}
      tabIndex={-1}
    >
      {t('mainAnchorLabel')}
    </chakra.a>
  )
})
