import React, { Component } from 'react'

export interface ErrorBoundaryProps {
  children: React.ReactNode
  fallback: React.ReactNode
}

export interface ErrorBoundaryState {
  hasError: boolean
}

const INITIAL_STATE: ErrorBoundaryState = {
  hasError: false,
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = INITIAL_STATE
  }

  public static getDerivedStateFromError(): Partial<ErrorBoundaryState> {
    return { hasError: true }
  }

  public render(): React.ReactNode {
    const { children, fallback } = this.props
    const { hasError } = this.state

    if (hasError) {
      return <>{fallback}</>
    }

    return <>{children}</>
  }
}
