import { ApolloError } from '@apollo/client'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  CloseButton,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface ApolloErrorMessage extends AlertProps {
  error?: ApolloError
}

export const ApolloErrorMessage = ({
  error,
  ...styleProps
}: ApolloErrorMessage): React.ReactElement | null => {
  const { t } = useTranslation()
  const [hideError, setHideError] = useState(false)

  useEffect(() => {
    setHideError(false)
  }, [error])

  return error && !hideError ? (
    <Alert status={'error'} {...styleProps}>
      <AlertIcon />
      <AlertDescription>
        {error.networkError
          ? t('common:errors.networkError')
          : error.graphQLErrors
          ? error.graphQLErrors
              .map((e) =>
                t([`common:apiErrors.${e.message}`, 'common:errors.unknown']),
              )
              .join(', ')
          : t('common:errors.unknown')}
      </AlertDescription>
      <CloseButton onClick={() => setHideError(true)} />
    </Alert>
  ) : null
}
