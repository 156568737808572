import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import { useUser } from 'src/auth/hooks/useUser'
import { routes } from 'src/common/routes'

export type NotLoggedInOnlyRoute = RouteProps

/**
 * Pages which makes sense only on case no user logged in like login, register, ...
 * */
export const NotLoggedInOnlyRoute = ({
  component: Component,
  ...rest
}: NotLoggedInOnlyRoute): React.ReactElement => {
  const { loggedIn } = useUser()

  // this is only relevant for the login page
  const searchQuery = new URLSearchParams(useLocation().search)
  const next = searchQuery.get('next')
  const nextRoute: string = next ? next : routes.home

  return loggedIn ? (
    <Redirect to={nextRoute} />
  ) : (
    <Route component={Component} {...rest} />
  )
}
