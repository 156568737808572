import { css } from '@emotion/react'

import { media } from 'src/theme/breakpoints'
import { colors } from 'src/theme/colors'
import { defaultTransitionCss } from 'src/theme/utils'

export const formFieldCss = css`
  position: relative;

  &:not(:last-child) {
    padding-bottom: 1.6rem;
    border-bottom: 0.1rem solid ${colors.gray['100']};
    margin-bottom: 1.2rem;
  }
`

export const inputCss = css`
  ${defaultTransitionCss};
  transition-property: box-shadow, padding;

  color: ${colors.text.default};

  ::placeholder {
    color: ${colors.text.default};
  }

  &[readonly],
  &[disabled] {
    border: none;
    box-shadow: none;
    opacity: 1;
    padding-left: 0;
    color: ${colors.text.default};

    &:hover {
      background: none;
    }

    // hide select icon
    + .chakra-select__icon-wrapper {
      display: none;
    }
  }
`

export const maxWidthCss = css`
  ${media.md} {
    max-width: calc(100% - 25rem);
  }
`
