import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom'

import { useUser } from 'src/auth/hooks/useUser'
import { routes } from 'src/common/routes'

export type PrivateRoute = RouteProps

/**
 * Protected pages which are only accessible if user is logged id
 * */
export const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRoute): React.ReactElement => {
  const { loggedIn, loading } = useUser()
  const location = useLocation()

  return !loading && !loggedIn ? (
    <Redirect to={`${routes.login}?next=${location.pathname}`} />
  ) : (
    <Route component={Component} {...rest} />
  )
}
