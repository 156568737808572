import { css } from '@emotion/react'

export const unitRoundedCss = css`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
`

const fontFamily = {
  openSans: "'Open Sans', sans-serif",
}

export const fonts = {
  heading: fontFamily.openSans,
  body: fontFamily.openSans,
}

export const fontSizes = {
  xs: '1.2rem',
  sm: '1.4rem',
  md: '1.6rem', // default
  lg: '1.8rem',
  xl: '2.2rem',
  '2xl': '2.8rem',
  '3xl': '3.2rem',
}

export const fontWeights = {
  normal: '400',
  semiBold: '600',
  bold: '700',
}

export const lineHeights = {
  normal: '150%',
}

export const textStyles = {
  h1: {
    fontSize: fontSizes['3xl'],
    lineHeight: '140%',
    fontWeight: fontWeights.bold,
  },
  h2: {
    fontSize: fontSizes['2xl'],
    lineHeight: '129%',
    fontWeight: fontWeights.semiBold,
  },
  h3: {
    fontSize: fontSizes.xl,
    lineHeight: '145%',
    fontWeight: fontWeights.semiBold,
  },
  h4: {
    fontSize: fontSizes.lg,
    lineHeight: '156%',
    fontWeight: fontWeights.semiBold,
  },
  h5: {
    fontSize: fontSizes.sm,
    lineHeight: '200%',
    fontWeight: fontWeights.bold,
  },
  h6: {
    fontSize: fontSizes.sm,
    lineHeight: '171%',
    fontWeight: fontWeights.normal,
  },
  text: {
    fontSize: fontSizes.md,
    lineHeight: '175%',
    fontWeight: fontWeights.normal,
  },
  small: {
    fontSize: fontSizes.sm,
    lineHeight: '150%',
    fontWeight: fontWeights.normal,
  },
}

export type TextStyle = keyof typeof textStyles
