import { chakra, Box, Button, Flex } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as ReactLink } from 'react-router-dom'

import { useUser } from 'src/auth/hooks/useUser'
import { HeroImage } from 'src/common/components/HeroImage'
import { RichText } from 'src/common/components/RichText'
import { routes } from 'src/common/routes'
import {
  EntryQueryResult,
  useEntryQuery,
} from 'src/graphql/__generated__/types'
import { defaultSectionCss, layout } from 'src/theme/layout'

export type HeroSection = Record<string, never>

export const HeroSection = (): React.ReactElement => {
  const { t } = useTranslation()
  const { loggedIn } = useUser()
  const { data }: EntryQueryResult = useEntryQuery({
    variables: { slug: 'HOMEPAGE_HERO' },
  })

  const titleId = 'hero__title'

  return (
    <chakra.header
      bg={'gray.100'}
      maxWidth={layout.maxWidth.hero}
      margin={'0 auto'}
      width={'100%'}
      position={'relative'}
      height={'auto'}
      aria-describedby={titleId}
    >
      <HeroImage />
      <chakra.div
        css={defaultSectionCss}
        paddingTop={{ base: '4rem', lg: '30vh' }}
        paddingBottom={{ base: '4rem', lg: '24.4rem' }}
      >
        <Flex
          direction={'column'}
          position={'relative'}
          textAlign={'left'}
          gridColumn={{ base: '1/-1', md: '1 / 8', lg: '2 / 8', xl: '1 / 8' }}
        >
          <chakra.div
            // prevent layout shift when data is loaded
            minHeight={!data?.entry ? '25rem' : undefined}
            color={'text.default'}
          >
            {data?.entry && (
              <>
                <Box as={'h1'} textStyle={'h1'} id={titleId}>
                  {data.entry.title}
                </Box>
                <RichText
                  html={data.entry.body}
                  textStyle={'paragraph'}
                  marginTop={{ base: '1.2rem', lg: '2.4rem' }}
                />
              </>
            )}
          </chakra.div>
          {!loggedIn && (
            <Button
              as={ReactLink}
              to={routes.register}
              alignSelf={'flex-start'}
              marginTop={'3.2rem'}
            >
              {t('register')}
            </Button>
          )}
        </Flex>
      </chakra.div>
    </chakra.header>
  )
}
