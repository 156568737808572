import { chakra, VStack, Button, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { mapToNodes } from 'src/common/utils/typescript'
import { useBlogArticlesQuery } from 'src/graphql/__generated__/types'
import {
  contentColumnNormalCss,
  defaultPageCss,
  defaultSectionCss,
} from 'src/theme/layout'

import { BlogArticleListItem } from './BlogArticleListItem'
import { SkeletonBlogArticleListItem } from './SkeletonBlogArticleListItem'

export type BlogPage = Record<string, never>

export const BLOG_ARTICLE_PAGE_COUNT = 10

export const BlogPage = (): React.ReactElement => {
  const { t } = useTranslation()

  const { data, loading, fetchMore } = useBlogArticlesQuery({
    variables: { first: BLOG_ARTICLE_PAGE_COUNT },
  })
  const pageInfo = data?.blogArticles?.pageInfo
  const blogArticles = mapToNodes(data?.blogArticles)

  const titleId = 'blog_page__title'

  return (
    <chakra.article
      css={[defaultSectionCss, defaultPageCss]}
      aria-labelledby={titleId}
    >
      <chakra.div css={contentColumnNormalCss}>
        <chakra.h1 id={titleId} textStyle={'h1'} color={'custom.blueDark'}>
          {t('common:mainNavigation.blog')}
        </chakra.h1>
        <VStack
          as={'ol'}
          spacing={{ base: '2.4rem', md: '6.4rem' }}
          mt={'4rem'}
          align={'strech'}
        >
          {loading
            ? new Array(4).fill(null).map((_, index) => {
                return <SkeletonBlogArticleListItem key={index} />
              })
            : blogArticles.map((blogArticle) => {
                return (
                  <BlogArticleListItem key={blogArticle.id} {...blogArticle} />
                )
              })}
        </VStack>
        {pageInfo?.hasNextPage && (
          <Flex mt={{ base: '2.4rem', md: '6.4rem' }} justify={'center'}>
            <Button
              variant={'secondary'}
              isLoading={loading}
              onClick={() =>
                fetchMore({ variables: { after: pageInfo.endCursor } })
              }
              width={{ base: '100%', md: 'auto' }}
            >
              {t('common:loadMore')}
            </Button>
          </Flex>
        )}
      </chakra.div>
    </chakra.article>
  )
}
