import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'

import { handleError } from 'src/common/utils/error.utils'
import {
  useDeleteUserMutation,
  useMeQuery,
} from 'src/graphql/__generated__/types'

import { AuthContext, UserContext } from './UserContext'
import {
  activateEmailAddressAPI,
  loginAPI,
  logoutAPI,
  registerAPI,
  resetPasswordAPI,
  sendPWResetEmail,
} from './api'

export interface UserProvider {
  children: React.ReactNode
  defaultLoggedIn?: boolean
}

export const UserProvider = ({
  children,
}: UserProvider): React.ReactElement => {
  const client = useApolloClient()

  const [loggedIn, setLoggedIn] = useState(false)
  const { data: userData, loading: userLoading } = useMeQuery()
  const [deleteUserAPI] = useDeleteUserMutation()

  const user = userData?.me ?? null

  const _setLoggedIn = (newLoggedIn: boolean): Promise<unknown> => {
    setLoggedIn(newLoggedIn)
    // resetStore will reset all graphql queries including the user query
    return client.resetStore()
  }

  const login: (
    userIdentifier: string,
    password: string,
    rememberMe: boolean,
  ) => Promise<unknown> = (userIdentifier, password, rememberMe) => {
    return loginAPI(userIdentifier, password, rememberMe).then((data) => {
      return _setLoggedIn(!!data?.user)
    })
  }

  const logout = () => {
    setLoggedIn(false)
    return logoutAPI()
      .catch((error) => handleError(error))
      .finally(() => {
        return client.resetStore()
      })
  }

  const activateEmailAddress: (
    ident: string,
    token: string,
  ) => Promise<unknown> = (ident, token) => {
    return activateEmailAddressAPI(ident, token).then(() => {
      return _setLoggedIn(true)
    })
  }

  const deleteUser = () => {
    setLoggedIn(false)
    return deleteUserAPI()
      .then(() => {
        return client.resetStore()
      })
      .catch((error) => handleError(error))
  }

  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      <AuthContext.Provider
        value={{
          // prevent the following combination : loggedIn=true + userLoading=false + user=null
          loading: loggedIn ? !user : userLoading,
          login,
          loggedIn: !!user || loggedIn,
          logout,
          deleteUser,
          activateEmailAddress,
          requestPasswordReset: sendPWResetEmail,
          resetPassword: resetPasswordAPI,
          register: registerAPI,
          setLoggedIn: _setLoggedIn,
        }}
      >
        {children}
      </AuthContext.Provider>
    </UserContext.Provider>
  )
}
