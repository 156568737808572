import { chakra, Box } from '@chakra-ui/react'

import { RichText } from 'src/common/components/RichText'
import { colors } from 'src/theme/colors'
import { contentColumnNormalCss, defaultSectionCss } from 'src/theme/layout'

export interface StaticPage {
  title: string
  body: string
}

export const StaticPage = ({ title, body }: StaticPage): React.ReactElement => {
  return (
    <chakra.div
      css={defaultSectionCss}
      paddingTop={'4.8rem'}
      paddingBottom={{ base: '15rem', md: '7.8rem' }}
    >
      <chakra.div css={contentColumnNormalCss}>
        <chakra.h1 textStyle={'h1'} color={colors.custom.blueDark}>
          {title}
        </chakra.h1>
        <Box marginTop={'4.8rem'}>
          <RichText html={body} />
        </Box>
      </chakra.div>
    </chakra.div>
  )
}
