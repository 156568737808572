import { chakra, Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react'

import {
  contentColumnNormalCss,
  defaultPageCss,
  defaultSectionCss,
} from 'src/theme/layout'

export type MyProfilePageSkeleton = Record<string, never>

export const MyProfilePageSkeleton = (): React.ReactElement => {
  return (
    <chakra.div css={[defaultSectionCss, defaultPageCss]}>
      <chakra.div css={contentColumnNormalCss}>
        <Flex direction={'column'} align={'strech'}>
          <Skeleton h={'4.4rem'} maxWidth={'20rem'} />
          <SkeletonCircle
            size={'11rem'}
            mt={{ base: '4rem', md: '4.8rem' }}
            alignSelf={{ base: 'center', md: 'flex-start' }}
          />
          <Skeleton mt={{ base: '4rem', md: '4.8rem' }} h={'70rem'} />
          <Skeleton mt={{ base: '4rem', md: '4.8rem' }} h={'70rem'} />
        </Flex>
      </chakra.div>
    </chakra.div>
  )
}
