import { css } from '@emotion/react'

import { theme } from 'src/theme'

export const buttonCss = css`
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  transition-duration: 150ms;
  transition-property: background;
  border-radius: 2rem;

  svg {
    transition-duration: 200ms;
    transition-property: transform;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    background-color: ${theme.colors.gray['50']};

    svg {
      transform: rotate(90deg);
    }
  }

  &.focus-visible {
    // todo robin unified focus style
  }
`
