import {
  ModalFooter as ChakraModalFooter,
  ModalFooterProps,
} from '@chakra-ui/react'

import { footerCss } from './ModalFooter.styles'

export type ModalFooter = ModalFooterProps & {
  direction?: 'vertical' | 'horizontal'
}

/**
 * Custom modal footer providing two different layouts (horizontal and vertical)
 * */
export const ModalFooter = ({
  direction = 'vertical',
  children,
  ...props
}: ModalFooter): React.ReactElement => {
  return (
    <ChakraModalFooter
      css={direction === 'vertical' ? footerCss.base : footerCss.horizontal}
      {...props}
    >
      {children}
    </ChakraModalFooter>
  )
}
