import { space } from 'src/theme/space'

/*
 * We need to override the default sizes from Chakra-UI because we set the
 * root font-size to 62.5% (1 / 1.6), which allows use to use 1rem as an equivalent to 10px
 * instead of 1rem == 16px. Therefore we need to multiply all default rem sizes by 1.6
 * */

export const sizes = {
  ...space,
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  '3xs': '22.4rem',
  '2xs': '25.6rem',
  xs: '32rem',
  sm: '38.4rem',
  md: '44.8rem',
  lg: '51.2rem',
  xl: '57.6rem',
  '2xl': '67.2rem',
  '3xl': '76.8rem',
  '4xl': '89.6rem',
  '5xl': '102.4rem',
  '6xl': '115.2rem',
  '7xl': '128rem',
  '8xl': '144rem',
}
