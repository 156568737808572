import { css } from '@emotion/react'

import { theme } from 'src/theme'

export const containerCss = css`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.8rem;

  padding: 2.4rem 1.2rem;

  ${theme.media.md} {
    grid-gap: 2.4rem;
    padding: 2.4rem;
  }

  ${theme.media.lg} {
    grid-gap: 3.2rem;
  }
`
