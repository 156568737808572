import React, { createContext } from 'react'

import { User } from 'src/auth/types'

import { ErrorPromise } from './utils'

export interface UserContextValue {
  user: User | null
}

export interface AuthContextValue {
  loading: boolean
  login: (
    userIdentifier: string,
    password: string,
    rememberMe: boolean,
  ) => Promise<unknown>
  loggedIn: boolean
  setLoggedIn: (loggedIn: boolean) => Promise<unknown>
  logout: () => Promise<unknown>
  deleteUser: () => Promise<unknown>
  activateEmailAddress: (
    userIdentifier: string,
    token: string,
  ) => Promise<unknown>
  requestPasswordReset: (email: string) => Promise<unknown>
  resetPassword: (
    ident: string,
    token: string,
    password: string,
  ) => Promise<unknown>
  register: (email: string, password: string) => Promise<unknown>
}

export const AuthContext = React.createContext<AuthContextValue>({
  loading: false,
  login: ErrorPromise,
  loggedIn: false,
  setLoggedIn: ErrorPromise,
  logout: ErrorPromise,
  deleteUser: ErrorPromise,
  activateEmailAddress: ErrorPromise,
  requestPasswordReset: ErrorPromise,
  resetPassword: ErrorPromise,
  register: ErrorPromise,
})

export const UserContext = createContext<UserContextValue>({
  user: null,
})
