import { css } from '@emotion/react'

import { media } from 'src/theme/breakpoints'

export const sessionListCss = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  ${media.desktop} {
    grid-template-columns: 0.4fr;
  }

  * > {
    grid-column: 1/-1;
  }
`
