/**
 * Utility CSS which moves an element off the screen to make it invisible
 * without removing it from the a11y tree.
 * You should use this class very rarely in favour of `visuallyHiddenCss`
 * to avoid hidden elements being focused.
 * */
import { css } from '@emotion/react'

export const forcedVisuallyHiddenCss = css`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`

/**
 * Utility CSS which moves an element off the screen to make it invisible
 * without removing it from the a11y tree.
 * These styles are only applied to non-active and non-focused elements
 * to prevent confusion over focused but invisible elements.
 * */
export const visuallyHiddenCss = css`
  &:not(:focus):not(:active) {
    ${forcedVisuallyHiddenCss}
  }
`

export const fullHeightCss = css`
  // to be placed in another flex container
  // --> take remaining space
  flex-grow: 1;
  // allow children to do the same
  // --> define flex container
  display: flex;
`

/**
 * Style util for text that should break works, but only if
 * there are no spaces left to be wrapped.
 * */
export const wrapTextCss = css`
  hyphens: auto;
  // show "..." if breaking word is impossible
  overflow: hidden;
  text-overflow: ellipsis;
`
