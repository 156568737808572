import { css } from '@emotion/react'

import { theme } from 'src/theme'
import { media } from 'src/theme/breakpoints'
import { colors } from 'src/theme/colors'
import { defaultFocusCss, defaultTransitionCss } from 'src/theme/utils'

export const wrapperCss = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.tablet} {
    display: grid;
    align-items: center;
    grid-column-gap: 2.4rem;
    grid-template-columns: 1fr auto 1fr;
  }
`

export const navLinkCss = css`
  ${defaultFocusCss};
  ${defaultTransitionCss};
  transition-property: color;
  display: block;
  padding: 0.4rem;
  border-radius: ${theme.radii.md};

  &:hover {
    text-decoration: none;
    color: ${colors.custom.blueLight};
  }

  &.active {
    color: ${colors.custom.blueLight};
  }
`
