import { colors } from './colors'

export const layerStyles = {
  elevated: {
    boxShadow: '1.3rem 0.6rem 2.6rem 0 rgba(36, 137, 177, 0.07)',
    border: `solid 0.1rem ${colors.gray['100']}`,
    backgroundColor: colors.white,
  },
  infoBox: {
    border: `0.1rem solid ${colors.blue['50']}`,
    borderLeftWidth: `0.7rem`,
  },
}
