import { TFunction } from 'react-i18next'

import { routes } from 'src/common/routes'

export const NAV_ITEMS = (t: TFunction): { title: string; link: string }[] => [
  {
    title: t('common:mainNavigation.home'),
    link: routes.home,
  },
  {
    title: t('common:mainNavigation.events'),
    link: '/events',
  },
  {
    title: t('common:mainNavigation.blog'),
    link: '/blog',
  },
]

export const FOOTER_NAV_ITEMS = (
  t: TFunction,
): { title: string; link: string }[] => [
  {
    title: t('footerNavigation.imprint'),
    link: routes.impressum,
  },
  {
    title: t('footerNavigation.dataPolicy'),
    link: routes.datenschutz,
  },
  {
    title: t('footerNavigation.about'),
    link: routes.about,
  },
]
