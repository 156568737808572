import { chakra, Stack, StyleProps } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdSchedule } from 'react-icons/md'

import { DateFormat, dateTimeFormat } from 'src/common/utils/date.utils'
import { MatchingEventNode } from 'src/graphql/__generated__/types'

import { containerCss } from './DeadlineInfo.styles'

export interface DeadlineInfo extends Omit<StyleProps, 'apply'> {
  matchingEvent: Pick<
    MatchingEventNode,
    | 'subscriptionDeadline'
    | 'matchingRequestDeadline'
    | 'startDate'
    | 'allowOverlappingDates'
  >
}

export const DeadlineInfo = ({
  matchingEvent,
  ...styleProps
}: DeadlineInfo): React.ReactElement => {
  const { t, i18n } = useTranslation()

  const [subscriptionDeadlineDate, subscriptionDeadlineTime] = useMemo<
    [string, string, string, string]
  >(() => {
    const formatDate = (dateString: string) =>
      dateTimeFormat(
        new Date(dateString),
        DateFormat.DateNumericWithYear,
        i18n.language,
      )
    const formatTime = (dateString: string) =>
      dateTimeFormat(new Date(dateString), DateFormat.Time, i18n.language)
    return [
      formatDate(matchingEvent.subscriptionDeadline),
      formatTime(matchingEvent.subscriptionDeadline),
      formatDate(matchingEvent.matchingRequestDeadline),
      formatTime(matchingEvent.matchingRequestDeadline),
    ]
  }, [i18n.language, matchingEvent])

  return (
    <chakra.div css={containerCss} layerStyle={'infoBox'} {...styleProps}>
      <chakra.svg
        as={MdSchedule}
        aria-hidden
        color={'custom.blueLight'}
        boxSize={'6.5rem'}
        display={'block'}
      />
      <Stack spacing={'1.2rem'} overflow={'hidden'} color={'gray.500'}>
        <p>
          {t('common:matchingEvent.subscriptionDeadlineExpiresAt', {
            date: subscriptionDeadlineDate,
            time: subscriptionDeadlineTime,
          })}
        </p>
        <p>{t('common:matchingEvent.matchingRequestDeadline')}</p>
      </Stack>
    </chakra.div>
  )
}
