import { TFunction } from 'i18next'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const passwordOptions = (t: TFunction): RegisterOptions => ({
  required: t('common:errors.missingRequired') as string,
  minLength: {
    value: 8,
    message: t('common:errors.passwordMinLength') as string,
  },
  validate: {
    hasSpecialChar: (value) =>
      /.*(?=.*?[#?!@$%^&*_-]).*/.test(value)
        ? true
        : (t('common:errors.passwordMissingSpecialCharacters') as string),
    hasNumber: (value) =>
      /.*(?=.*?[0-9]).*/.test(value)
        ? true
        : (t('common:errors.passwordMissingNumber') as string),
    hasUpperLetter: (value) =>
      /.*(?=.*?[A-Z]).*/.test(value)
        ? true
        : (t('common:apiErrors.password_has_no_capital_letters') as string),
    hasLowerLetter: (value) =>
      /.*(?=.*?[0-9]).*/.test(value)
        ? true
        : (t('common:errors.passwordMissingNumber') as string),
  },
})
