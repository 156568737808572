export default {
  greetings: 'Beste Grüße vom EvilBeard Team',
  skipLinkLabel: 'Zum Hauptinhalt springen',
  mainAnchorLabel: 'Hauptinhalt',
  notFound: 'Fehler | 404',
  noResultsFound: 'Keine Ergebnisse gefunden',
  resetFilter: 'Filter zurücksetzen',
  you: 'Sie',
  hint: 'Hinweis',
  showMore: 'Mehr anzeigen',
  showLess: 'Weniger anzeigen',
  loadMore: 'Weitere laden',
  back: 'Zurück',
  mainNavigation: {
    label: 'Hauptnavigation',
    home: 'Startseite',
    events: 'Events',
    blog: 'Blog',
    close: 'Hauptnavigation schließen',
    open: 'Hauptnavigation öffnen',
    menu: 'Menü',
  },
  userMenu: {
    label: 'Nutzer-Menü',
    profile: 'Profil',
    logout: 'Ausloggen',
  },
  footerNavigation: {
    label: 'Footer Navigation',
    imprint: 'Impressum',
    dataPolicy: 'Datenschutz',
    about: 'Über VDI/VDE-IT',
  },
  nextMatchLogo: 'nextmatch Logo',
  login: 'Login',
  register: 'Registrieren',
  email: 'E-Mail',
  contacts: 'Kontakte',
  socialMedia: 'Social Media',
  password: 'Passwort',
  error: 'Fehler',
  cancel: 'Abbrechen',
  close: 'Schließen',
  clearInput: 'Eingabe löschen',
  oClock: 'Uhr',
  save: 'Speichern',
  edit: 'Bearbeiten',
  backToLogin: 'Zurück zum Login',
  auth: {
    showPassword: 'Passwort anzeigen',
    hidePassword: 'Passwort ausblenden',
  },
  registerPage: {
    heading: 'Registrieren',
    subHeading:
      'Herzlich willkommen bei nextmatch! ' +
      'Bitte registrieren Sie sich mit Ihrer E-Mail-Adresse und einem sicheren Passwort.',
    doRegister: 'Zustimmen und Anmelden',
  },
  accountActivation: {
    heading: 'Bitte bestätigen Sie Ihre E-Mail-Adresse.',
    subHeading:
      'Wir senden Ihnen in Kürze eine E-Mail mit einem Aktivierungslink. Prüfen Sie auch Ihren Spam-Ordner, falls Sie keine E-Mail erhalten.',
  },
  loginPage: {
    title: 'Login',
    subHeading:
      'Loggen Sie sich ein, um sich zu Events anzumelden und neue Kontakte zu finden.',
    doLogin: 'Anmelden',
    doLogout: 'Abmelden',
    rememberMe: 'Angemeldet bleiben',
    forgotPassword: 'Passwort vergessen?',
  },
  errors: {
    unknown: 'Ein unbekannter Fehler ist aufgetreten.',
    missingRequired: 'Bitte füllen Sie dieses Feld aus.',
    invalidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    passwordMinLength: 'Das Passwort muss mindestens acht Zeichen lang sein.',
    passwordMissingSpecialCharacters:
      'Das Passwort muss mindestens ein Sonderzeichen enthalten.',
    passwordMissingNumber: 'Das Passwort muss mindestens eine Zahl enthalten.',
    passwordMissingLetter:
      'Das Passwort muss mindestens einen Buchstaben enthalten.',
    inputTooLong: 'Die Eingabe ist zu lang.',
    moreThanEightTopics: 'Es können maximal acht Themen ausgewählt werden.',
    networkError:
      'Es ist ein Netzwerkfehler aufgetreten. Bitte überprüfen Sie Ihre Verbindung',
    fileCompressingError:
      'Etwas ist schiefgelaufen. Wähle bitte ein anderes Bild.',
    exceedingInputLimit: 'Es können nur maximal 450 Zeichen eingegeben werden',
    atLeastOneTopic: 'Bitte fügen sie mindestens ein Element hinzu',
    missingRequiredToast:
      'Sie haben nicht alle Informationen angegeben, die notwendig zur Teilnahme an Matching Events sind.',
    invalidPhoneInput:
      "Die Telefonnummer muss in folgendem Format angegeben werden: '+999999999'.",
  },
  apiErrors: {
    not_authenticated:
      'Sie scheinen nicht mehr eingeloggt zu sein. Bitte laden Sie die Seite erneut.',
    user_not_active:
      'Dieser Account wurde noch nicht aktiviert.\n' +
      'Sie haben nach Ihrer Registrierung eine E-Mail mit einem Aktivierungslink erhalten (bitte prüfen Sie auch Ihren Spam-Ordner). Nach klicken des Aktivierungslinks in der E-Mail können Sie ihren Account nutzen.',
    validation_error:
      'Leider ist bei der Prüfung Ihrer Eingabe ein Fehler aufgetreten.',
    invalid_credentials:
      'Diese Kombination aus E-Mail und Passwort ist ungültig.',
    password_too_short: 'Das Passwort muss mindestens acht Zeichen lang sein.',
    password_too_common: 'Dieses Passwort ist zu schwach.',
    email_unique: 'Mit dieser E-Mail-Adresse ist schon jemand registriert.',
    password_has_no_small_letters:
      'Das Passwort muss mindestens einen Kleinbuchstaben enthalten.',
    password_has_no_capital_letters:
      'Das Passwort muss mindestens einen Großbuchstaben enthalten.',
    password_has_no_special_characters:
      'Das Passwort muss mindestens ein Sonderzeichen enthalten.',
    activation_link_invalid: 'Ungültiger Aktivierungslink',
    reset_password_link_invalid:
      'Dieser Link zum Zurücksetzen des Passworts ist ungültig oder abgelaufen.',
    matching_event_does_not_exist:
      'Dieses Event existiert nicht mehr. Bitte laden Sie die Seite neu.',
    user_profile_not_complete:
      'Es scheint, dass Ihr Profil nicht vollständig ist. Aktualisieren Sie es jetzt, um fortzufahren.',
    invalid_topics_input:
      'Diese Auswahl an Themen ist ungültig. Entweder enthält sie Duplikate oder veraltete Einträge. ' +
      'Bitte prüfen Sie Ihre Angaben.',
    subscription_deadline_is_passed:
      'Die Anmeldefrist für dieses Event ist bereits abgelaufen.',
    matching_request_phase_not_started:
      'Sie können Ihre Gesprächswünsche noch nicht abgeben. Dies ist möglich, sobald die Anmeldefrist abgelaufen ist.',
    matching_request_phase_is_passed:
      'Die Frist für die Abgabe der Gesprächswünsche ist leider schon abgelaufen.',
    requested_user_not_participant:
      'Der/die Benutzer:in nimmt an diesem Event nicht mehr teil, bitte laden Sie die Seite neu.',
    current_user_not_participant:
      'Sie nehmen nicht mehr an diesem Event teil, bitte laden Sie die Seite neu.',
  },
  registerTermsAndConditions:
    'Durch Klicken auf „Zustimmen und Anmelden“ stimmen Sie der <1>Nutzungsvereinbarung</1>, der <3>Datenschutzrichtlinie</3> und der <5>Cookie-Richtlinie</5> von nextmatch zu.',
  myProfile: {
    profile: 'Profil',
    generalInfo: 'Allgemeine Informationen',
    deleteAccount: 'Konto löschen',
    matchingCriteria: 'Matching-Kriterien',
    noInput: 'Keine Angabe',
    visibleToPublic: 'Öffentlich sichtbar',
    visibleToMatches: 'Für Matches sichtbar',
    demandedTopics: 'Ich suche',
    offeredTopics: 'Ich biete',
    deleteAccountDialog:
      'Sollte ein Problem mit Ihrem nextmatch-Konto bestehen, kontaktieren Sie bitte das Support-Team von nextmatch via nextmatch@vdivde-it.de. \n\n Falls Sie Ihr nextmatch-Konto und alle damit verbundenen Informationen unwiderruflich löschen möchten, klicken Sie bitte auf "Konto löschen". Wir werden Sie vermissen und freuen uns auf ein Wiedersehen!',
    showEmailText: 'Meine aktuelle E-Mail für andere Nutzer:innen anzeigen',
  },
  userProfile: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    title: 'Titel',
    gender: 'Geschlecht',
    organisation: 'Organisation',
    aboutMe: 'Über mich',
    job: 'Position',
    phone: 'Telefonnummer',
    showEmail: 'E-Mail',
    twitter: 'Twitter',
    linkedIn: 'LinkedIn',
    xing: 'Xing',
  },
  gender: {
    M: 'Männlich',
    F: 'Weiblich',
    D: 'Divers',
  },
  salutation: {
    M: 'Herr',
    F: 'Frau',
  },
  matchingEvent: {
    events: 'Events',
    startAndEndTime: '{{startTime}} bis {{endTime}} Uhr',
    showDetails: 'Details anzeigen',
    exclusive: 'Exklusives Event',
    datetimePlaceFormat: '{{date}} • {{time}} Uhr • {{city}}',
    doSubscribe: 'Zu Event anmelden',
    doUnsubscribe: 'Von Event abmelden',
    notCompletedProfileHint:
      'Bitte vervollständigen Sie zuerst die Angaben in Ihrem Profil, damit Sie sich für dieses Event anmelden können.',
    unsubscribeConfirm: 'Abmeldung bestätigen',
    unsubscribeFromEvent: 'Vom Event abmelden',
    unsubscribeFromEventInfo:
      'Mit Klicken auf „Abmeldung bestätigen“ melden Sie sich von diesem Event ab. ' +
      'Wenn Sie bereits Matching-Anfragen gestellt haben, werden auch diese gelöscht.',
    participants: 'Teilnehmende',
    requestMeetings: 'Teilnehmende anzeigen',
    requestMeetingsInfoText: 'Hier können Sie auch Gespräche anfragen',
    loginToSeeOtherParticipants:
      'Bitte melden Sie sich zuerst an, um andere Teilnehmende zu sehen',
    backToMatchingEventDetails: 'Zurück zum Event',
    matchingResults: 'Passend zur Suche',
    searchParticipants: 'Passende Teilnehmende finden',
    subscriptionDeadlineExpiresAt:
      'Die Anmeldefrist endet am {{date}} um {{time}} Uhr.',
    matchingRequestDeadlineFromUntil:
      'Im Zeitraum vom {{fromDate}} bis zum {{untilDate}} um {{untilTime}} Uhr können Sie ihre Gesprächswünsche abgeben. Nach Ablauf der Frist erhalten Sie Ihren personalisierten Meeting-Plan.',
    matchingRequestDeadlineWithOverlap:
      'Sie können Ihre Gesprächswünsche bis zum {{untilDate}} um {{untilTime}} Uhr abgeben. Nach Ablauf dieser Frist erhalten Sie Ihren personalisierten Meeting-Plan.',
    matchingRequestDeadline:
      'Sie können bis zum Eventbeginn Gesprächswünsche senden. Ihren personalisierten Gesprächsplan erhalten Sie in vorläufiger Form in Kürze hier und in finaler Form zu Beginn der Veranstaltung.',
    passphrase: {
      default: 'Passwort',
      passphraseRequired:
        'Dieses exklusive Event ist eingeladenen Gästen vorbehalten. Bitte geben Sie das Passwort ein, das Sie mit der Einladung erhalten haben, um zu den Event-Details und zur Registrierung zu gelangen.',
      submitPassphrase: 'Passwort senden',
    },
  },
  participantList: {
    requestMeeting: 'Gespräch anfragen',
    sendMatchingRequest: 'Anfrage an {{participantName}} senden',
    sendMatchingRequestConfirm:
      'Wenn {{participantName}} ebenfalls an einem Gespräch interessiert ist, wird im Rahmen dieses Events ein gemeinsamer Zeitslot generiert, in dem die Möglichkeit eines Kennenlernens besteht.',
    sendRequest: 'Anfrage senden',
    cancelRequest: 'Anfrage zurückziehen',
    rejectRequest: 'Gesprächsanfrage ablehnen',
    bidirectionalRequest: 'Diese Person hat Sie ebenfalls angefragt.',
    group: 'Gruppe',
    filter: {
      label: 'Filtern nach',
      groupLabel: 'Filtern nach Gruppen',
      all: 'Alle anzeigen',
      requestFromUser: 'Angefragte Teilnehmende',
      requestToUser: 'Anfragende Teilnehmende',
    },
  },
  schedule: {
    schedule: 'Event-Stundenplan',
    temporarySchedule: 'Vorläufiger Gesprächsplan',
    temporaryScheduleInfo:
      'Hier sehen Sie Ihren vorläufigen Gesprächsplan. Dieser basiert auf den bisher gesendeten und empfangenen Gesprächsanfragen. Bitte beachten Sie, dass sich der Gesprächsplan bis zum Veranstaltungsbeginn noch verändern kann.',
    noSessions:
      'Ihr Stundenplan ist leer. Wenn Sie denken, dass dies ein Fehler ist, kontaktieren Sie bitte das nextmatch Support Team: nextmatch@vdivde-it.de',
    sessionSlot: '{{startTime}} bis {{endTime}} • Tisch {{tableNumber}}',
    match: 'Match',
    requestFromCurrentUser: 'Initiiert von Ihnen',
    currentUserRequested: 'Initiiert von {{fullName}}',
    cancelSession: 'Gespräch absagen',
  },
  requestNewPassword: {
    passwordResetEmailSend: 'E-Mail versendet',
    passwordResetEmailSendDescription:
      'Eine E-Mail zum Zurücksetzten des Passworts wurde versendet. Bitte prüfen Sie Ihren Posteingang.',
    heading: 'Passwort vergessen',
    subHeading:
      'Geben Sie hier Ihre E-Mail-Adresse ein, damit wir Ihnen einen Link zum Zurücksetzen des Passworts senden können.',
    sendRequest: 'Link zum Zurücksetzen schicken',
  },
  resetPassword: {
    heading: 'Passwort zurücksetzen',
    subHeading:
      'Geben Sie bitte ein neues Passwort an, das zukünftig für Ihren Login eingesetzt werden soll.',
    newPassword: 'Neues Passwort',
    saveNewPassword: 'Neues Passwort speichern',
    passwordReset: 'Passwort wurde zurückgesetzt',
    passwordResetConfirmation:
      'Sie können sich jetzt mit dem neuen Passwort anmelden.',
  },
  languageMenu: {
    label: 'Sprachen-Menü',
    german: 'Deutsch',
    english: 'Englisch (UK/US)',
  },
}
