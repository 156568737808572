import {
  Alert,
  AlertDescription,
  AlertIcon,
  CloseButton,
  Stack,
  StyleProps,
} from '@chakra-ui/react'
import { Dispatch, SetStateAction } from 'react'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { UseFormSetFocus } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'

export interface NonFieldErrors extends Pick<StyleProps, 'marginTop'> {
  errors: string[]
  setErrors: Dispatch<SetStateAction<string[]>>
  setFocus: UseFormSetFocus<FieldValues>
  focusOnClose: string
}

export const NonFieldErrors = ({
  errors,
  setErrors,
  setFocus,
  focusOnClose,
  ...styleProps
}: NonFieldErrors): React.ReactElement | null => {
  const { t } = useTranslation()
  return errors.length > 0 ? (
    <Stack {...styleProps} spacing={'0.6rem'}>
      {errors.map((errorCode) => (
        <Alert key={errorCode} status={'error'}>
          <AlertIcon key={errorCode} />
          <AlertDescription>
            {t([`common:apiErrors.${errorCode}`, 'common:errors.unknown'])}
          </AlertDescription>
          <CloseButton
            onClick={() => {
              setErrors((prev) => prev.filter((code) => code != errorCode))
              setFocus(focusOnClose)
            }}
          />
        </Alert>
      ))}
    </Stack>
  ) : null
}
