import { Button, ButtonProps, chakra, StyleProps } from '@chakra-ui/react'
import React, { FormEvent, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { gridCss } from './FormSection.styles'

export const SubmitEditButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { isEdit: boolean }
>(({ isEdit, ...props }, ref) => {
  const { t } = useTranslation()
  return (
    <Button
      {...props}
      type={isEdit ? 'submit' : 'button'}
      width={{ base: '100%', md: 'auto' }}
      variant={'secondary'}
      ref={ref}
    >
      {isEdit ? t('common:save') : t('common:edit')}
    </Button>
  )
})

export interface FormSection extends Omit<StyleProps, 'apply'> {
  prefix: string
  title: string
  isEdit: boolean
  handleButtonClick: (e: MouseEvent) => void
  buttonRef: React.RefObject<HTMLButtonElement>
  children?: React.ReactNode
  onSubmit: (e: FormEvent) => void
  loading: boolean
}

export const FormSection = ({
  prefix,
  title,
  isEdit,
  children,
  handleButtonClick,
  buttonRef,
  onSubmit,
  loading,
  ...styleProps
}: FormSection): React.ReactElement => {
  const { t } = useTranslation()
  const titleId = `${prefix}__title`
  return (
    <chakra.section {...styleProps} aria-labelledby={titleId}>
      <chakra.form css={gridCss} onSubmit={onSubmit}>
        <chakra.header gridArea={'title'}>
          <chakra.h2 id={titleId} textStyle={'h4'} color={'custom.blueDark'}>
            {title}
          </chakra.h2>
        </chakra.header>
        <chakra.div gridArea={'content'}>{children}</chakra.div>
        <Button
          gridArea={'action'}
          type={isEdit ? 'submit' : 'button'}
          width={{ base: '100%', md: 'auto' }}
          variant={'secondary'}
          ref={buttonRef}
          onClick={handleButtonClick}
          isLoading={loading}
        >
          {isEdit ? t('common:save') : t('common:edit')}
        </Button>
      </chakra.form>
    </chakra.section>
  )
}
