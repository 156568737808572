import { css } from '@emotion/react'

import { media } from 'src/theme/breakpoints'
import { defaultGridCss, maxWidthCss } from 'src/theme/layout'

export const containerCss = css`
  ${maxWidthCss}
  ${defaultGridCss}
  
  padding: 3.2rem 2.4rem;
  ${media.md} {
    padding: 3.5rem 6.2rem 4.8rem 4.8rem;
  }
  display: flex;
  flex-direction: column;
`
