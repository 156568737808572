import { Badge, chakra, Wrap, WrapItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { RichText } from 'src/common/components/RichText'
import { DateFormat, dateTimeFormat } from 'src/common/utils/date.utils'
import { BlogArticleType, TopicType } from 'src/graphql/__generated__/types'

export type BlogArticle = Omit<BlogArticleType, 'topics'> & {
  topics: Pick<TopicType, 'name'>[]
}

export type BlogArticleListItem = BlogArticle

export const BlogArticleListItem = (
  blogArticle: BlogArticleListItem,
): React.ReactElement => {
  const { i18n } = useTranslation()

  const titleId = `blog_article_list_item_${blogArticle.id}__title`

  const createdAtString = dateTimeFormat(
    new Date(blogArticle.createdAt),
    DateFormat.DateWithYear,
    i18n.language,
  )

  const topicsString = blogArticle.topics.map(({ name }) => name).join(', ')

  return (
    <li>
      <chakra.article
        layerStyle={'elevated'}
        aria-labelledby={titleId}
        padding={{ base: '3.2rem', md: '4.8rem' }}
      >
        <header>
          <Wrap spacing={'1.2rem'}>
            <WrapItem>
              <Badge colorScheme={'blue'} color={'custom.blueDark'}>
                {blogArticle.category}
              </Badge>
            </WrapItem>
            {blogArticle.region && (
              <WrapItem>
                {' '}
                <Badge>{blogArticle.region}</Badge>
              </WrapItem>
            )}
          </Wrap>
          <chakra.p textStyle={'small'} color={'gray.500'} mt={'4.8rem'}>
            {createdAtString}
          </chakra.p>

          <chakra.h2
            id={titleId}
            textStyle={'h3'}
            color={'custom.blueDark'}
            mt={'2.4rem'}
          >
            {blogArticle.title}
          </chakra.h2>
          {topicsString && (
            <chakra.p textStyle={'small'} mt={'1.2rem'}>
              {topicsString}
            </chakra.p>
          )}
        </header>
        <RichText html={blogArticle.body} mt={'3.2rem'} />
      </chakra.article>
    </li>
  )
}
