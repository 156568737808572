import { chakra, HStack, SkeletonCircle, Skeleton } from '@chakra-ui/react'
import React from 'react'

import { sessionItemCss } from './SessionListItem.styles'

export const SessionListItemSkeleton = (): React.ReactElement => {
  return (
    <chakra.li css={sessionItemCss} bg={'custom.blueFill'}>
      <Skeleton width={'50%'} height={'1.4rem'} />
      <HStack mt={'2.4rem'} spacing={'1.6rem'}>
        <SkeletonCircle size="10" />
        <chakra.div flexGrow={1}>
          <Skeleton width={'50%'} height={'1.4rem'} />
          <Skeleton width={'50%'} height={'1.4rem'} mt={'1rem'} />
        </chakra.div>
      </HStack>
    </chakra.li>
  )
}
