import { Skeleton, chakra, SkeletonText } from '@chakra-ui/react'
import React from 'react'

import { contentColumnNormalCss, defaultSectionCss } from 'src/theme/layout'

export type StaticPageSkeleton = Record<string, never>

export const StaticPageSkeleton = (): React.ReactElement => {
  return (
    <chakra.div
      css={defaultSectionCss}
      paddingTop={'4.8rem'}
      paddingBottom={{ base: '15rem', md: '7.8rem' }}
    >
      <chakra.div css={contentColumnNormalCss}>
        <Skeleton height={'4.4rem'} maxWidth={'20rem'} />
        <SkeletonText
          skeletonHeight={'1.4rem'}
          marginTop={'4.8rem'}
          noOfLines={100}
          spacing={4}
        />
      </chakra.div>
    </chakra.div>
  )
}
