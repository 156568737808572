import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  InMemoryCacheConfig,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import cookie from 'cookie'

import { handleError } from 'src/common/utils/error.utils'

import config from './config'

const { apiUrl } = config

const GRAPHQL_URL = apiUrl + 'graphql/'
const CSRF_URL = apiUrl + 'csrf/'

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        // Use relay-style pagination for paginated queries.
        // Two results belong to be different lists,
        // if one of the keyArg variables changes
        blogArticles: relayStylePagination(),
      },
    },
  },
}

const httpUploadLink = (createUploadLink({
  uri: GRAPHQL_URL,
}) as unknown) as ApolloLink

const getCsrfToken = async (): Promise<string> => {
  // pass CSRF token from cookie to request header or fetch one if not exist
  let csrfToken = cookie.parse(document.cookie).csrftoken
  if (!csrfToken) {
    await fetch(CSRF_URL, { credentials: 'include' })
    csrfToken = cookie.parse(document.cookie).csrftoken
  }
  return csrfToken
}

const authLink = setContext(async (_, { headers }) => {
  return {
    credentials: 'include',
    headers: {
      ...headers,
      'X-CSRFToken': await getCsrfToken(),
      'Accept-Language': localStorage.getItem('i18nextLng'),
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => handleError(error))
  }
  if (networkError) {
    console.error(`[Network error]`, networkError)
  }
})

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(cacheOptions),
  link: ApolloLink.from([errorLink, authLink, httpUploadLink]),
})
