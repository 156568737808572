import { css } from '@emotion/react'

import { textStyles } from './typography'

export const textStylesCss = {
  h1: css`
    font-size: ${textStyles.h1.fontSize};
    line-height: ${textStyles.h1.lineHeight};
    font-weight: ${textStyles.h1.fontWeight};
  `,
  h2: css`
    font-size: ${textStyles.h2.fontSize};
    line-height: ${textStyles.h2.lineHeight};
    font-weight: ${textStyles.h2.fontWeight};
  `,
  h3: css`
    font-size: ${textStyles.h3.fontSize};
    line-height: ${textStyles.h3.lineHeight};
    font-weight: ${textStyles.h3.fontWeight};
  `,
  h4: css`
    font-size: ${textStyles.h4.fontSize};
    line-height: ${textStyles.h4.lineHeight};
    font-weight: ${textStyles.h4.fontWeight};
  `,
}
