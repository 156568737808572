import {
  InputGroup,
  Input,
  InputRightElement,
  InputProps,
  chakra,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

import { visuallyHiddenCss } from 'src/common/utils/style.utils'
import { defaultFocusCss } from 'src/theme/utils'

export type PasswordInput = InputProps

export const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInput>(
  (props, ref) => {
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show)
    const { t } = useTranslation()
    return (
      <InputGroup size={'md'}>
        <Input
          ref={ref}
          paddingRight={'2.1rem'}
          type={show ? 'text' : 'password'}
          {...props}
        />
        <InputRightElement
          width={'4.5rem'}
          color={
            props.isDisabled
              ? 'custom.disabled'
              : props.isInvalid
              ? 'custom.error'
              : 'gray.400'
          }
        >
          <chakra.button
            type={'button'}
            css={defaultFocusCss}
            onClick={handleClick}
            disabled={props.isDisabled}
          >
            {show ? (
              <chakra.span css={visuallyHiddenCss}>
                {t('common:auth.hidePassword')}
              </chakra.span>
            ) : (
              <chakra.span css={visuallyHiddenCss}>
                {t('common:auth.showPassword')}
              </chakra.span>
            )}
            {show ? (
              <AiOutlineEyeInvisible fontSize={'2.4rem'} aria-hidden />
            ) : (
              <AiOutlineEye fontSize={'2.4rem'} aria-hidden />
            )}
          </chakra.button>
        </InputRightElement>
      </InputGroup>
    )
  },
)
