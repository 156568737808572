export const Checkbox = {
  sizes: {
    md: {
      control: {
        h: '1.4rem',
        w: '1.4rem',
      },
      icon: {
        fontSize: '1rem',
      },
    },
  },
}
