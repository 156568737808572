import { css } from '@emotion/react'

import { theme } from 'src/theme'
import { defaultFocusCss, defaultTransitionCss } from 'src/theme/utils'

export const containerCss = {
  base: css`
    min-height: 4rem;
    display: flex;
    align-items: center;
    padding: 0.8rem 1.8rem;
    border-radius: 2rem;
    position: relative;
  `,
  deletable: css`
    padding-right: 4.2rem;
  `,
  yellow: css`
    background: ${theme.colors.custom.yellowDark};
    color: ${theme.colors.custom.yellowButton};
  `,
  blue: css`
    background: ${theme.colors.custom.blueDark};
    color: ${theme.colors.white};
  `,
  gray: css`
    background: ${theme.colors.gray['600']};
    color: ${theme.colors.white};
    padding: 1.2rem 1.8rem;
  `,
  outline: css`
    color: ${theme.colors.gray['600']};
    box-shadow: inset 0 0 0 0.1rem currentColor;
  `,
}

export const deleteButtonCss = {
  base: css`
    ${defaultFocusCss};
    ${defaultTransitionCss};
    transition-property: background, color, box-shadow;

    position: absolute;
    right: 0.4rem;
    top: 0.4rem;

    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    border-radius: 2rem;
  `,
  yellow: css`
    &:hover {
      background: ${theme.colors.custom.yellowButton};
      color: ${theme.colors.custom.yellowDark};
    }
  `,
  blue: css`
    &:hover {
      background: ${theme.colors.custom.blueDark};
      color: ${theme.colors.white};
    }
  `,
  gray: css`
    &:hover {
      background: ${theme.colors.gray['600']};
      color: ${theme.colors.white};
    }
  `,
  outline: css`
    &:hover {
      background: ${theme.colors.gray['600']};
      color: ${theme.colors.white};
    }
  `,
}
