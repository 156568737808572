import { chakra, Stack, IconButton } from '@chakra-ui/react'
import React, { FormEvent, useEffect, useRef } from 'react'
import { MdModeEdit, MdClear } from 'react-icons/md'

import { User } from 'src/auth/types'
import { ApolloErrorMessage } from 'src/common/components/Error'
import {
  forcedVisuallyHiddenCss,
  wrapTextCss,
} from 'src/common/utils/style.utils'
import { useUploadProfileImageMutation } from 'src/graphql/__generated__/types'
import { useCompressedImage } from 'src/userprofile/components/UploadProfileImage/useCompressedImage'
import { UserAvatar } from 'src/userprofile/components/UserAvatar'
import { getUserName } from 'src/userprofile/utils'

export interface ImageUpload {
  user: User
}

export const ImageUpload = ({ user }: ImageUpload): React.ReactElement => {
  const uploadElementId = 'image_upload_input'
  const [
    uploadRemoveImage,
    { error, loading },
  ] = useUploadProfileImageMutation()
  const uploadInputRef = useRef<HTMLInputElement>(null)
  const editButtonRef = useRef<HTMLButtonElement>(null)
  const {
    compressing,
    image: compressedImage,
    updateImage,
    error: compressionError,
  } = useCompressedImage()

  const handleUpload = (e: FormEvent<HTMLInputElement>) => {
    const files = e.currentTarget?.files
    if (files) {
      const image = files[0]
      updateImage(image)
      // reset input value to be able to select the same image again after deletion
      // https://github.com/ngokevin/react-file-reader-input/issues/11
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      e.target.value = null
    }
  }
  useEffect(() => {
    if (compressedImage) {
      uploadRemoveImage({
        variables: { profileImage: compressedImage },
      }).finally(() => editButtonRef?.current?.focus())
    }
  }, [uploadRemoveImage, compressedImage])

  const handleEditClick = () => {
    uploadInputRef?.current?.click()
  }
  const handleRemove = () => {
    uploadRemoveImage().finally(() => editButtonRef?.current?.focus())
  }
  return (
    <>
      <Stack
        spacing={{ md: '4.6rem' }}
        direction={{ base: 'column', md: 'row' }}
        align={'center'}
      >
        <chakra.div position={'relative'}>
          <UserAvatar
            boxSize={'11.1rem'}
            userProfile={user?.profile}
            bg={'gray.300'}
          />
          <chakra.input
            onChange={handleUpload}
            accept={'image/*'}
            id={uploadElementId}
            type={'file'}
            css={forcedVisuallyHiddenCss}
            aria-invalid={!!error}
            ref={uploadInputRef}
            tabIndex={-1}
          />
          <IconButton
            onClick={handleEditClick}
            position={'absolute'}
            top={-1}
            right={-1}
            isRound={true}
            colorScheme="blue"
            aria-label="Upload profile image"
            icon={<MdModeEdit fontSize={'2rem'} />}
            ref={editButtonRef}
            variant={'secondary'}
            cursor={'pointer'}
            isLoading={loading || compressing}
          />
          {user?.profile?.profileImage && (
            <IconButton
              onClick={handleRemove}
              position={'absolute'}
              bottom={-1}
              right={-1}
              isRound={true}
              colorScheme="red"
              aria-label="Remove profile image"
              icon={<MdClear fontSize={'2rem'} />}
              variant={'secondary'}
              cursor={'pointer'}
              isLoading={loading || compressing}
            />
          )}
        </chakra.div>
        <chakra.div textAlign={{ base: 'center', md: 'unset' }}>
          <chakra.h2
            textStyle={'h2'}
            color={'custom.blueDark'}
            css={wrapTextCss}
          >
            {getUserName(user)}
          </chakra.h2>
          {user?.profile?.job && (
            <chakra.p textStyle={'h4'} color={'custom.blueDark'}>
              {user?.profile.job}
            </chakra.p>
          )}
        </chakra.div>
      </Stack>
      {error && <ApolloErrorMessage error={error} />}
      {compressionError && (
        <chakra.p textStyle={'small'} color={'custom.error'}>
          {compressionError}
        </chakra.p>
      )}
    </>
  )
}
