import { ChakraProvider } from '@chakra-ui/react'
import { Global } from '@emotion/react'
import 'focus-visible/dist/focus-visible'
import React from 'react'

import { theme } from './theme'
import { unitRoundedCss } from './typography'

export const ThemeProvider: React.FC = ({ children }) => (
  <ChakraProvider theme={theme}>
    <Global styles={unitRoundedCss} />
    {children}
  </ChakraProvider>
)
