import {
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  StyleProps,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useUser } from 'src/auth/hooks/useUser'
import { routes } from 'src/common/routes'
import { UserAvatar } from 'src/userprofile/components/UserAvatar'
import { getUserName } from 'src/userprofile/utils'

import { itemCss, userMenuButtonCss, userNameCss } from './UserMenu.styles'

export type UserMenu = StyleProps

export const UserMenu = (props: UserMenu): React.ReactElement | null => {
  const { t } = useTranslation()
  const { user, loading, logout } = useUser()
  const { isOpen, onClose, onToggle } = useDisclosure()

  const userName = loading
    ? '...'
    : user
    ? getUserName(user)
    : t('common:error')

  return (
    <Menu
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onToggle}
      placement={'bottom-end'}
      {...props}
    >
      <MenuButton
        isLoading={loading}
        as={Button}
        variant={'ghost'}
        css={userMenuButtonCss}
        aria-label={t('common:userMenu.label')}
      >
        <chakra.span
          css={userNameCss}
          mr={'1.2rem'}
          fontSize={'sm'}
          color={'custom.blueLight'}
        >
          {userName}
        </chakra.span>
        <UserAvatar userProfile={user?.profile} />
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} to={routes.profile} css={itemCss}>
          {t('common:userMenu.profile')}
        </MenuItem>
        <MenuItem onClick={() => logout()} css={itemCss}>
          {t('common:userMenu.logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
