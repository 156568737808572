import { css } from '@emotion/react'

import { media } from 'src/theme/breakpoints'

export const gridCss = css`
  display: grid;

  grid-row-gap: 3.2rem;
  grid-template:
    'title'
    'content'
    'action'
    / 1fr;

  ${media.md} {
    grid-row-gap: 2.8rem;
    grid-template:
      'title title'
      'content content'
      '. action'
      / 1fr auto;
  }
`
