import { chakra, StyleProps } from '@chakra-ui/react'
import React from 'react'

import { imageCss } from './HeroImage.styles'

export type HeroImage = Omit<StyleProps, 'apply'>

/**
 * The responsive images were generated with:
 * https://www.responsivebreakpoints.com/
 * Resolution: 300px - 1600px
 * Size step: 10KB
 * */
export const HeroImage = (styleProps: HeroImage): React.ReactElement => {
  return (
    <chakra.img
      sizes="(max-width: 1600px) 100vw, 1600px"
      srcSet="
        /assets/hero-bg-light/hero-bg-light_ie6yc7_c_scale,w_300.jpg 300w,
        /assets/hero-bg-light/hero-bg-light_ie6yc7_c_scale,w_1039.jpg 1039w,
        /assets/hero-bg-light/hero-bg-light_ie6yc7_c_scale,w_1600.jpg 1600w"
      src="/assets/hero-bg-light/hero-bg-light_ie6yc7_c_scale,w_1600.jpg"
      css={imageCss}
      aria-hidden
      {...styleProps}
    />
  )
}
