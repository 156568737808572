import { Flex, Image, chakra, StyleProps } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

const LogoText = (props: Omit<StyleProps, 'apply'>): React.ReactElement => {
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 172.131 28.462"
      {...props}
    >
      <path
        d="M.158 14.093C.158 12.119.12 10.5 0 8.922h3.08l.197 3.158h.078a7.025 7.025 0 016.317-3.593c2.644 0 6.75 1.58 6.75 8.132v11.408h-3.475V17.014c0-3.08-1.144-5.645-4.42-5.645a4.935 4.935 0 00-4.658 3.553 4.988 4.988 0 00-.236 1.618v11.487H.158zm23.84 5.013c.078 4.698 3.078 6.632 6.553 6.632a12.559 12.559 0 005.289-.987l.592 2.487a15.482 15.482 0 01-6.356 1.184c-5.88 0-9.394-3.868-9.394-9.632 0-5.763 3.394-10.303 8.96-10.303 6.237 0 7.895 5.488 7.895 9a13.248 13.248 0 01-.117 1.62zm10.184-2.487c.04-2.21-.907-5.644-4.815-5.644-3.513 0-5.053 3.236-5.33 5.644zm9.156-7.697l2.725 4.105a65.942 65.942 0 011.933 3.119h.12c.63-1.145 1.263-2.132 1.894-3.159l2.683-4.065h3.75l-6.511 9.237 6.709 9.868h-3.948l-2.802-4.302c-.75-1.106-1.381-2.171-2.053-3.316h-.078c-.631 1.145-1.303 2.171-2.014 3.316l-2.764 4.302h-3.828l6.79-9.75-6.473-9.355zm21.353-5.487v5.487h4.975v2.644h-4.975V21.87c0 2.368.672 3.711 2.606 3.711a7.703 7.703 0 002.014-.237l.158 2.605a8.514 8.514 0 01-3.08.474 4.812 4.812 0 01-3.75-1.46c-.987-1.026-1.342-2.725-1.342-4.975v-10.42h-2.961V8.921h2.96v-4.58z"
        fill="#ffce44"
      />
      <path
        d="M73.572 14.093c0-1.974-.04-3.592-.158-5.171h3.04L76.61 12h.12a6.582 6.582 0 016-3.514 5.645 5.645 0 015.406 3.83h.08a7.868 7.868 0 012.13-2.487 6.517 6.517 0 014.225-1.343c2.526 0 6.276 1.659 6.276 8.29v11.25h-3.395V17.211c0-3.671-1.342-5.88-4.144-5.88a4.498 4.498 0 00-4.106 3.156 5.714 5.714 0 00-.275 1.738v11.802h-3.395V16.58c0-3.04-1.344-5.25-3.988-5.25a4.747 4.747 0 00-4.303 3.474 4.785 4.785 0 00-.275 1.697v11.526h-3.395zm43.496 13.934l-.275-2.408h-.12a7.118 7.118 0 01-5.841 2.843 5.443 5.443 0 01-5.842-5.487c0-4.619 4.106-7.145 11.487-7.106v-.394c0-1.58-.434-4.421-4.342-4.421a9.521 9.521 0 00-4.975 1.42l-.789-2.29a11.857 11.857 0 016.277-1.697c5.842 0 7.262 3.988 7.262 7.817v7.144a26.949 26.949 0 00.317 4.58zm-.513-9.75c-3.79-.079-8.092.592-8.092 4.303a3.074 3.074 0 003.277 3.316 4.753 4.753 0 004.617-3.198 3.605 3.605 0 00.198-1.105zm12.986-14.842v5.487h4.973v2.644h-4.973V21.87c0 2.368.67 3.711 2.604 3.711a7.69 7.69 0 002.013-.237l.158 2.605a8.502 8.502 0 01-3.078.474 4.812 4.812 0 01-3.75-1.46c-.988-1.026-1.343-2.725-1.343-4.975v-10.42h-2.96V8.921h2.96v-4.58zm22.262 23.881a12.798 12.798 0 01-5.487 1.106c-5.763 0-9.513-3.908-9.513-9.75 0-5.882 4.025-10.145 10.263-10.145a11.606 11.606 0 014.815.987l-.79 2.684a8.05 8.05 0 00-4.025-.908c-4.382 0-6.75 3.237-6.75 7.224 0 4.422 2.842 7.145 6.631 7.145a9.882 9.882 0 004.264-.947zM155.867 0h3.473v11.922h.08a6.435 6.435 0 012.486-2.447 7.14 7.14 0 013.553-.988c2.566 0 6.672 1.58 6.672 8.172v11.368h-3.475V17.054c0-3.08-1.144-5.685-4.422-5.685a4.993 4.993 0 00-4.658 3.474 4.21 4.21 0 00-.236 1.658v11.526h-3.473z"
        fill="#004169"
      />
    </chakra.svg>
  )
}

export const Logo = (): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <Flex direction={'row'} align={'center'} mr={5}>
      <LogoText height={'2.7rem'} width={'auto'} mr={'0.8rem'} aria-hidden />
      <Image
        src={'/assets/next-match-logo-bubble.svg'}
        alt={t('common:nextMatchLogo')}
        height={'4rem'}
        width={'auto'}
      />
    </Flex>
  )
}
