import { Stack, useToast } from '@chakra-ui/react'
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ApolloErrorMessage } from 'src/common/components/Error'
import {
  useMyTopicsQuery,
  useUpdateMyTopicsMutation,
} from 'src/graphql/__generated__/types'
import {
  TopicMultiSelect,
  ItemType,
} from 'src/matching/components/TopicMultiSelect/TopicMultiSelect'
import { FormSection } from 'src/userprofile/components/FormSection'

export type MyTopicsForm = Record<string, never>

export const MyTopicsForm = (): React.ReactElement => {
  const { t } = useTranslation()

  const { data: userData } = useMyTopicsQuery()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const toast = useToast()

  const offeredTopics = userData?.me?.offeredTopics
  const demandedTopics = userData?.me?.demandedTopics

  const [selectedOfferedTopics, setSelectedOfferedTopics] = useState<
    ItemType[]
  >(offeredTopics || [])

  const [selectedDemandedTopics, setSelectedDemandedTopics] = useState<
    ItemType[]
  >(demandedTopics || [])

  useEffect(() => {
    // reset selected items when query data changes
    if (offeredTopics) {
      setSelectedOfferedTopics(offeredTopics)
    }
    if (demandedTopics) {
      setSelectedDemandedTopics(demandedTopics)
    }
  }, [offeredTopics, demandedTopics])

  const [updateTopics, { loading, error }] = useUpdateMyTopicsMutation({
    // prevent "unhandled promise rejection"
    // display errors via `error` object
    onError: () => null,
  })

  const offeredTopicsError = useMemo(() => {
    return selectedOfferedTopics.length > 8
      ? t('common:errors.moreThanEightTopics')
      : selectedOfferedTopics.length < 1
      ? t('common:errors.atLeastOneTopic')
      : undefined
  }, [selectedOfferedTopics, t])

  const demandedTopicsError = useMemo(() => {
    return selectedDemandedTopics.length > 8
      ? t('common:errors.moreThanEightTopics')
      : selectedDemandedTopics.length < 1
      ? t('common:errors.atLeastOneTopic')
      : undefined
  }, [selectedDemandedTopics, t])

  const handleEditButtonClick = (e: React.MouseEvent) => {
    if (!isEdit) {
      // prevent form from submit
      e.preventDefault()
      setIsEdit(true)
    }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (offeredTopicsError || demandedTopicsError) {
      if (
        selectedDemandedTopics?.length < 1 ||
        selectedOfferedTopics?.length < 1
      ) {
        toast({
          status: 'error',
          description: t('common:errors.missingRequiredToast'),
          isClosable: true,
        })
      } else {
        toast({
          status: 'error',
          description: t('common:errors.moreThanEightTopics'),
          isClosable: true,
        })
      }
    } else {
      const mapItems = (item: ItemType) => {
        return Number.parseInt(item.id)
      }
      updateTopics({
        variables: {
          offeredTopics: selectedOfferedTopics.map(mapItems),
          demandedTopics: selectedDemandedTopics.map(mapItems),
        },
      }).then(() => setIsEdit(false))
    }
  }

  const isReadonly = !isEdit

  return (
    <FormSection
      prefix={'my_topics_form'}
      title={t('common:myProfile.matchingCriteria')}
      onSubmit={handleSubmit}
      isEdit={isEdit}
      handleButtonClick={handleEditButtonClick}
      buttonRef={buttonRef}
      loading={loading}
    >
      <ApolloErrorMessage error={error} mb={'2.4rem'} />
      <Stack spacing={'3.2rem'}>
        <TopicMultiSelect
          label={t('common:myProfile.demandedTopics')}
          selectedItems={selectedDemandedTopics}
          setSelectedItems={setSelectedDemandedTopics}
          isReadonly={isReadonly}
          error={demandedTopicsError}
          isRequired={true}
        />
        <TopicMultiSelect
          label={t('common:myProfile.offeredTopics')}
          selectedItems={selectedOfferedTopics}
          setSelectedItems={setSelectedOfferedTopics}
          isReadonly={isReadonly}
          error={offeredTopicsError}
          isRequired={true}
        />
      </Stack>
    </FormSection>
  )
}
